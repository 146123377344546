import axios from "axios";
import { store } from "../redux/store";
import { showToast } from "../redux/slices/commonSlice";

const dispatch = store.dispatch;
const endpointsWithoutToast = new Set(["/user/login"]);
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      if (config.headers)
        config.headers["accessToken"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error) => {
    const config = error.config || {};
    const endpoint = config.url || "";
    if (error?.response?.data?.message || error?.message) {
      if (!endpointsWithoutToast.has(endpoint)) {
        dispatch(
          showToast({
            color: "error",
            msg: error.response?.data?.message || error?.message,
          })
        );
      }
    }

    // Handle response errors here
    if (error.response?.status === 409) {
      localStorage.removeItem("accessToken");
      window.location.href = "/sign-in";
    }
    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;
