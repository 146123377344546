import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SingIn from "./components/SingIn/SingIn";
import PersonalDetails from "./module/profile/PersonalDetails/PersonalDetails";
import Consumer from "./components/LandingPage/Consumer";
import ServiceProvider from "./components/LandingPage/ServiceProvider";
import ExpertServiceProvider from "./module/LandingPage/ExpertServiceProvider";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import DetailsServiceProvider from "./components/DetailsServiceProvider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { routePaths } from "./Router/routePaths";
import UpdateProfile from "./module/profile/UpdateProfile/UpdateProfile";
import AddBusiness from "./module/profile/AddBusiness/AddBusiness";
import SelectServiceCategory from "./module/profile/SelectServiceCategory/SelectServiceCategory";
import AddBusinessDetails from "./module/profile/AddBusinessDetails/AddBusinessDetails";
import SelectServiceSubCategory from "./module/profile/SelectServiceSubCategory/SelectServiceSubCategory";
import AddBusinessImages from "./module/profile/AddBusinessImages/AddBusinessImages";
import QuickBuy from "./components/QuickBuy";
import Availability from "./module/profile/Dashboard/Availability";
import DashboardPage from "./module/profile/Dashboard/Dashboard";
import AboutUs from "./components/AboutUs";
import AddOperatingHours from "./module/profile/AddOperatingHours/AddOperatingHours";
import OperatingHours from "./module/profile/AddOperatingHours/OperatingHours";
import EditBusiness from "./module/profile/EditBusiness/EditBusiness";

import Settings from "./module/profile/Settings";
import EditServiceCategory from "./module/profile/EditServiceCategory/EditServiceCategory";
import EditServiceSubCategory from "./module/profile/EditServiceSubCategory/EditServiceSubCategory";
import EditBusinessImages from "./module/profile/EditBusinessImages/EditBusinessImages";
import EditAddress from "./module/profile/EditAddress/EditAddress";
import EditLicenseDocuments from "./module/profile/EditLicenseDocuments/EditLicenseDocuments";
import ContactUs from "./components/ContactUs";
import TieredPlans from "./module/LandingPage/TieredPlans";
import QuickBuyForm from "./module/LandingPage/QuickBuyForm";
import Contact from "./module/LandingPage/Contact";
import UploadDocuments from "./module/profile/UploadDocuments/UploadDocuments";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { setLoading, showToast } from "./redux/slices/commonSlice";
import Loader from "./components/Loader";
import { RootState } from "./redux/rootReducer";
import { useEffect } from "react";
import { fetchProfileDetails } from "./redux/slices/userSlice";
import { AppDispatch } from "./redux/store";

const App = () => {
  const toaster = useSelector((state: RootState) => state.common.toaster);
  const loader = useSelector((state: RootState) => state.common.loader);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const closeToast = () => {
    setTimeout(() => {
      dispatch(showToast(null));
    }, 500);
  };

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  useEffect(() => {
    if (
      location.pathname === routePaths.signIn &&
      localStorage.getItem("accessToken")
    ) {
      if (localStorage.getItem("isProfileCompletion") === "true") {
        navigate(routePaths.dashboard);
      } else {
        navigate(routePaths.personalDetails);
      }
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      dispatch(fetchProfileDetails());
    }
  }, [dispatch, localStorage.getItem("accessToken")]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(toaster)}
        autoHideDuration={4000}
        onClose={closeToast}
      >
        <Alert onClose={closeToast} severity={toaster?.color || "info"}>
          {toaster?.msg}
        </Alert>
      </Snackbar>
      {loader && <Loader />}
      <Routes>
        <Route
          path={routePaths.serviceproviders}
          element={<ServiceProvider />}
        />
        <Route path={routePaths.aboutUs} element={<AboutUs />} />
        <Route path={routePaths.dashboard} element={<DashboardPage />} />
        <Route path={routePaths.settings} element={<Settings />} />
        <Route path={routePaths.availability} element={<Availability />} />
        <Route
          path={routePaths.detailsServiceproviders}
          element={<DetailsServiceProvider />}
        />
        <Route path={routePaths.quickBuy} element={<QuickBuy />} />
        <Route path={routePaths.signIn} element={<SingIn />} />
        <Route
          path={routePaths.personalDetails}
          element={<PersonalDetails />}
        />
        <Route path={routePaths.uploadProfile} element={<UpdateProfile />} />
        <Route path={routePaths.addBusiness} element={<AddBusiness />} />
        <Route
          path={routePaths.selectServiceCategory}
          element={<SelectServiceCategory />}
        />
        <Route
          path={routePaths.selectServiceSubCategory}
          element={<SelectServiceSubCategory />}
        />
        <Route
          path={routePaths.addBusinessDetails}
          element={<AddBusinessDetails />}
        />
        <Route
          path={routePaths.addBusinessImages}
          element={<AddBusinessImages />}
        />
        <Route
          path={routePaths.addOperatingHours}
          element={<AddOperatingHours />}
        />
        <Route path={routePaths.operatingHours} element={<OperatingHours />} />
        <Route
          path={routePaths.uploadDocuments}
          element={<UploadDocuments />}
        />
        <Route path={routePaths.editBusiness} element={<EditBusiness />} />
        <Route
          path={routePaths.editServiceCategory}
          element={<EditServiceCategory />}
        />
        <Route
          path={routePaths.editServiceSubCategory}
          element={<EditServiceSubCategory />}
        />
        <Route
          path={routePaths.editBusinessImages}
          element={<EditBusinessImages />}
        />
        <Route path={routePaths.editAddress} element={<EditAddress />} />
        <Route
          path={routePaths.editLicenseDocuments}
          element={<EditLicenseDocuments />}
        />
        <Route path={routePaths.default} element={<Consumer />} />
        <Route
          path={routePaths.expertServiceProvider}
          element={<ExpertServiceProvider />}
        />
        <Route
          path={routePaths.termsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route path={routePaths.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={routePaths.contactUs} element={<ContactUs />} />
        <Route path={routePaths.contact} element={<Contact />} />
        <Route path={routePaths.tieredPlans} element={<TieredPlans />} />
        <Route path={routePaths.quickBuyForm} element={<QuickBuyForm />} />
        <Route path="*" element={<Consumer />} />
      </Routes>
    </>
  );
};

export default App;
