import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import backIcon from "../../../assets/common/backBlueIcon.svg";
import CustomStepper from '../stepper/CustomStepper';
import BlueButton from '../../../components/Button/BlueButton';
import { useNavigate } from 'react-router-dom';

interface ProfileWrapperProps {
    children: React.ReactNode;
    title: string;
    btnDisabled: boolean;
    onSubmit?: () => void;
    stepperCount?: number;
    description?: string;
    optional?: boolean;
    businessImages?: boolean;
    onSkip?: () => void;
    buttonText?: string;
    hide?: boolean;
    backHandler?: () => void;
}

const ProfileWrapper: React.FC<ProfileWrapperProps> = ({ children, title, btnDisabled, onSubmit, stepperCount, description, optional, onSkip, businessImages, buttonText, hide, backHandler }) => {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigateBackHandler = () => {
        if (backHandler) {
            backHandler()
        }
        else {
            navigate(-1)
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: {
                    sm: 'center',
                    xs: "flex-start",
                },
                paddingTop: {
                    sm: "0",
                    xs: "20px"
                },
                height: "100vh",
                backgroundColor: '#f5f5fa',
                "@media (orientation: landscape) and (max-width: 999px)": {
                    height: "auto",
                    padding: "20px 0"
                }
            }}
        >
            <Box
                sx={{
                    width: { xs: '80%', sm: '80vw', md: "70vw" },
                    height: {
                        xs: '80%', sm: '90vh', md: "80vh",
                        "@media (orientation: landscape) and (max-width: 999px)": {
                            height: "auto",
                        }
                    },
                    backgroundColor: '#FFFFFF',
                    padding: {
                        sm: "20px",
                        xs: "16px"
                    },
                    borderRadius: "40px",
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    scrollbarWidth: 'none',
                    boxShadow: "none"
                }}
                className="innerWrapper"
            >
                <Box src={backIcon} component="img" alt="backIcon"
                    sx={{
                        position: 'absolute',
                        top: {
                            sm: "25px",
                            xs: "25px"
                        },
                        left: {
                            sm: "20px",
                            xs: "10px"
                        },
                        width: {
                            sm: "35px",
                            xs: "30px"
                        },
                        cursor: "pointer",
                    }}
                    onClick={navigateBackHandler}
                >
                </Box>
                {optional &&
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: {
                                xl: '16px',
                                xs: "14px"
                            },
                            position: 'absolute',
                            top: 30,
                            right: 20,
                            cursor: "pointer",
                            color: '#8989A3',
                            fontFamily: "Montserrat, sans-serif",
                        }}
                        onClick={onSkip}
                    >
                        Skip
                    </Typography>
                }

                {!hide && <Box sx={{
                    width: {
                        xs: '100%',
                        sm: "85%"
                    },
                    margin: {
                        sm: "10px auto 0",
                        xs: "60px auto 10px"
                    }
                }}>
                    <CustomStepper currentStep={stepperCount ?? 0} />
                </Box>
                }

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    m: {
                        md: 1,
                        xl: 2
                    },
                }}>
                    <Box sx={{
                        fontWeight: 700,
                        fontSize: { xs: '18px', md: "20px", xl: "28px" },
                        color: "#06038D",
                        fontFamily: 'Montserrat, sans-serif',
                        textAlign: 'center',
                        marginTop: {
                            sm: "15px",
                            xs: "0"
                        }
                    }}>
                        <>
                            {title}
                            {optional &&
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: { xs: '16px', md: "20px" },
                                        color: "#8989A3",
                                        fontFamily: 'Montserrat, sans-serif',
                                        display: 'inline',
                                    }}>(optional)</Typography>
                            }
                        </>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: "#8989A3",
                            fontFamily: 'Montserrat, sans-serif',
                            padding: {
                                xl: "10px 0",
                                xs: "5px 0"
                            },
                            textAlign: "center"
                        }}>

                        {description}
                    </Typography>
                </Box>

                <Box sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    scrollbarWidth: 'none'
                }}>
                    {children}
                </Box>

                <Box sx={{
                    display: "flex",
                    justifyContent: 'center',
                    mb: {
                        md: 1,
                        xl: 2
                    },
                }}>
                    <BlueButton
                        btnName={buttonText ? buttonText : "Continue"}
                        disabled={btnDisabled}
                        width="300px"
                        padding='10px 24px'
                        type="button"
                        onClick={onSubmit}
                    />
                </Box>
            </Box>

        </Box >
    );
};

export default ProfileWrapper;
