import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  background: {
    background: "#F5F5FA",
    minHeight: "100vh",
  },
  relativePosition: {
    position: "relative",
    paddingBottom: {
      lg: "100px",
    },
  },
  flexContainer: {
    display: "flex",
    padding: {
      lg: "60px 0",
      sm: "20px 0",
      xs: "15px 0",
    },
    alignItems: "center",
  },
  cursorPointer: {
    cursor: "pointer",
    width: {
      xs: "30px",
      lg: "auto"
    },
    paddingTop: {
      md:"2px",
      xs:"2px"
    }
  },
  listContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0",
  },
  listItemPadding: {
    padding: {
      sm: "0 0 0 20px",
      xs: "0 0 0 10px"
    },
    width: "auto"
  },
  linkStyle: {
    fontSize: {
      lg: "20px",
      xs: "16px",
    },
    fontWeight: "500",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    opacity: "0.4",
    fontFamily: "Montserrat, sans-serif",
  },
  linkActiveStyle: {
    fontSize: {
      lg: "20px",
      xs: "16px",
    },
    fontWeight: "600",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    fontFamily: "Montserrat, sans-serif",
  },
  formContainer: {
    boxShadow: "0px 69px 42px 0px #C8C8C840",
    borderRadius: "20px",
    position: "relative",
    padding: {
      lg: "50px 0",
      sm:"30px 20px",
      xs: "20px",
    },
    display: "flex",
    background: "#fff",
    minHeight: "70vh",
  },
  formTitle: {
    fontSize: "20px",
    color: "#0D0639",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingBottom: "20px",
  },
  submitButtonContainer: {
    margin: "auto",
    textAlign: "center",
    marginTop: "30px",
  },
  submitButton: {
    background: "#06038D",
    padding: "13px 60px",
    borderRadius: "12px",
    fontSize: { lg: "18px", xs: "16px" },
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    color: "#fff",
    fontWeight: "600",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    "&:disabled": {
      background: "#B3B3CF",
      color: "#fff",
      boxShadow: "none",
    },
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px"
  },
};

export default styles;