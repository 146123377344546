import footerBgImage from "../../../../assets/Consumer/footerBgImage.png";

export const getStyles = () => ({
  footerWrapper: {
    padding: {
      lg: "80px 0",
      xs: "40px 0",
    },
    backgroundImage: `url(${footerBgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  footerContainer: {
    padding: {
      xl: "0 24px",
      lg: "0 60px",
    },
  },
  customer: {
    paddingLeft: {
      lg: "0",
      sm: "25px",
      xs: "0"
    }
  },
  socialIcons: {
    paddingLeft: {
      xl: "0",
      lg: "25px",
      md: "25px",
      xs: "0"
    }
  },
  gridItem: {
    display: "flex",
    justifyContent: {
      lg: "center",
      xs: "start",
    },
  },
  sectionHeading: {
    fontSize: "19px",
    color: "#050267",
    fontWeight: "600",
    fontFamily: "Montserrat, sans- serif",
    margin: "20px 0 20px 0",
  },
  list: {
    padding: "0",
  },
  listItem: {
    padding: "0",
    margin: "0 0 20px 0",
    "&:last-child": {
      marginBottom: "0",
    }
  },
  listItemText: {
    padding: "0",
    margin: "0",
  },
  socialLink: {
    display: "flex",
  },
  socialIconWrapper: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "20px",
    background: "#0704771A",
  },
  socialIconLink: {
    display: "flex",
    alignItems: "center",
  },
  socialIconBox: {
    display: "flex",
  },
});
