import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

interface ImageCropperProps {
  open: boolean;
  image: string;
  onCrop: (file: File) => void;
  onCancel: () => void;
  cropperOptions?: Partial<Cropper.Options>;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  open,
  image,
  onCrop,
  onCancel,
  cropperOptions = {},
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const handleCrop = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const base64Data = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      const blob = await fetch(base64Data)
        .then((res) => res.blob())
        .catch((err) => console.error("Error converting base64 to blob:", err));

      if (blob) {
        const file = new File([blob], "croppedImage.png", {
          type: "image/png",
        });
        onCrop(file);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        <Cropper
          ref={cropperRef}
          style={{ height: 400, width: "100%" }}
          src={image}
          viewMode={1}
          autoCropArea={1}
          background={false}
          guides={true}
          aspectRatio={1}
          zoomOnWheel={false}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          responsive={true}
          checkOrientation={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleCrop} color="primary">
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropper;
