import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  editAddressPage: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5fa',
    "& .button button": {
      padding: {
        xs: "18px",
        sm: "18px 82px"
      }
    },
    alignItems: {
      md: 'center',
      xs: "flex-start",
    },
    paddingTop: {
      lg: "0",
      xs: "20px"
    },
  },
  editAddressWrapper: {
    width: { xs: '80%', sm: '80vw', md: "70vw" },
    height: { xs: 'auto', lg: "80vh" },
    backgroundColor: '#FFFFFF',
    padding: "20px",
    borderRadius: "40px",
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth: 'none',
    boxShadow: "none"
  },
  editAddressTitle: {
    fontWeight: 700,
    fontSize: { xs: '18px', md: "20px", xl: "28px" },
    color: "#06038D",
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    marginTop: "15px"
  },
  backIcon: {
    position: 'absolute',
    top: {
      sm: "15px",
      xs: "25px"
    },
    left: {
      sm: "15px",
      xs: "0"
    },
    cursor: "pointer",
    width: {
      xs: "55px",
      xl: "60px"
    }
  },
  dialogTitle: {
    m: 0,
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    fontSize: "22px",
    fontWeight: "700",
  },
  dialogContent: {
    padding: {
      lg: "30px 90px 20px",
      xs: "0 0 20px 0",
    },
    "@media (orientation: landscape) and (max-width: 999px)": {
              overflowY:"unset",
            }
  },
  contentBox: {
    padding: "0 20px",
  },
  buttonContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
    marginTop: {
      xl: "20px",
      xs: "10px"
    }
  },
  doneButton: {
    background: '#06038D',
    padding: '13px 60px',
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: '#fff',
    fontWeight: '500',
    textDecoration: 'none',
    fontFamily: 'Montserrat, sans-serif',
    textTransform: "capitalize",
  },

};

export default styles;