import dayjs, { Dayjs } from "dayjs";
import { showToast } from "../redux/slices/commonSlice";

export interface TimeSlot {
    startTime: string;
    endTime: string;
    monthDate?: any;
    _id?: string;
}

export interface DayAvailability {
    isAvailable: boolean;
    slots: TimeSlot[];
}

export interface ServiceTiming {
    [key: string]: DayAvailability;
}

export interface AvailabilityFormProps {
    handleClose: () => void;
    selectedDay: string;
    serviceTimingProp?: ServiceTiming;
    getProfileDetails?: () => void;
    selectedDate?: any;
    from?: string;
}

export const removeFalsyIdsFromSlots = (serviceTiming: any) => {
    Object.entries(serviceTiming).forEach(([day, value]) => {
        const dayValue = value as { isAvailable: boolean; slots: any[] }; 

        const { slots } = dayValue; 

        if (Array.isArray(slots) && slots.length > 0) {
            slots.forEach((slot: any) => {
                if (slot && !slot._id) {
                    delete slot._id; // Remove _id if it's falsy
                }
            });
        }
    });
};

// Helper function to validate the last slot
export const validateLastSlot = (dispatch: any, timeSlots: any) => {
    const lastSlot = timeSlots[timeSlots.length - 1];
    if (!lastSlot.startTime || !lastSlot.endTime) {
        dispatch(showToast({ color: 'error', msg: "Start time or end time for a slot cannot be empty." }));
        return false;
    }
    return true;
};

// Helper function to deep copy the serviceTiming
export const deepCopyServiceTiming = (serviceTimingProp: any) => {
    if (!serviceTimingProp) {
        return {};
    }
    return JSON.parse(JSON.stringify(serviceTimingProp));
};

// Helper function to format the time slots into string format
export const formatTimeSlots = (timeSlots: any) => {
    return timeSlots.map((slot: any) => ({
        startTime: slot.startTime ? slot.startTime.format('h:mm A') : '',
        endTime: slot.endTime ? slot.endTime.format('h:mm A') : '',
        monthDate: slot.monthDate,
        _id: slot?._id
    }));
};

// Helper function to create a new slot object
export const createNewSlot = (slot: any) => {
    return {
        startTime: slot.startTime,
        endTime: slot.endTime,
        _id: slot._id
    };
};

// Helper function to ensure day entry exists in updatedServiceTiming
export const ensureDayExists = (day: any, updatedServiceTiming: any) => {
    if (!updatedServiceTiming[day]) {
        updatedServiceTiming[day] = { isAvailable: true, slots: [] };
    }
};

export const addGeneralSlotsWithoutDate = (formattedSlots: any, day: any, updatedServiceTiming: any) => {
    const existingSlots = updatedServiceTiming[day].slots || [];
    formattedSlots.forEach((slot: any) => {

        if (!slot.monthDate) {
            const newSlot = createNewSlot(slot); 

            const existingSlotIndex = existingSlots.findIndex(
                (existingSlot: any) => existingSlot._id && existingSlot._id === newSlot._id
            );
            const hasSameTimeSlot = existingSlots.some(
                (existingSlot: any) =>
                    existingSlot.startTime === newSlot.startTime &&
                    existingSlot.endTime === newSlot.endTime && !existingSlot.monthDate
            );

            if (existingSlotIndex !== -1) {
                existingSlots[existingSlotIndex] = { ...newSlot };
            } else if (!hasSameTimeSlot) {
                existingSlots.push(newSlot);
            }
        }
    });
};
export const convertTo24Hour = (time: string) => {
    const [timePart, modifier] = time?.split(' ');
    let [hours, minutes] = timePart?.split(':').map(Number);

    if (modifier === 'PM' && hours !== 12) {
        hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
        hours = 0;
    }
    return hours * 100 + minutes;
};

interface Slot {
    _id?: string | null;
    startTime?: Dayjs | null;
    endTime?: Dayjs | null;
    monthDate?: string | null;
}

export const sortSlotsByStartTime = (slots: Slot[]): Slot[] => {
    return slots.sort((a, b) => {

        if (a.startTime && b.startTime) {
            if (a.startTime.isBefore(b.startTime)) return -1;
            if (a.startTime.isAfter(b.startTime)) return 1;
            return 0;
        }

        if (!a.startTime) return 1;
        if (!b.startTime) return -1;

        return 0; 
    });
};

interface DisplaySlot {
    _id?: string;
    startTime?: string;
    endTime?: string;
}

// Function to sort slots based on startTime
export const sortDisplaySlotsByTime = (slots: DisplaySlot[]): DisplaySlot[] => {
    return slots.sort((a, b) => {
        const timeA = dayjs(a.startTime, 'h:mm A');
        const timeB = dayjs(b.startTime, 'h:mm A');

        if (timeA.isBefore(timeB)) return -1;
        if (timeA.isAfter(timeB)) return 1;
        return 0;
    });
};