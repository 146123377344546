import { useEffect } from "react";
import Footer from "../../../components/LandingPage/Consumer/Footer/footer";
import Header from "../../../components/LandingPage/Consumer/Header/header";
import GetHelp from "../../../components/LandingPage/ServiceProvider/GetHelp/GetHelp";
import Banner from "./Banner/Banner";
import Search from "./Search/Search";

const ExpertServiceProvider = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <Search />
      <GetHelp />
      <Footer />
    </>
  );
};

export default ExpertServiceProvider;
