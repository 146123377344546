import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./style";

import EasyDoesIt from "../../../assets/quickBuy/easyDoesIt.svg";
import SellWithCertainity from "../../../assets/quickBuy/sellWithCertainity.svg";
import SkipTheHassles from "../../../assets/quickBuy/skipTheHassles.svg";
import QuickBuyLockGuaranteedSaleImage from "../../../assets/quickBuy/quickBuyLockGuaranteedSaleImage.jpg";
import QuickBuyLockGuaranteedSaleIcon from "../../../assets/quickBuy/quickBuyLockGuaranteedSaleIcon.png";
import QuickBuyImmediateOfferImage from "../../../assets/quickBuy/quickBuyImmediateOfferImage.jpg";
import OfferImage from "../../../assets/quickBuy/offerImage.png";
import QuickBuyVectorImage from "../../../assets/quickBuy/quickBuyVectorImage.png";
import QuickBuyLeackBackImage from "../../../assets/quickBuy/quickBuyLeackBackImage.jpg";
import QuickBuyLeackBack from "../../../assets/quickBuy/quickBuyLeackBack.png";

const QuickBuyContent = () => {
  const [
    isLoadingQuickBuyImmediateOfferImage,
    setIsLoadingQuickBuyImmediateOfferImage,
  ] = useState(true);
  const [
    isLoadingQuickBuyLockGuaranteedSaleImage,
    setIsLoadingQuickBuyLockGuaranteedSaleImage,
  ] = useState(true);
  const [isLoadingQuickBuyLeackBackImage, setIsLoadingQuickBuyLeackBackImage] =
    useState(true);
  return (
    <>
      <Box sx={styles.quickBuyContenImage}>
        <Container maxWidth="xl">
          <Typography variant="h3" sx={styles.quickBuyContenTitle}>
            Quickbuy : Makes It Easy
          </Typography>
          <Typography variant="body1" sx={styles.quickBuyContenSubTitle}>
            Lorem Ipsum simply dummy text ispc choose industry std priniting
            lorem ipsum dummy
          </Typography>

          <Grid
            container
            spacing={{ md: 4, lg: 6, xs: 0 }}
            sx={styles.gridContainerWrapper}
          >
            <Grid item xs={12} sm={12} md={4} sx={styles.gridContainer}>
              <Box sx={styles.card}>
                <Box sx={styles.cardIcon}>
                  <img
                    src={SellWithCertainity}
                    alt="Sell With Certainity Image"
                  />
                </Box>
                <Typography variant="body1" sx={styles.cardName}>
                  Sell with Certainity
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  To remove the uncertainty, we will match you with a qualified
                  real estate agent in your area who will help you understand
                  immediate offers and other solutions.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={styles.gridContainer}>
              <Box sx={styles.skipTheHasslesCard}>
                <Box sx={styles.skipTheHasslesCardIcon}>
                  <img src={SkipTheHassles} alt="Sell With Certainity Image" />
                </Box>
                <Typography variant="body1" sx={styles.cardName}>
                  Skip the Hassles
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  Eliminate the time and expense of putting your home on the
                  market. No showings, open houses, stress, or worries.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={styles.gridContainer}>
              <Box sx={styles.card}>
                <Box sx={styles.cardIcon}>
                  <img src={EasyDoesIt} alt="Sell With Certainity Image" />
                </Box>
                <Typography variant="body1" sx={styles.cardName}>
                  Easy Does It
                </Typography>
                <Typography variant="body1" sx={styles.cardText}>
                  Your agent will help you choose the right QuickBuy option for
                  your needs. Buy and sell on your preferred timeline.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={styles.quickBuyImmediateOfferSection}>
        <Container maxWidth="xl">
          <Box>
            <Grid
              spacing={{ md: 4 }}
              container
              sx={styles.quickBuyImmediateOfferGridWrapper}
            >
              <Grid item md={5} xs={12} lg={6} sx={styles.wrapper}>
                <Box>
                  <Typography variant="h3" sx={styles.quickBuyLeasebackHeading}>
                    QuickBuy® Immediate Offer
                  </Typography>
                </Box>
                <Box sx={styles.width}>
                  <Typography variant="h3" sx={styles.quickBuyContent}>
                    When we say hassle-free we mean it. QuickBuy® is the
                    perfect way to sell your house quickly, with no problems and
                    no worries. You’ll have more buying power and the cash you
                    need to find your next home.
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={styles.quickBuyLeasebackSubHeading}
                  >
                    Is QuickBuy® Immediate Offer the right fit for you?
                  </Typography>
                  <List>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      We present an immediate offer on a qualified home.
                    </ListItem>
                    <ListItem sx={styles.firstListItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      Bypass showings and preparing your home for market.
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      Close on your home in as few as 14 days.
                    </ListItem>
                  </List>
                </Box>
                <NavLink to="/quick-buy-form">
                  <Button sx={styles.button}>Get My Offer</Button>
                </NavLink>
              </Grid>
              <Grid item md={7} xs={12} lg={6}>
                <Box sx={styles.relative}>
                  <Box
                    component="img"
                    sx={styles.quickBuyImage}
                    src={QuickBuyImmediateOfferImage}
                    onLoad={() =>
                      setIsLoadingQuickBuyImmediateOfferImage(false)
                    }
                  ></Box>
                  {isLoadingQuickBuyImmediateOfferImage && (
                    <CircularProgress
                      size={40}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                  <Box sx={styles.offerImageWrapper}>
                    <Box
                      component="img"
                      src={OfferImage}
                      sx={styles.offerImage}
                      alt="image"
                    ></Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box sx={styles.quickBuyLockGuaranteedSaleWrapper}>
        <Container maxWidth="xl">
          <Box>
            <Box sx={styles.quickBuyLockGuaranteedSaleIconBoxWrapper}>
              <Box
                component="img"
                src={QuickBuyLockGuaranteedSaleIcon}
                sx={styles.quickBuyLockGuaranteedSaleIconImage}
                alt="image"
              ></Box>
              <Typography
                variant="h3"
                sx={styles.quickBuyLockGuaranteedSaleHeading}
              >
                QuickBuy® Lock Guaranteed Sale
              </Typography>
            </Box>
            <Grid spacing={{ md: 4 }} container sx={styles.flexDirection}>
              <Grid item md={5} xs={12} lg={6} sx={styles.wrapper}>
                <Box sx={styles.width}>
                  <Typography variant="h3" sx={styles.quickBuyContent}>
                    Now you can list your house with more confidence. Enjoy the
                    benefits of the traditional home sale and view multiple
                    offers, keeping the assurance of our QuickBuy® Immediate
                    Offer in hand for 150 days. The offer can be accepted at any
                    time.
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={styles.quickBuyLeasebackSubHeading}
                  >
                    Is QuickBuy® Lock Guaranteed Sale the right fit for you?
                  </Typography>
                  <List>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      We won’t take it personally if you take 150 days to see
                      what other offers are out there.
                    </ListItem>
                    <ListItem sx={styles.firstListItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      The safety net of the QuickBuy® offer empowers you to
                      take the time you need to sell your home.
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      You’ve got nothing to lose by listing your home on the
                      market, because you have an offer to fall back on.
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      Knowing you can sell at any time gives you complete
                      control over your next steps.
                    </ListItem>
                  </List>
                </Box>
                <NavLink to="/quick-buy-form">
                  <Button sx={styles.getMyOfferButton}>Get My Offer</Button>
                </NavLink>
              </Grid>
              <Grid item md={7} xs={12} lg={6}>
                <Box sx={styles.relative}>
                  <Box
                    component="img"
                    sx={styles.quickBuyImage}
                    onLoad={() =>
                      setIsLoadingQuickBuyLockGuaranteedSaleImage(false)
                    }
                    src={QuickBuyLockGuaranteedSaleImage}
                  ></Box>
                  {isLoadingQuickBuyLockGuaranteedSaleImage && (
                    <CircularProgress
                      size={40}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box sx={styles.quickBuyVectorImage}>
          <img src={QuickBuyVectorImage} alt="quickBuyVector" />
        </Box>
      </Box>
      <Box sx={styles.quickBuyLeackBackImageSection}>
        <Container maxWidth="xl">
          <Box>
            <Grid spacing={{ md: 6 }} container sx={styles.flexDirection}>
              <Grid item md={7} xs={12} lg={6}>
                <Box sx={styles.relative}>
                  <Box
                    component="img"
                    sx={styles.quickBuyImage}
                    onLoad={() => setIsLoadingQuickBuyLeackBackImage(false)}
                    src={QuickBuyLeackBackImage}
                  ></Box>
                  {isLoadingQuickBuyLeackBackImage && (
                    <CircularProgress
                      size={40}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                  <Box sx={styles.quickBuyLeackBackImageWrapper}>
                    <Box
                      component="img"
                      src={QuickBuyLeackBack}
                      sx={styles.quickBuyLeackBackImage}
                      alt="image"
                    ></Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={5} xs={12} lg={6} sx={styles.wrapper}>
                <Box>
                  <Typography variant="h3" sx={styles.quickBuyLeasebackHeading}>
                    QuickBuy® Leaseback
                  </Typography>
                </Box>
                <Box sx={styles.width}>
                  <Typography variant="h3" sx={styles.quickBuyContent}>
                    Change may be inevitable, but you can control the speed at
                    which it happens. QuickBuy® Leaseback is a great solution
                    if you need to sell your home quickly but aren’t quite ready
                    to move. Stay in your home while you build, improve, or look
                    for the next one. Long and short-term options are available.
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={styles.quickBuyLeasebackSubHeading}
                  >
                    Is QuickBuy® Leaseback the right fit for you?
                  </Typography>
                  <List>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      You control your timeline and move when you’re ready.
                    </ListItem>
                    <ListItem sx={styles.firstListItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      Enjoy a smooth transition between your current home and
                      your new one.
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      Keep your options open while you prepare to move.
                    </ListItem>
                    <ListItem sx={styles.listItem}>
                      <Box component="span" sx={styles.listItemText}></Box>
                      Simplify the process of looking for your next home with
                      funds from your current one.
                    </ListItem>
                  </List>
                </Box>
                <NavLink to="/quick-buy-form">
                  <Button sx={styles.button}>Get My Offer</Button>
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default QuickBuyContent;
