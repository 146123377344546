import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import cardOffer from "../../../assets/images/cardOffer.svg";
import convenience from "../../../assets/images/convenience.svg";
import creditCard from "../../../assets/images/creditCard.svg";
import expertise from "../../../assets/AboutUs/expertise.svg";
import FlexibleAndEasyIcon from "../../../assets/AboutUs/FlexibleAndEasyIcon.svg";
import leaseBack from "../../../assets/AboutUs/leaseBack.svg";
import menuOfServiceImage from "../../../assets/AboutUs/menuOfServiceImage.png";
import menuOfServiceVectorImage from "../../../assets/AboutUs/menuOfServiceVectorImage.png";
import minusAccordingIcon from "../../../assets/AboutUs/minusAccordingIcon.svg";
import plusAccordingIcon from "../../../assets/AboutUs/plusAccordingIcon.svg";
import ourMisstionImageVector from "../../../assets/AboutUs/ourMisstionImageVector.png";
import ourServicesVectorImage from "../../../assets/AboutUs/ourServicesVectorImage.svg";
import servicesIcon from "../../../assets/AboutUs/servicesIcon.svg";
import traditionalSelling from "../../../assets/AboutUs/traditionalSelling.svg";
import whyChooseVectorImage from "../../../assets/AboutUs/whyChooseVectorImage.png";
import flexibility from "../../../assets/AboutUs/flexibility.svg";
import profitableDeals from "../../../assets/AboutUs/profitableDeals.svg";
import testimonialBgColor from "../../../assets/AboutUs/testimonialBgColor.png";
import ourExpertiseImage from "../../../assets/AboutUs/ourExpertiseImage.jpg";
import ourMisstionImage from "../../../assets/AboutUs/ourMisstionImage.jpg";

const AboutUsContent = () => {
  interface AccordionData {
    title: string;
    color: string;
    content: string;
  }

  const accordionData: AccordionData[] = [
    {
      title: "Repairs",
      color: "#0AF4B5",
      content: "Fix up any issues with your home before listing.",
    },
    {
      title: "Remodelling",
      color: "#FE9D14",
      content:
        "Want to sell your home but stay in it a bit longer? Our lease back option offers you flexibility.",
    },
    {
      title: "Staging",
      color: "#FA5D29",
      content:
        "Want to sell your home but stay in it a bit longer? Our lease back option offers you flexibility.",
    },
    {
      title: "Lenders",
      color: "#96FB7D",
      content:
        "Want to sell your home but stay in it a bit longer? Our lease back option offers you flexibility.",
    },
    {
      title: "Appraisals",
      color: "#2823FF",
      content:
        "Want to sell your home but stay in it a bit longer? Our lease back option offers you flexibility.",
    },
    {
      title: "Homeowner’s Insurance",
      color: "#417FFF",
      content:
        "Want to sell your home but stay in it a bit longer? Our lease back option offers you flexibility.",
    },
  ];

  const [expandedPanel, setExpandedPanel] = useState<string>(
    accordionData[0].title
  );

  const handleChange = (panel: string) => (event: React.SyntheticEvent) => {
    setExpandedPanel(expandedPanel === panel ? "" : panel);
  };

  const [isMissionImageLoading, setIsMissionImageLoading] = useState(true);
  const [isExperServiceImageLoading, setIsExperServiceImageLoading] =
    useState(true);
  const [isMenuOfServiceImageLoading, setIsMenuOfServiceImageLoading] =
    useState(true);

  return (
    <>
      {/* Why Choose */}
      <Box
        sx={{
          padding: {
            xs: "20px 0 20px",
            lg: "80px 10px 80px",
          },
          backgroundImage: `url(${testimonialBgColor})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container
          sx={{
            position: "relative",
            zIndex: "9",
            padding: {
              xl: "0 24px",
              lg: "0 60px",
            },
          }}
          maxWidth="xl"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                lg: "40px",
                xs: "28px",
              },
              color: "#0D0639",
              fontFamily: "Montserrat, sans- serif",
              fontWeight: "600",
              textAlign: "center",
              paddingBottom: "14px",
            }}
          >
            Why{" "}
            <span
              style={{
                color: "#D40500",
              }}
            >
              Choose Us?
            </span>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                lg: "18px",
                xs: "16px",
              },
              color: "#8989A3",
              fontFamily: "Montserrat, sans- serif",
              fontWeight: "500",
              textAlign: "center",
              marginBottom: {
                lg: "20px",
                xs: "30px",
              },
            }}
          >
            Expert Guidance, Tailored Solutions and Trusted Professionals - Your
            Complete Real Estate Resource
          </Typography>

          <Grid
            container
            spacing={{ md: 4, lg: 6, xs: 0 }}
            sx={{
              width: {
                xl: "80%",
                lg: "90%",
                md: "100%",
              },
              margin: "auto !important",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: {
                  xs: "20px",
                },
              }}
              sm={12}
              md={4}
            >
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid #06038D0D",
                  borderRadius: "12px",
                  color: "#fff",
                  padding: {
                    lg: "20px 30px 30px 30px",
                    xs: "14px",
                  },
                  backgroundColor: "#8D0603",
                  boxShadow: "0px 7px 28px 0px #135C542E",
                  marginTop: {
                    lg: "60px",
                  },
                }}
              >
                <Box
                  sx={{
                    height: "85px",
                    margin: "0 auto 20px",
                    width: "85px",
                    borderRadius: "50%",
                    background: "#fff",
                    border: "2px solid #fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#9f2f2f",
                  }}
                >
                  <img src={flexibility} alt="Sell With Certainity Image" />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      lg: "20px",
                      xs: "18px",
                    },
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  Flexibility
                </Typography>
                <Box
                  sx={{
                    height: "0.5px",
                    background: "#ffffff63",
                    width: "170px",
                    margin: "10px auto 18px",
                  }}
                ></Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  We offer flexible, tailored solutions to meet your unique
                  needs, from selling your home to accessing top professionals.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: {
                  xs: "20px",
                },
              }}
              sm={12}
              md={4}
            >
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid #06038D0D",
                  borderRadius: "12px",
                  color: "#fff",
                  padding: {
                    lg: "20px",
                    xs: "14px",
                  },
                  backgroundColor: "#06038D",
                  boxShadow: "0px 7px 28px 0px #135C542E",
                }}
              >
                <Box
                  sx={{
                    height: "85px",
                    margin: "0 auto 20px",
                    width: "85px",
                    borderRadius: "50%",
                    background: "#fff",
                    border: "2px solid #fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#2e2c9f",
                  }}
                >
                  <img src={convenience} alt="Sell With Certainity Image" />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      lg: "20px",
                      xs: "18px",
                    },
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  Convenience
                </Typography>
                <Box
                  sx={{
                    height: "0.5px",
                    background: "#ffffff63",
                    width: "170px",
                    margin: "10px auto 18px",
                  }}
                ></Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Experience unmatched convenience with our one-stop service,
                  providing everything from expert real estate guidance to a
                  network of trusted professionals - all in one place.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: {
                  xs: "20px",
                },
              }}
              sm={12}
              md={4}
            >
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid #06038D0D",
                  borderRadius: "12px",
                  color: "#fff",
                  padding: {
                    lg: "20px",
                    xs: "14px",
                  },
                  backgroundColor: "#1CB469",
                  boxShadow: "0px 7px 28px 0px #135C542E",
                  marginTop: {
                    lg: "60px",
                  },
                }}
              >
                <Box
                  sx={{
                    height: "85px",
                    margin: "0 auto 20px",
                    width: "85px",
                    borderRadius: "50%",
                    background: "#fff",
                    border: "2px solid #fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#41bf82",
                  }}
                >
                  <img src={expertise} alt="Sell With Certainity Image" />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      lg: "20px",
                      xs: "18px",
                    },
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  Expertise
                </Typography>
                <Box
                  sx={{
                    height: "0.5px",
                    background: "#ffffff63",
                    width: "170px",
                    margin: "10px auto 18px",
                  }}
                ></Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Rely on our exceptional expertise, backed by top-rated agents
                  and a trusted network of professionals. ensuring you make
                  informed decisions at every step.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          component="img"
          src={whyChooseVectorImage}
          sx={{
            position: "absolute",
            top: "-80px",
            right: "-30px",
          }}
        ></Box>
      </Box>

      {/* Services */}
      <Box
        sx={{
          padding: {
            lg: "80px 0",
            xs: "20px 0",
          },
          position: "relative",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xl: "0 24px",
              lg: "0 60px",
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                lg: "40px",
                xs: "28px",
              },
              color: "#06038D",
              fontFamily: "Montserrat, sans- serif",
              fontWeight: "700",
              textAlign: "center",
              paddingBottom: "14px",
            }}
          >
            Our Services
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                lg: "18px",
                xs: "16px",
              },
              color: "#8989A3",
              fontFamily: "Montserrat, sans- serif",
              fontWeight: "500",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Selling Options
          </Typography>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              padding: {
                lg: "30px 0 0",
                xs: "10px 0 0",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "0 0 50px 0",
              }}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  width: "25%",
                  display: "flex",
                  position: "relative",
                  "&::before": {
                    content: "''",
                    display: "block",
                    width: "50%",
                    position: "absolute",
                    left: "50%",
                    border: "2px dashed #0A003259",
                    top: "50%",
                  },
                }}
              >
                <Box
                  sx={{
                    border: "2px solid #0D0639",
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "50%",
                    height: "35px",
                    zIndex: 9,
                    position: "relative",
                    listStyle: "none",
                    width: "35px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#06038D",
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      color: "#fff",
                      fontSize: "18px",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    1
                  </Box>
                  <Box
                    sx={{
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderTop: "14px solid #06038D",
                      position: "absolute",
                      bottom: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  width: "25%",
                  display: "flex",
                  position: "relative",
                  "&::before": {
                    content: "''",
                    display: "block",
                    width: "100%",
                    position: "absolute",
                    border: "2px dashed #0A003259",
                    top: "50%",
                  },
                }}
              >
                <Box
                  sx={{
                    border: "2px solid #0D0639",
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "50%",
                    height: "35px",
                    zIndex: 9,
                    position: "relative",
                    listStyle: "none",
                    width: "35px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#06038D",
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      color: "#fff",
                      fontSize: "18px",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    2
                  </Box>
                  <Box
                    sx={{
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderTop: "14px solid #06038D",
                      position: "absolute",
                      bottom: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  width: "25%",
                  display: "flex",
                  position: "relative",
                  "&::before": {
                    content: "''",
                    display: "block",
                    width: "100%",
                    position: "absolute",
                    border: "2px dashed #0A003259",
                    top: "50%",
                  },
                }}
              >
                <Box
                  sx={{
                    border: "2px solid #0D0639",
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "50%",
                    height: "35px",
                    zIndex: 9,
                    position: "relative",
                    listStyle: "none",
                    width: "35px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#06038D",
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      color: "#fff",
                      fontSize: "18px",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    3
                  </Box>
                  <Box
                    sx={{
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderTop: "14px solid #06038D",
                      position: "absolute",
                      bottom: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box
                sx={{
                  justifyContent: "center",
                  width: "25%",
                  display: "flex",
                  position: "relative",
                  "&::before": {
                    content: "''",
                    display: "block",
                    width: "50%",
                    position: "absolute",
                    right: "50%",
                    border: "2px dashed #0A003259",
                    top: "50%",
                  },
                }}
              >
                <Box
                  sx={{
                    border: "2px solid #0D0639",
                    background: "#fff",
                    padding: "10px",
                    borderRadius: "50%",
                    height: "35px",
                    zIndex: 9,
                    position: "relative",
                    listStyle: "none",
                    width: "35px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#06038D",
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      color: "#fff",
                      fontSize: "18px",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    4
                  </Box>
                  <Box
                    sx={{
                      width: "0",
                      height: "0",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderTop: "14px solid #06038D",
                      position: "absolute",
                      bottom: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></Box>
                </Box>
              </Box>
            </Box>

            <Grid container spacing={3}>
              <Grid
                item
                md={3}
                sm={6}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    border: "1.86px solid #ACA3D44D",
                    borderRadius: "7px",
                    padding: "20px 20px",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img src={cardOffer} alt="Card Offer Image" />
                  </Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: {
                        lg: "20px",
                        xs: "18px",
                      },
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "500",
                      textAlign: "center",
                      paddingBottom: "14px",
                    }}
                  >
                    Cash Offers
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "300",
                      textAlign: "center",
                    }}
                  >
                    Need to sell quickly? Our cash offer option provides a fast
                    and straightforward solution..
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                sm={6}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    border: "1.86px solid #ACA3D44D",
                    borderRadius: "7px",
                    padding: "20px 20px",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img src={leaseBack} alt="Card Offer Image" />
                  </Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: {
                        lg: "20px",
                        xs: "18px",
                      },
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "500",
                      textAlign: "center",
                      paddingBottom: "14px",
                    }}
                  >
                    Lease Back
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "300",
                      textAlign: "center",
                    }}
                  >
                    Want to sell your home but stay in it a bit longer? Our
                    lease back option offers you flexibility.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                sm={6}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    border: "1.86px solid #ACA3D44D",
                    borderRadius: "7px",
                    padding: {
                      xl: "20px 60px",
                      lg: "20px",
                      xs: "20px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img src={creditCard} alt="Card Offer Image" />
                  </Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: {
                        lg: "20px",
                        xs: "18px",
                      },
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "500",
                      textAlign: "center",
                      paddingBottom: "14px",
                    }}
                  >
                    Lock
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "300",
                      textAlign: "center",
                    }}
                  >
                    Secure your sale with our lock option, giving you peace of
                    mind.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                sm={6}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    border: "1.86px solid #ACA3D44D",
                    borderRadius: "7px",
                    padding: "20px 20px",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img src={traditionalSelling} alt="Card Offer Image" />
                  </Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: {
                        lg: "20px",
                        xs: "18px",
                      },
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "500",
                      textAlign: "center",
                      paddingBottom: "14px",
                    }}
                  >
                    Traditional Selling
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      color: "#0D0639",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "300",
                      textAlign: "center",
                    }}
                  >
                    Prefer the conventional route? Our traditional selling
                    option ensures a seamless process.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              background: "#F3F4FC",
              boxShadow: "0px 2px 4px 0px #00000014",
              margin: {
                lg: "80px 0 40px",
                xs: "30px 0 40px",
              },
              borderRadius: "24px",
              zIndex: "9",
              position: "relative",
              padding: {
                lg: "40px 20px 40px 60px",
                xs: "16px",
              },
              overflow: "hidden",
            }}
          >
            <Grid
              container
              sx={{
                flexDirection: {
                  md: "row",
                  xs: "column-reverse",
                },
              }}
            >
              <Grid
                item
                md={6}
                xs={12}
                lg={7}
                sx={{
                  display: "flex",
                  textAlign: "left",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: {
                        lg: "40px",
                        xs: "22px",
                      },
                      paddingTop: "14px",
                      color: "#06038D",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "700",
                      paddingBottom: "20px",
                    }}
                  >
                    Our Mission
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: {
                        lg: "18px",
                        xs: "16px",
                      },
                      color: "#64748B",
                      fontFamily: "Montserrat, sans- serif",
                      fontWeight: "500",
                      lineHeight: {
                        lg: "36px",
                        xs: "28px",
                      },
                      width: {
                        lg: "70%",
                        md: "90%",
                        xs: "100%",
                      },
                    }}
                  >
                    At Home Seller Savings, our mission is to provide homeowners
                    with the easiest and most convenient way to sell their home.
                    We believe in offering choices, and our diverse range of
                    selling options ensures that you can find the perfect
                    solution for your circumstances.
                  </Typography>
                </Box>
                <NavLink to="/contact">
                  <Button
                    sx={{
                      background: "#1CB469",
                      color: "#fff",
                      padding: {
                        xs: "8px 20px",
                        sm: "13px 30px",
                      },
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                      },
                      textTransform: "capitalize",
                      border: "1.6px solid #1CB469",
                      fontFamily: "Montserrat, sans- serif",
                      boxShadow: "4px 8px 24px 0px #1CB46940",
                      fontWeight: "600",
                      borderRadius: "12px",
                      margin: {
                        xs: "20px 0 0 0",
                        sm: "20px 0 0 0",
                        lg: "30px 0 0 0",
                      },
                    }}
                  >
                    Learn More
                  </Button>
                </NavLink>
              </Grid>
              <Grid item md={6} xs={12} lg={5} sx={{ position: "relative" }}>
                <Box
                  component="img"
                  sx={{
                    borderRadius: "20px",
                    width: "100%",
                    position: "relative",
                    zIndex: "9",
                  }}
                  src={ourMisstionImage}
                  onLoad={() => setIsMissionImageLoading(false)}
                ></Box>
                {isMissionImageLoading && (
                  <Box sx={{ display: "flex", position: "absolute", top: "50%", left: "50%", zIndex: "99" }}>
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box
              sx={{
                position: "absolute",
                right: "0",
                top: "-20px",
              }}
            >
              <img
                src={ourMisstionImageVector}
                alt="Our Services Vector Image"
              />
            </Box>
          </Box>
        </Container>
        <Box
          component="img"
          src={ourServicesVectorImage}
          sx={{
            position: "absolute",
            bottom: "0",
            left: "-30px",
          }}
        ></Box>
      </Box>

      {/*  Menu of Services */}
      <Box
        sx={{
          background: "linear-gradient(180deg, #D8EDE3 5.04%, #FFFFFF 103.28%)",
          padding: {
            lg: "40px 0",
            xs: "20px 0 0",
          },
        }}
      >
        <Container
          sx={{
            padding: {
              xl: "0 24px",
              lg: "0 60px",
            },
          }}
          maxWidth="xl"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                lg: "40px",
                xs: "22px",
              },
              paddingTop: "14px",
              color: "#0D0639",
              fontFamily: "Montserrat, sans- serif",
              fontWeight: "700",
              paddingBottom: "20px",
              textAlign: "center",
            }}
          >
            Comprehensive Menu of{" "}
            <span
              style={{
                color: "#06038D",
              }}
            >
              Services
            </span>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                lg: "18px",
                xs: "16px",
              },
              color: "#8989A3",
              fontFamily: "Montserrat, sans- serif",
              fontWeight: "500",
              textAlign: "center",
              marginBottom: "20px",
              width: {
                lg: "70%",
                xs: "100%",
              },
              margin: {
                md: "0 auto 50px",
                xs: "0 auto 30px",
              },
            }}
          >
            We go beyond just selling your home. Our extensive menu of services
            provides everything you need to prepare and optimize your home for
            sale. From repairs and remodeling to staging and appraisals, we've
            got you covered. Our service providers include:
          </Typography>
          <Grid
            container
            spacing={{
              xs: 4,
              md: 6,
            }}
          >
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: {
                  lg: "flex-end",
                  xs: "center",
                },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  marginTop: {
                    sm: "30px",
                  },
                }}
              >
                <Box
                  sx={{
                    position: {
                      sm: "absolute",
                      xs: "unset",
                    },
                    display: {
                      sm: "block",
                      xs: "none",
                    },
                    bottom: "-50px",
                    left: "-60px",
                  }}
                >
                  <img
                    src={menuOfServiceVectorImage}
                    alt="Menu Of Service Vector Image"
                  />
                </Box>
                <Box
                  sx={{
                    border: "1px solid #41C485",
                    boxShadow: "5px 15px 60px 0px #1B212F1A",
                    background: "#fff",
                    borderRadius: "20px",
                    padding: "14px 35px 14px 14px",
                    position: {
                      sm: "absolute",
                      xs: "unset",
                    },
                    top: "-40px",
                    marginBottom: {
                      sm: "0",
                      xs: "20px",
                    },
                    left: {
                      lg: "-120px",
                      md: "0",
                    },
                    width: {
                      sm: "max-content",
                      xs: "auto",
                    },
                    zIndex: "99",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={servicesIcon} alt="Services Icon" />
                    <Typography
                      sx={{
                        color: "#1B212F",
                        fontFamily: "Montserrat, sans- serif",
                        fontSize: {
                          lg: "20px",
                          xs: "16px",
                        },
                        fontWeight: "600",
                        paddingLeft: "20px",
                      }}
                    >
                      50+ Services
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #F14D5D",
                    boxShadow: "5px 15px 60px 0px #1B212F1A",
                    background: "#fff",
                    borderRadius: "20px",
                    padding: "14px 35px 14px 14px",
                    position: {
                      sm: "absolute",
                      xs: "unset",
                    },
                    top: "50%",
                    marginBottom: {
                      sm: "0",
                      xs: "20px",
                    },
                    left: {
                      xl: "-170px",
                      lg: "-120px",
                      md: "0",
                    },
                    transform: {
                      sm: "translateY(-50%)",
                      xs: "unset",
                    },
                    width: {
                      sm: "max-content",
                      xs: "auto",
                    },
                    zIndex: "99",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={FlexibleAndEasyIcon} alt="Services Icon" />
                    <Typography
                      sx={{
                        color: "#1B212F",
                        fontFamily: "Montserrat, sans- serif",
                        fontSize: {
                          lg: "20px",
                          xs: "16px",
                        },
                        fontWeight: "600",
                        paddingLeft: "20px",
                      }}
                    >
                      Flexible & Easy
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    boxShadow: "5px 15px 60px 0px #1B212F1A",
                    background: "#fff",
                    borderRadius: "20px",
                    padding: "14px 35px 14px 14px",
                    width: {
                      sm: "max-content",
                      xs: "auto",
                    },
                    position: {
                      sm: "absolute",
                      xs: "unset",
                    },
                    marginBottom: {
                      sm: "0",
                      xs: "20px",
                    },
                    bottom: "-30px",
                    right: "20px",
                    zIndex: "99",
                    border: "1px solid #8E66FF",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={profitableDeals} alt="Services Icon" />
                    <Typography
                      sx={{
                        color: "#1B212F",
                        fontFamily: "Montserrat, sans- serif",
                        fontSize: {
                          lg: "20px",
                          xs: "16px",
                        },
                        fontWeight: "600",
                        paddingLeft: "20px",
                      }}
                    >
                      Profitable Deals
                    </Typography>
                  </Box>
                </Box>
                <Box
                  component="img"
                  sx={{
                    zIndex: "9",
                    position: "relative",
                    width: {
                      lg: "572px",
                      xs: "100%",
                    },
                  }}
                  src={menuOfServiceImage}
                  alt="Menu Of Service Image"
                  onLoad={() => setIsMenuOfServiceImageLoading(false)}
                ></Box>
                {isMenuOfServiceImageLoading && (
                  <Box sx={{ display: "flex", position: "absolute", top: "50%", left: "50%", zIndex: "99" }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                padding: {
                  lg: "50px 0",
                  xs: "0 0 20px",
                },
                marginTop: {
                  sm: "30px",
                },
              }}
            >
              {accordionData.map((accordion, index) => (
                <Accordion
                  key={accordion.title}
                  sx={{
                    borderLeft: `5px solid ${accordion.color}`,
                    marginBottom: "20px",
                    boxShadow: "none",
                    padding: "10px 0",
                  }}
                  expanded={expandedPanel === accordion.title}
                  onChange={handleChange(accordion.title)}
                >
                  <AccordionSummary
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: {
                        lg: "20px",
                        xs: "18px",
                      },
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        expandedPanel === accordion.title
                          ? minusAccordingIcon
                          : plusAccordingIcon
                      }
                      alt={
                        expandedPanel === accordion.title
                          ? "Expand Less"
                          : "Expand More"
                      }
                      style={{
                        marginRight: "8px",
                        marginLeft: "5px",
                        paddingRight: "20px",
                      }}
                    />
                    {accordion.title}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      color: "#202124",
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Montserrat, sans-serif",
                      paddingLeft: "67px",
                      lineHeight: "24px",
                    }}
                  >
                    {accordion.content}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Expertise */}
      <Container
        maxWidth="xl"
        sx={{
          padding: {
            xl: "0 24px",
            lg: "0 60px",
          },
        }}
      >
        <Box
          sx={{
            background: "#F3F4FC",
            boxShadow: "0px 2px 4px 0px #00000014",
            margin: {
              xs: "0",
              lg: "60px 0 40px",
            },
            borderRadius: "24px",
            zIndex: "9",
            position: "relative",
            padding: {
              lg: "40px 20px 40px 60px",
              xs: "16px",
            },
            overflow: "hidden",
          }}
        >
          <Grid
            container
            sx={{
              flexDirection: {
                md: "row",
                xs: "column-reverse",
              },
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              lg={6}
              sx={{
                display: "flex",
                textAlign: "left",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      lg: "40px",
                      xs: "22px",
                    },
                    paddingTop: "14px",
                    color: "#06038D",
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "700",
                    paddingBottom: "20px",
                  }}
                >
                  Our Expertise
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      lg: "18px",
                      xs: "16px",
                    },
                    color: "#64748B",
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "500",
                    lineHeight: {
                      lg: "36px",
                      xs: "28px",
                    },
                    width: {
                      lg: "70%",
                      md: "90%",
                      xs: "100%",
                    },
                  }}
                >
                  With many years of experience in the real estate market, we've
                  built strong relationships with trusted service providers.
                  We're proud to share these connections with you, offering a
                  one-stop-shop experience that adds another layer of
                  convenience to your home selling journey.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12} lg={6} sx={{ position: "relative" }}>
              <Box
                component="img"
                sx={{
                  borderRadius: "20px",
                  width: "100%",
                  position: "relative",
                  zIndex: "9",
                }}
                src={ourExpertiseImage}
                onLoad={() => setIsExperServiceImageLoading(false)}
              ></Box>
              {isExperServiceImageLoading && (
                <Box sx={{ display: "flex", position: "absolute", top: "50%", left: "50%", zIndex: "99" }}>
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              position: "absolute",
              right: "0",
              top: "-20px",
            }}
          >
            <img src={ourMisstionImageVector} alt="Our Services Vector Image" />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AboutUsContent;
