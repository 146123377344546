import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Pagination,
  PaginationItem,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Autocomplete,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
} from "@mui/material";
import "./search.css";
import { Link, NavLink } from "react-router-dom";
import Constant from "../../../../comman/Constant";
import {
  getSubCategories,
  getServiceProviders,
} from "../../../../network/services";
import styles from "./style";
import { Autocomplete as GoogleAutocomplete } from "@react-google-maps/api";
import searchArrow from "../../../../assets/ExpertServiceProvider/searchArrow.svg";
import { SearchContext } from "../../../../context/SearchContext";
import noResultSearchImage from "../../../../assets/ExpertServiceProvider/No_Results_View_Search.svg";
import dropdownArrow from "../../../../assets/ExpertServiceProvider/dropdownArrow.svg";
import dummyImage from "../../../../assets/common/dummyImage.jpg";
import searchDivider from "../../../../assets/ExpertServiceProvider/search-divider.png";
import searchIcon from "../../../../assets/ExpertServiceProvider/searchIcon.svg";
import prevIcon from "../../../../assets/common/prevIcon.svg";
import nextIcon from "../../../../assets/common/nextIcon.svg";
import closeIcon from "../../../../assets/common/closeIcon.svg";
import searchCloseIcon from "../../../../assets/common/searchCloseIcon.svg";
import locationIcon from "../../../../assets/ExpertServiceProvider/locationIcon.svg";
import { setLoading } from "../../../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";

interface Service {
  _id: string;
  name: string;
}

const DropdownIcon = ({ onClick }: { onClick: () => void }) => (
  <img src={dropdownArrow} alt="arrow" className="downArrowIcon" onClick={onClick} />
);

const PreviousSlot = ({
  page,
  onPreviousClick,
}: {
  page: number;
  onPreviousClick: () => void;
}) => (
  <Box
    sx={styles.paginationPrevious}
    onClick={onPreviousClick} // Use the `page` prop
  >
    <img src={prevIcon} alt="Prev Icon" />
    <Box sx={styles.paginationText}>Previous</Box>
  </Box>
);

const NextSlot = ({
  page,
  totalPages,
  onNextClick,
}: {
  page: number;
  totalPages: number;
  onNextClick: () => void;
}) => (
  <Box
    sx={styles.paginationNext}
    onClick={onNextClick} // Use the `page` prop
    style={{ pointerEvents: page === totalPages ? "none" : "auto" }} // Disable if on the last page
  >
    <Box sx={styles.paginationText}>Next</Box>
    <img src={nextIcon} alt="Next Icon" />
  </Box>
);

const Search: React.FC = () => {
  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error("Auth Context must be used within a AuthProvider");
  }
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { data, filters, updateFilter, updateData } = searchContext;
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isCategorySelected, setIsCategorySelected] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [locationModal, setLocationModal] = React.useState(false);
  const resultsRef = useRef<any>(null);
  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place) {
        const addressComponents = place.address_components;
        const formattedAddress = place.formatted_address;
        const zipCodeComponent = addressComponents?.find((comp: any) =>
          comp.types.includes("postal_code")
        );
        const zipCode = zipCodeComponent?.long_name || "";
        updateFilter("searchedAddress", formattedAddress || "");
        updateFilter("zipCode", zipCode);
      }
    }
  };

  const handleDropdownToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const getAddressFromCoordinates = async (lat: number, lng: number) => {
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.results.length > 0) {
        const firstResult = data.results[0];
        const formattedAddress = firstResult.formatted_address;
        const addressComponents = firstResult.address_components;
        const zipCodeComponent = addressComponents.find((comp: any) =>
          comp.types.includes("postal_code")
        );
        const zipCode = zipCodeComponent?.long_name || "Unknown Zip Code";
        updateFilter("zipCode", zipCode);
        setLocationModal(false);
        updateFilter("searchedAddress", "");
        updateFilter("location", formattedAddress);
      }
    } catch (err) {
      console.error("Error fetching address from coordinates:", err);
    }
  };

  const handlePageChange = (event: any, newPage: number) => {
    updateData("page", newPage);
    if (resultsRef.current) {
      window.requestAnimationFrame(() => {
        resultsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    }
  };

  const handlePreviousClick = () => {
    if (data.page > 1) {
      updateData("page", data.page - 1);
      // handlePageChange(null, data.page - 1);
    }
  };

  const handleNextClick = () => {
    if (data.page < data.totalPages) {
      updateData("page", data.page + 1);
      // handlePageChange(null, data.page + 1);
    }
  };

  const handleSubCategoryClick = (value: any) => {
    if (filters.selectedSubCategories.includes(value)) {
      const updatedSubCategories = filters.selectedSubCategories.filter(
        (subCategory: any) => subCategory !== value
      );
      updateFilter("selectedSubCategories", updatedSubCategories);
    } else {
      updateFilter("selectedSubCategories", [
        ...filters.selectedSubCategories,
        value,
      ]);
    }
  };

  const handleClickUserCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getAddressFromCoordinates(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.error("Error getting location", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    }
  };

  const handleSearchButtonClick = async () => {
    dispatch(setLoading(true));
    const newPayload = {
      zipCode:
        filters.zipCode !== "Unknown Zip Code"
          ? filters.zipCode
          : filters.searchedAddress,
      page: data.page,
      limit: 12,
      subCategoryIds: filters.selectedSubCategories,
    };
    await getServiceProvidersList(newPayload);
    dispatch(setLoading(false));
    updateData("isSearchClicked", true);
    setTimeout(() => {
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const getcontent = () => {
    fetch(`${Constant.BASE_URL}/api/service/list-for-user`)
      .then((res) => res.json())
      .then((output) => {
        updateData("services", output.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    getcontent();
  }, []);

  useEffect(() => {
    if (
      typeof filters.selectedService == "object" &&
      filters.selectedService !== null
    ) {
      let categoryid = filters.selectedService && filters.selectedService._id;
      fetch(`${Constant.BASE_URL}/api/category/list-for-consumer/${categoryid}`)
        .then((res) => res.json())
        .then((output) => {
          updateData("category", output.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [filters.selectedService]);

  const getSubCategoriesList = async () => {
    const selectedCategoryData = data.category.find(
      (item: any) => item.categoryName === filters.selectedCategory
    );
    updateFilter("selectedCategoryId", selectedCategoryData?._id);
    const response = await getSubCategories({
      categoryIds: [selectedCategoryData?._id],
    });
    const fetchedData = await response.data;
    updateData("subCategory", fetchedData[0]?.subcategories);
  };
  useEffect(() => {
    getSubCategoriesList();
  }, [filters.selectedCategory]);

  const getServiceProvidersList = async (payload: any) => {
    const response = await getServiceProviders(payload);
    const data = await response.data;
    updateData("serviceProviders", data.userList);
    updateData("totalRecordsCount", response.totalCount);
    updateData("totalPages", Math.ceil(response.data.totalCount / 12));
  };
  useEffect(() => {
    if (isCategorySelected) {
      updateFilter("selectedSubCategories", []);
    }
  }, [filters.selectedCategory]);

  const handleClickLocationModalOpen = () => {
    setLocationModal(true);
  };
  const handleClickLocationModalClose = () => {
    updateFilter(
      "location",
      (autocompleteRef &&
        autocompleteRef.current?.getPlace()?.formatted_address) ||
      ""
    );
    updateFilter("searchedAddress", "");
    setLocationModal(false);
  };

  const handleCloseIconLocationModalClose = () => {
    setLocationModal(false);
  };

  const selectedCategoryHandleChange = (event: SelectChangeEvent<string>) => {
    setIsCategorySelected(true);
    updateFilter("selectedCategory", event.target.value);
    updateFilter("selectedSubCategories", []);
    updateData("serviceProviders", []);
  };

  const MenuProps = {
    PaperProps: {
      className: "dropdownMenu",
    },
  };

  const handleClick = (event: any) => {
    if (
      !filters.selectedService ||
      !filters.selectedCategory ||
      !filters.selectedSubCategories ||
      !(filters.zipCode || filters.searchedAddress)
    ) {
      event.preventDefault();
      setErrorMessage(
        "Please add service, category, subcategory, and location to get the desired service providers list."
      );
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    const newPayload = {
      zipCode:
        filters.zipCode !== "Unknown Zip Code"
          ? filters.zipCode
          : filters.searchedAddress,
      page: data.page,
      limit: 12,
      subCategoryIds: filters.selectedSubCategories,
    };
    if (data.isSearchClicked) {
      getServiceProvidersList(newPayload);
    }
  }, [data.isSearchClicked, data.page]);

  return (
    <>
      <Box sx={styles.background}>
        <Container sx={styles.container} maxWidth="xl">
          <Box sx={styles.boxShadow}>
            <Box sx={styles.flexDirection}>
              <Box sx={styles.fullWidth}>
                <Autocomplete
                  fullWidth
                  sx={styles.autocomplete}
                  className="selectDropdown search"
                  options={data.services}
                  getOptionLabel={(option) => option.name}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    newValue: Service | null
                  ) => {
                    updateFilter("selectedService", newValue);
                    updateFilter("selectedCategory", "");
                    updateData("serviceProviders", []);
                    updateData("isSearchClicked", false);
                    updateData("totalRecordsCount", 0);
                  }}
                  open={isOpen}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Service"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box
                              src={searchIcon}
                              alt="search icon"
                              component="img"
                              sx={{
                                width: {
                                  xl: "auto",
                                  xs: "24px",
                                },
                              }}
                            ></Box>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setIsOpen((prev) => !prev)}
                            sx={{ cursor: "pointer" }}
                          >
                            <img src={dropdownArrow} alt="dropdown icon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <FormControl
                  sx={styles.formControl}
                  fullWidth
                  variant="outlined"
                  className="selectDropdown selectCategory"
                >
                  <Select
                    labelId="select-location-label"
                    MenuProps={MenuProps}
                    value={filters.selectedCategory}
                    onChange={selectedCategoryHandleChange}
                    displayEmpty
                    IconComponent={() => <DropdownIcon onClick={handleDropdownToggle} />}
                    renderValue={(selectedValue) =>
                      selectedValue !== "" ? (
                        selectedValue
                      ) : (
                        <span className="selectPlaceholder">
                          Select Category
                        </span>
                      )
                    }
                    sx={{
                      pointerEvents: !filters.selectedService ? "none" : "auto",
                    }}
                  >
                    {data.category.map((category: any) => (
                      <MenuItem
                        key={category._id}
                        value={category.categoryName}
                      >
                        {category.categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={styles.formControlLocation}
                  fullWidth
                  variant="outlined"
                  className="selectDropdown"
                  onClick={handleClickLocationModalOpen}
                >
                  <Box sx={styles.locationBox}>
                    <span className="selectPlaceholder">
                      {filters.location ? filters.location : "Select Location"}
                    </span>
                    <img
                      src={dropdownArrow}
                      alt="arrow"
                      className="downArrowIcon"
                    />
                  </Box>
                </FormControl>
              </Box>
              <Box sx={styles.searchButtonBox}>
                <Button
                  disabled={
                    !(
                      filters.selectedService &&
                      filters.selectedCategory &&
                      filters.selectedSubCategories.length > 0 &&
                      filters.zipCode &&
                      filters.location
                    )
                  }
                  sx={styles.searchButton}
                  onClick={handleSearchButtonClick}
                >
                  Search
                  <Box
                    sx={{
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      src={searchArrow}
                      alt="search arrow"
                      width="24"
                      height="14"
                    />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
          {filters.selectedService &&
            filters.selectedCategory &&
            filters.selectedSubCategories.length > 0 &&
            (!filters.zipCode || !filters.location) && (
              <Box
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Typography sx={styles.errorMessage}>
                  Please enter a valid zip code and select address to get the
                  desired service providers list.
                </Typography>
              </Box>
            )}
          <Grid sx={styles.gridContainer} container spacing={2}>
            <>
              {data.subCategory?.map((item: any) => (
                <Grid item key={item._id} sx={{
                  paddingLeft: {
                    sm: "16px !important",
                    xs: "0 !important"
                  },
                  width: {
                    sm: "auto",
                    xs: "100%"
                  }
                }}>
                  <Button
                    sx={{
                      ...styles.subCategoryButton,
                      color: filters.selectedSubCategories.includes(item._id)
                        ? "#FFFFFF"
                        : "#8989A3",
                      backgroundColor: filters.selectedSubCategories.includes(
                        item._id
                      )
                        ? "#1CB469"
                        : "transparent",
                    }}
                    onClick={() => handleSubCategoryClick(item._id)}
                  >
                    {item.subCategoryName}
                  </Button>
                </Grid>
              ))}
            </>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              transform: "translateY(-10px)",
              width: "100%",
              margin: "auto",
            }}
            ref={resultsRef}
          >
            {data.serviceProviders.length !== 0 &&
              data.serviceProviders.map((serviceProvider: any) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                  key={serviceProvider._id}
                  sx={{
                    paddingLeft: {
                      md: "16px !important",
                      xs: "0 !important"
                    }
                  }}
                >
                  <Box sx={styles.serviceProviderBox}>
                    <Box sx={styles.serviceProviderImageWrapper}>
                      <Box
                        sx={styles.serviceProviderImage}
                        component="img"
                        src={
                          serviceProvider.profileImage?.url &&
                            !serviceProvider.profileImage.url.endsWith(".pdf")
                            ? serviceProvider.profileImage.url
                            : dummyImage
                        }
                        alt="User Image"
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      title={serviceProvider.fullName}
                      sx={styles.serviceProviderName}
                    >
                      {serviceProvider.fullName}
                    </Typography>
                    <Box sx={styles.serviceProviderTitleBox}>
                      {serviceProvider.businessTitle && (
                        <Typography
                          variant="h4"
                          sx={styles.serviceProviderTitle}
                          title={serviceProvider.businessTitle}
                        >
                          {serviceProvider.businessTitle}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      component="img"
                      src={searchDivider}
                      alt="Divider Image"
                      sx={styles.dividerImage}
                    ></Box>
                    <Link
                      to={`/detailsServiceproviders/${serviceProvider._id}?categoryId=${filters.selectedCategoryId}&zipCode=${filters.zipCode}`}
                      onClick={handleClick}
                    >
                      <Button sx={styles.viewDetailsButton}>
                        View Details
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              ))}
            {data.serviceProviders.length === 0 && data.isSearchClicked && (
              <Grid item sx={styles.noDataContainer} md={10} xs={12}>
                <Paper
                  sx={{
                    background: "transparent",
                    boxShadow: "none",
                  }}
                  elevation={2}
                >
                  <Box>
                    <Box
                      component="img"
                      src={noResultSearchImage}
                      sx={styles.noResultImage}
                    ></Box>
                    <Typography variant="h5" sx={styles.heading}>
                      No Service Providers Available
                    </Typography>
                    <Box sx={styles.noDataTextContainer}>
                      <Typography variant="body1" sx={styles.text}>
                        Currently no service provider is available in your area.{" "}
                        <Box sx={styles.breakText}>
                          <br />
                        </Box>
                        If you need more information please feel free to click
                        on{" "}
                        <Box component="span" sx={styles.blueColor}>
                          {"‘"}Get Help{"’"}
                        </Box>{" "}
                        button below
                      </Typography>
                    </Box>
                    <Button sx={styles.noDataButton}>
                      <NavLink to="/contact">Get Help</NavLink>
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} sx={{
              paddingLeft: {
                md: "16px !important",
                xs: "0 !important"
              }
            }}>
              {data.totalRecordsCount > 10 && (
                <Box sx={styles.paginationContainer}>
                  <Pagination
                    count={Math.ceil(data.totalRecordsCount / 12)}
                    page={data.page}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        slots={{
                          previous: () => (
                            <Box
                              sx={styles.paginationPrevious}
                              onClick={handlePreviousClick}
                            // Use the `page` prop
                            >
                              <img src={prevIcon} alt="Prev Icon" />
                              <Box sx={styles.paginationText}>Previous</Box>
                            </Box>
                          ),
                          next: () => (
                            <Box
                              sx={styles.paginationNext}
                              onClick={handleNextClick}
                            >
                              <Box sx={styles.paginationText}>Next</Box>
                              <img src={nextIcon} alt="Next Icon" />
                            </Box>
                          ),
                        }}
                        sx={{
                          ...(item.selected && styles.paginationItem),
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Location Modal */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClickLocationModalClose();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={locationModal}
        sx={styles.dialog}
      >
        <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
          Select Location
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseIconLocationModalClose}
          sx={styles.closeButton}
        >
          <img src={closeIcon} alt="closeIcon" />
        </IconButton>
        <DialogContent>
          <Box sx={styles.dialogContentBox}>
            <Box className="ZipCodeSearch">
              <GoogleAutocomplete
                onLoad={(autocomplete) => {
                  autocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={() => {
                  handlePlaceChanged();
                  handleClickLocationModalClose(); // Close the modal after selection
                }}
                options={{
                  types: ["geocode"],
                  // componentRestrictions: { country: 'us' }
                }}
              >
                <TextField
                  className="formControl"
                  placeholder="Search for your Zip Code"
                  value={filters.searchedAddress}
                  onChange={(e) =>
                    updateFilter("searchedAddress", e.target.value)
                  }
                  sx={styles.TextField}
                />
              </GoogleAutocomplete>
              {filters.searchedAddress && (
                <Button sx={styles.searchIcon}>
                  <Box
                    sx={styles.popupSearchIcon}
                    onClick={() => {
                      updateFilter("searchedAddress", "");
                      updateFilter("zipCode", "");
                      updateFilter("location", "");
                    }}
                  >
                    <img src={searchCloseIcon} alt="Search Close Icon" />
                  </Box>
                </Button>
              )}
            </Box>
            <Box
              sx={styles.currentLocationBox}
              onClick={handleClickUserCurrentLocation}
            >
              <img src={locationIcon} alt="locationIcon" />
              <Box sx={styles.currentLocationText}>Use Current Location</Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Search;
