import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  settingPage: {
    background: "#F5F5FA",
    // height: "100vh",
    width: "100%",
    padding: {
      xs: "10px",
      sm: "20px",
      xl: "30px",
    },
    borderRadius: {
      md: "30px 0 0 30px",
      xs: "0",
    },
  },
  settingsBox: {
    boxShadow:
      " 0px 1.85px 3.15px 0px #00000002, 0px 8.15px 6.52px 0px #00000003",
    border: "1px solid #3F8CFF4D",
    padding: {
      xl: "20px",
      xs: "14px",
    },
    borderRadius: "16px",
    background: "#fff",
    marginBottom: {
      xs: "20px",
      xl: "40px",
    },
    marginTop: {
      md: "0",
      xs: "60px",
    },
  },
  settingTabs: {
    display: "flex",
    height: {
      md: "70vh",
      xs: "auto",
    },
    overflow: "auto",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  settingsTitle: {
    color: "#070477",
    borderLeft: "3px solid #EABD19",
    paddingLeft: "14px",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "600",
    fontSize: {
      xl: "26px",
      lg: "20px",
      xs: "20px",
    },
  },
  stickyBox: {
    position: "sticky",
    top: "0",
  },
  tabs: {
    borderRight: 1,
    borderColor: "divider",
  },
  deleteAccountBox: {
    textAlign: "left",
    alignItems: "flex-start",
    justifyContent: "start",
    fontSize: "18px",
    fontFamily: '"Montserrat", sans-serif',
    textTransform: "capitalize",
    fontWeight: 400,
    minHeight: "unset",
    padding: {
      md: "0",
      xs: "0",
    },
    color: "#6a6686",
    cursor: "pointer",
    display: "flex",
    "@media (max-width: 767px)": {
      fontSize: "0",
    },
  },
  deleteAccountIconBox: {
    marginRight: {
      md: "20px",
      xs: "12px",
    },
    height: "24px",
    width: "24px",
  },
  generalDetailsTitle: {
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    fontSize: {
      xl: "24px",
      xs: "20px",
    },
    paddingBottom: "10px",
    borderBottom: "1px solid #1CB469",
    width: "100%",
    marginBottom: {
      md: "20px",
      xs: "10px",
    },
  },
  profileImagesTitle: {
    color: "#8989A3",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Montserrat, sans-serif",
  },
  profileImageBox: {
    position: "relative",
    width: "max-content",
    margin: "5px 0 10px",
  },
  profileImage: {
    height: {
      xl: "200px",
      xs: "150px",
    },
    width: {
      xl: "200px",
      xs: "150px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  editIconBox: {
    position: "absolute",
    bottom: "0",
    right: "0",
  },
  editIconContainer: {
    height: {
      xl: "65px",
      xs: "50px",
    },
    width: {
      xl: "65px",
      xs: "50px",
    },
    cursor: "pointer",
    border: "2px solid #fff",
    borderRadius: "50%",
    background: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editIconImage: {
    width: {
      xl: "30px",
      xs: "24px",
    },
  },
  formControl: {
    width: {
      lg: "80%",
      xs: "100%",
    },
  },
  businessDetailsTitle: {
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    fontSize: {
      xl: "24px",
      xs: "20px",
    },
    paddingBottom: "10px",
    borderBottom: "1px solid #1CB469",
    width: "100%",
    marginBottom: "20px",
  },
  serviceCategoryBox: {
    display: "flex",
    paddingRight: {
      md: "20px",
      xs: "10px",
    },
    justifyContent: "space-between",
  },
  serviceCategoryList: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    maxHeight: "30vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#bdbdbd",
      borderRadius: "8px",
    },
    scrollbarWidth: "thin",
  },
  serviceCategoryItem: {
    background: "#FF711D",
    padding: {
      xl: "8px 10px 8px 6px",
      xs: "4px 6px 4px 4px",
    },
    borderRadius: "37px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    fontFamily: "Montserrat, sans-serif",
  },
  serviceCategoryIcon: {
    background: "#FFF",
    height: "28px",
    width: "28px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    marginRight: {
      xl: "7px",
      xs: "4px",
    },
    justifyContent: "center",
    "& img": {
      borderRadius: "50%",
    },
  },
  serviceSubCategoryItem: {
    background: "#1CB469",
    padding: {
      xl: "12px 14px",
      xs: "10px 12px",
    },
    borderRadius: "37px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    fontFamily: "Montserrat, sans-serif",
  },
  serviceImagesList: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
  },
  serviceImage: {
    borderRadius: "10px",
    height: "158px",
    width: "158px",
    objectFit: "cover",
  },
  licenseBox: {
    background: "#e3e6e9",
    borderRadius: "12px",
    padding: "14px 20px 14px 10px",
    display: "flex",
  },
  licenseList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "5px",
  },
  licenseItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  licenseIcon: {
    height: "40px",
    width: "40px",
    marginRight: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  licenseText: {
    fontSize: "14px",
    color: "#0D0639",
    fontWeight: "500",
    wordBreak: "break-word",
    fontFamily: "Montserrat, sans-serif",
  },
  licenseSize: {
    fontSize: "12px",
    color: "#999999",
    paddingTop: "3px",
  },
  saveButtonBox: {
    position: "fixed",
    bottom: "70px",
    background: "#F5F5FA",
    width: "100%",
  },
  saveButton: {
    background: "#06038D",
    color: "#fff",
    padding: "13px 30px",
    fontSize: {
      xs: "14px",
      xl: "18px",
    },
    width: {
      xl: "20%",
      lg: "30%",
      sm: "30%",
      xs: "50%",
    },
    textTransform: "capitalize",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    marginTop: {
      sm: "20px",
      xs: "5px",
    },
    borderRadius: "12px",
    marginRight: {
      xs: "0",
      sm: "30px",
    },
    "&:disabled": {
      background: "#B3B3CF",
      color: "#fff",
      boxShadow: "none",
    },
    marginBottom: {
      lg: "20px",
      sm: "40px",
      xs: "0",
    },
  },
  passwordIconBox: {
    position: "absolute",
    right: "20px",
  },
  passwordIconButton: {
    padding: "0",
  },
  dialogTitle: {
    m: 0,
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    fontSize: "22px",
    fontWeight: "700",
  },
  dialogContent: {
    padding: {
      lg: "30px 90px 20px",
      xs: "0 0 20px 0",
    },
    "@media (orientation: landscape) and (max-width: 999px)": {
              overflowY:"unset",
            }
  },
  contentBox: {
    padding: "0 20px",
  },
  buttonContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  passwordResetdialogContent: {
    padding: {
      lg: "30px 90px 40px",
      xs: "0 0 20px 0",
    },
  },
  doneButton: {
    background: "#06038D",
    padding: "13px 60px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
  },
  yesButton: {
    background: "#B3B3CF",
    padding: "13px 80px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    margin: {
      sm: "0 20px 0 0",
      xs: "0 0 20px 0",
    },
    width: {
      sm: "max-content",
      xs: "100%",
    },
  },
  noButton: {
    background: "#06038D",
    padding: "13px 80px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    width: {
      sm: "max-content",
      xs: "100%",
    },
  },
  changePasswordSaveBtn: {
    background: "#06038D",
    color: "#fff",
    padding: "13px 30px",
    fontSize: { xs: "14px", xl: "18px" },
    width: { xl: "20%", lg: "30%", sm: "50%", xs: "100%" },
    textTransform: "capitalize",
    fontFamily: "Montserrat, sans- serif",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    fontWeight: "600",
    marginTop: "20px",
    borderRadius: "12px",
    marginRight: { xs: "0", sm: "30px" },
    "&:disabled": {
      background: "#B3B3CF",
      color: "#fff",
      boxShadow: "none",
    },
  },
  changePwdFormIconPosition: {
    position: "absolute",
    right: "20px",
  },
  zeroPadding: {
    padding: "0",
  },
};

export default styles;
