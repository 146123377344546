import { useState, useEffect, useRef } from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import DashboardHeader from "./DashboardHeader";
import DashboardFooter from "./DashboardFooter";

import { getServiceProviderDashboardData } from "../../../network/services";
import styles from "./style";
import { isToday } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import Snackbar from "@mui/material/Snackbar";
import calenderIcon from "../../../assets/Dashboard/calenderIcon.svg";
import callIcon from "../../../assets/Dashboard/callIcon.svg";
import copyIcon from "../../../assets/Dashboard/copyIcon.svg";
import mailIcon from "../../../assets/Dashboard/mailIcon.svg";
import noDataFound from "../../../assets/Dashboard/noDataFound.svg";
import popupCloseIcon from "../../../assets/Dashboard/popupCloseIcon.svg";
import prevIcon from "../../../assets/common/prevIcon.svg";
import nextIcon from "../../../assets/common/nextIcon.svg";
import DashboardMobileHeader from "./DashboardMobileHeader";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";

function formatTimestamp(utcTimestamp: string) {
  const nyTimeZone = "America/New_York";

  const formattedDate = formatInTimeZone(
    new Date(utcTimestamp),
    nyTimeZone,
    "MMM d h:mmaaa"
  );
  if (isToday(new Date(utcTimestamp))) {
    return `Today ${formatInTimeZone(new Date(utcTimestamp), nyTimeZone, "h:mmaaa")}`;
  }

  return formattedDate;
}

function formatText(text: string) {
  const maxCharacters = 120;
  if (text.length > maxCharacters) {
    return text.slice(0, maxCharacters);
  }
  return text;
}

const DashboardPage = () => {
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [inquiryList, setInquiryList] = useState([]);
  interface Inquiry {
    _id: string;
    fullName: string;
    updatedAt: string;
    phoneNumber: string;
    email: string;
    message: string;
    categoryDetails: any[];
  }

  const [selectedInquiry, setSelectedInquiry] = useState<Inquiry | null>(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const resultsRef = useRef<HTMLDivElement>(null);
  const handleClickViewDetailsModalOpen = (inquiry: any) => {
    setSelectedInquiry(inquiry);
    setViewDetailsModal(true);
  };
  const handleClickViewDetailsModalClose = () => {
    setViewDetailsModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getServiceProviderDashboardDetails = async () => {
    dispatch(setLoading(true));
    const response = await getServiceProviderDashboardData(page, 9);
    const data = await response.data;
    setTotalRecordsCount(response.totalCount);
    setTotalPages(Math.ceil(response.totalCount / 9));
    setInquiryList(data);
    dispatch(setLoading(false));
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setOpen(true);
  };

  useEffect(() => {
    getServiceProviderDashboardDetails();
  }, [page]);

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
    resultsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handlePreviousClick = () => {
    if (page > 1) {
      setPage(page - 1);
      handlePageChange(null, page - 1);
      resultsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleNextClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
      handlePageChange(null, page + 1);
      resultsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
        className="dashboardPage"
      >
        <Box
          sx={{
            display: {
              md: "none",
              xs: "block",
            },
            position: "absolute",
            width: "100%",
          }}
        >
          <DashboardMobileHeader />
        </Box>
        <Box
          sx={{
            display: {
              md: "block",
              xs: "none",
            },
          }}
        >
          <DashboardHeader />
        </Box>
        <Box sx={styles.dashboardBox}>
          <Box sx={styles.dashboardHeaderBox}>
            <Typography variant="h3" sx={styles.dashboardTitle}>
              Dashboard
            </Typography>
          </Box>
          <Box
            sx={{
              height: {
                xl: "74vh",
                sm: "76vh",
                xs: "auto",
                "@media (orientation: landscape) and (max-width: 999px)": {
                  height: "auto"
                }
              },
              overflow: "auto",
            }}
          >
            <Grid container spacing={{ md: 3, xs: 2 }} ref={resultsRef}>
              {inquiryList.map((inquiry: any, index) => (
                <Grid item xs={12} md={6} lg={4} key={inquiry._id}>
                  <Box sx={styles.card}>
                    <Box sx={styles.cardHeader}>
                      <Box sx={styles.cardHeaderTitle}>
                        <Typography variant="h3" sx={styles.userName}>
                          {inquiry.fullName}
                        </Typography>
                        <Box sx={styles.dateWrapper}>
                          <img src={calenderIcon} alt="Calender Icon" />
                          <Typography variant="h3" sx={styles.date}>
                            {formatTimestamp(inquiry.updatedAt)}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={styles.contactWrapper}>
                        <Box sx={styles.contactInnerWrapper}>
                          <Box sx={styles.callIcon}>
                            <img src={callIcon} alt="Call Icon" />
                          </Box>
                          <Typography variant="h3" sx={styles.callText}>
                            {inquiry.phoneNumber}
                          </Typography>
                        </Box>

                        <Box sx={styles.mailIconWrapper}>
                          <Box sx={styles.mailIcon}>
                            <img src={mailIcon} alt="Mail Icon" />
                          </Box>
                          <Typography variant="h3" sx={styles.mailText}>
                            {inquiry.email}
                          </Typography>
                          <Box
                            sx={styles.mailIconImage}
                            onClick={() =>
                              handleCopy(
                                inquiry.phoneNumber + " " + inquiry.email
                              )
                            }
                          >
                            <img src={copyIcon} alt="Copy Icon" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: {
                          xl: "20px",
                          xs: "15px",
                        },
                      }}
                    >
                      <Box sx={styles.flexWrapper}>
                        <Typography variant="h4" sx={styles.sectionLabel}>
                          Service Category
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={styles.viewAllButton}
                          onClick={() =>
                            handleClickViewDetailsModalOpen(inquiry)
                          }
                        >
                          VIEW ALL
                        </Typography>
                      </Box>
                      <Box sx={styles.serviceContainer}>
                        {inquiry.categoryDetails.map((category: any) => (
                          <Box sx={styles.serviceBox}>
                            <Box sx={styles.serviceIconBox}>
                              <img
                                src={category.displayImage.url}
                                width="100%"
                                alt="Pipe Installation Image"
                              />
                            </Box>
                            {category.categoryName}
                          </Box>
                        ))}
                      </Box>
                      <Box>
                        <Typography variant="h4" sx={styles.descriptionTitle}>
                          Description
                        </Typography>
                        <Box>
                          <Typography sx={styles.descriptionText}>
                            {formatText(inquiry.message.slice(0, 88))}
                            {inquiry.message.length > 50 && (
                              <Box
                                component="span"
                                sx={styles.readMoreButton}
                                onClick={() =>
                                  handleClickViewDetailsModalOpen(inquiry)
                                }
                              >
                                &nbsp; Read More
                              </Box>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {inquiryList.length === 0 && (
              <Box sx={styles.noResultDataWrapper}>
                <Box
                  component="img"
                  src={noDataFound}
                  sx={styles.noResultImage}
                ></Box>
                <Typography variant="h5" sx={styles.heading}>
                  "Currently, there are no service requests available. Please
                  check back later for new requests."
                </Typography>
              </Box>
            )}

            {totalRecordsCount > 9 && (
              <Box sx={styles.paginationContainer}>
                <Pagination
                  count={Math.ceil(totalRecordsCount / 9)}
                  page={page}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      slots={{
                        previous: () => (
                          <Box
                            sx={styles.paginationPrevious}
                            onClick={handlePreviousClick}
                          >
                            <img src={prevIcon} alt="Prev Icon" />
                            <Box sx={styles.paginationText}>Previous</Box>
                          </Box>
                        ),
                        next: () => (
                          <Box
                            sx={styles.paginationNext}
                            onClick={handleNextClick}
                          >
                            <Box sx={styles.paginationText}>Next</Box>
                            <img src={nextIcon} alt="Prev Icon" />
                          </Box>
                        ),
                      }}
                      sx={{
                        ...(item.selected && styles.paginationItem),
                      }}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
          <Snackbar
            open={open}
            autoHideDuration={1500}
            onClose={handleClose}
            message="Text copied"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          />
          <DashboardFooter />
        </Box>
      </Box>

      {/* Modal Inquiry Submitted Successfully */}
      <Dialog
        onClose={handleClickViewDetailsModalClose}
        aria-labelledby="customized-dialog-title"
        open={viewDetailsModal}
        sx={{
          "& .MuiDialog-paper": {
            width: {
              xs: "90%",
              sm: "70%",
              md: "680px",
            },
            borderRadius: "20px",
            maxWidth: "90%",
          },
          "& .MuiDialogContent-root": {
            padding: {
              sm: "20px 24px",
              xs: "15px",
            },
            "@media (orientation: landscape) and (max-width: 999px)": {
              padding: "20px 0"
            }
          },
        }}
      >
        <DialogTitle id="customized-dialog-title"></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickViewDetailsModalClose}
          sx={styles.closeButton}
        >
          <img src={popupCloseIcon} alt="closeIcon" />
        </IconButton>
        <DialogContent sx={styles.dialogContent}>
          {selectedInquiry && (
            <>
              <Box sx={styles.dialogContentBox}>
                <Box sx={styles.flexBetween}>
                  <Typography variant="h3" sx={styles.title}>
                    {selectedInquiry.fullName}
                  </Typography>
                  <Box sx={styles.flexCenter}>
                    <img src={calenderIcon} alt="Calender Icon" />
                    <Typography variant="h3" sx={styles.calendarText}>
                      {formatTimestamp(selectedInquiry.updatedAt)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.contactBox}>
                  <Box sx={styles.contactItem}>
                    <Box sx={styles.contactIcon}>
                      <img src={callIcon} alt="Call Icon" />
                    </Box>
                    <Typography variant="h3" sx={styles.contactText}>
                      {selectedInquiry.phoneNumber}
                    </Typography>
                    <Box
                      sx={styles.copyIcon}
                      onClick={() =>
                        selectedInquiry &&
                        handleCopy(selectedInquiry.phoneNumber)
                      }
                    >
                      <img src={copyIcon} alt="Copy Icon" />
                    </Box>
                  </Box>
                  <Box sx={styles.flexCenter}>
                    <Box sx={styles.emailIcon}>
                      <img src={mailIcon} alt="Mail Icon" />
                    </Box>
                    <Typography variant="h3" sx={styles.contactText}>
                      {selectedInquiry.email}
                    </Typography>
                    <Box
                      sx={styles.copyIcon}
                      onClick={() =>
                        selectedInquiry && handleCopy(selectedInquiry.email)
                      }
                    >
                      <img src={copyIcon} alt="Copy Icon" />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.serviceCategoryBox}>
                <Box sx={styles.serviceCategoryHeader}>
                  <Typography variant="h4" sx={styles.serviceCategoryTitle}>
                    Service Category
                  </Typography>
                </Box>
                <Box sx={styles.serviceCategoryList}>
                  {selectedInquiry.categoryDetails?.map((category: any) => (
                    <Box sx={styles.serviceCategoryItem}>
                      <Box sx={styles.serviceCategoryIcon}>
                        <img
                          src={category.displayImage.url}
                          width="100%"
                          alt="Pipe Installation Image"
                        />
                      </Box>
                      {category.categoryName}
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Typography variant="h4" sx={styles.descriptionTitle}>
                    Description
                  </Typography>
                  <Typography sx={styles.modalDescriptionText}>
                    {selectedInquiry.message}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DashboardPage;
