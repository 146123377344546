import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/icons/backIcon.svg";
import contactCallIcon from "../../assets/icons/contactCallIcon.svg";
import contactGmailIcon from "../../assets/icons/contactGmailIcon.svg";
import contactWebIcon from "../../assets/icons/contactWebIcon.svg";

const ContactUs = () => {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          background: "#F5F5FA",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "40px",
            height: "70vh",
            width: "80%",
            padding: {
              sm: "60px 0 40px",
              xs: "70px 0 40px",
            },
            position: "relative",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: {
                lg: "40px",
                xs: "20px",
              },
              left: {
                lg: "40px",
                xs: "20px",
              },
            }}
          >
            <Box onClick={() => navigate(-1)}>
              <img src={backIcon} alt="backArrowIcon" />
            </Box>
          </Box>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              color: "#06038D",
              fontFamily: "Montserrat, sans- serif",
              fontWeight: "700",
              fontSize: {
                lg: "28px",
                xs: "22px",
              },
              padding: "0 0 20px",
            }}
          >
            Let’s stay connected
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Montserrat, sans- serif",
              fontSize: {
                lg: "20px",
                xs: "18px",
              },
              color: "#8989A3",
              textAlign: "center",
              fontWeight: "500",
              padding: "0 30px",
            }}
          >
            It's very easier to get in touch with{" "}
            <span
              style={{
                color: "#06038D",
                fontWeight: "700",
              }}
            >
              Home Seller Savings,LLC
            </span>{" "}
            <Box
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                },
                height: "0px",
              }}
            >
              <br />
            </Box>
            Email us your query and we'll get back to you as soon as possible!
          </Typography>
          <Box
            sx={{
              background: "#8989A34D",
              padding: {
                lg: "20px 60px",
                xs: "20px",
              },
              margin: {
                lg: "50px auto",
                xs: "20px auto",
              },
              borderRadius: "20px",
              width: {
                lg: "50%",
                md: "60%",
                sm: "60%",
                xs: "80%",
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Montserrat, sans- serif",
                fontSize: {
                  lg: "28px",
                  xs: "26px",
                },
                color: "#0D0639",
                fontWeight: "600",
              }}
            >
              Contact Us
            </Typography>
            <Box
              sx={{
                paddingTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginRight: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "50px",
                    minWidth: "50px",
                    background: "#231F20",
                    borderRadius: "50%",
                  }}
                >
                  <Box
                    component="img"
                    width="25px"
                    src={contactGmailIcon}
                    alt="Contact Gmail Icon"
                  ></Box>
                </Box>
                <Link
                  sx={{
                    wordBreak: "break-all",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#0D0639",
                    textDecoration: "none",
                  }}
                  href="mailto:homesellerinfo@gmail.com"
                >
                  homesellerinfo@gmail.com
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginRight: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "50px",
                    minWidth: "50px",
                    background: "#231F20",
                    borderRadius: "50%",
                  }}
                >
                  <Box
                    component="img"
                    width="30px"
                    src={contactCallIcon}
                    alt="Contact Gmail Icon"
                  ></Box>
                </Box>
                <Link
                  sx={{
                    wordBreak: "break-all",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#0D0639",
                    textDecoration: "none",
                  }}
                  href="tel:800-234-567"
                >
                  800-234-567
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginRight: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "50px",
                    minWidth: "50px",
                    background: "#231F20",
                    borderRadius: "50%",
                  }}
                >
                  <Box
                    component="img"
                    width="26px"
                    src={contactWebIcon}
                    alt="Contact Gmail Icon"
                  ></Box>
                </Box>
                <Link
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    wordBreak: "break-all",
                    color: "#0D0639",
                    textDecoration: "none",
                  }}
                  href="www.homesellerinfo.help"
                >
                  www.homesellerinfo.help
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
