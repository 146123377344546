import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import serviceExpertImage from "../../../../assets/Banner/serviceExpertImage.png";
import expertServiceImage from "../../../../assets/Banner/expertServiceImage.png";
import { useState } from "react";

const Banner = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box
        sx={{
          background: "#06038D",
          boxShadow: "4px 8px 24px 0px #3F8CFF40",
          padding: {
            xl: "0 30px 0 30px",
            xs: "20px 16px 0 16px",
          },
          backgroundImage: `url(${expertServiceImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
          position: "relative",
          marginTop: {
            md: "-70px",
            xs: "0",
          },
        }}
      >
        <Grid
          sx={{
            "@media (min-width: 1536px)": {
              maxWidth: "1536px",
              margin: "auto",
            },
            overflow: "hidden",
            padding: {
              xl: "0 24px",
              lg: "0 50px",
            },
          }}
          container
          maxWidth="xl"
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
            item
            xs={12}
            sm={5}
            lg={4}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    xl: "62px",
                    lg: "50px",
                    xs: "40px",
                  },
                  color: "#fff",
                  fontFamily: "Montserrat, sans- serif",
                  fontWeight: "600",
                  lineHeight: {
                    lg: "69px",
                    xs: "54px",
                  },
                  paddingBottom: {
                    sm: "0",
                    xs: "20px",
                  },
                }}
              >
                Expert Service Providers
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "relative",
              bottom: {
                md: "-80px",
                sm: "-40px",
              },
            }}
            item
            xs={12}
            sm={7}
            lg={8}
          >
            <Box
              sx={{
                display: "flex",
                height: {
                  lg: "500px",
                  md: "auto",
                },
              }}
            >
              <img
                src={serviceExpertImage}
                width="100%"
                alt="bannerImage"
                onLoad={() => setIsLoading(false)}
              />
              {isLoading && (
                <CircularProgress
                  size={40}
                  sx={{
                    position: "absolute",
                    top: "30%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Banner;
