const styles = {
    container: {
        padding: {
            lg: "0px 0 80px",
            xs: "40px 0 40px"
        },
        background: "#F5F5FA"
    },
    containerBox: {
        maxWidth: "xl",
        padding: {
            xl: "0 24px",
            lg: "0 60px",
        }
    },
    box: {
        textAlign: "center",
        backgroundColor: "#1CB469",
        borderRadius: "30px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
    },
    paddingBox: {
        padding: {
            lg: "40px 0",
            xs: "40px 20px"
        }
    },
    title: {
        fontSize: {
            xl: "34px",
            lg: "28px",
            xs: "22px"
        },
        color: "#fff",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        paddingBottom: "14px",
    },
    subtitle: {
        fontSize: {
            xl: "18px",
            xs: "16px"
        },
        color: "#fff",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 400,
        paddingBottom: "14px",
    },
    button: {
        background: "#fff",
        color: "#070477",
        padding: {
            xs: "8px 20px",
            sm: "13px 42px"
        },
        fontSize: {
            xs: "16px",
            xl: "18px"
        },
        boxShadow: "0px 14px 24px 0px #0000001A",
        textTransform: "capitalize",
        border: "1.6px solid #1CB469",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 600,
        borderRadius: "12px",
        margin: {
            xs: "10px 0 0 0",
            sm: "10px 0 0 0",
            lg: "20px 0 0 0",
        }
    },
    imageRight: {
        position: "absolute",
        top: 0,
        height: "100%",
        right: "-50px",
        display: {
            xs: "none",
            sm: "block"
        }
    },
    imageLeft: {
        position: "absolute",
        top: 0,
        height: "100%",
        left: "-50px",
        display: {
            xs: "none",
            sm: "block"
        }
    }
};

export default styles;
