import { Box } from "@mui/material";
import React from "react";
import FileIcon1 from "../../assets/ServiceProvider/file icon1.png";
import FileIcon2 from "../../assets/ServiceProvider/file icon2.png";
import closeIcon from "../../assets/common/closeIcon.svg";

interface showSelectedFileProps {
  index: number;
  fileName?: string;
  fileSize?: number;
  handleRemoveFile: (index: number) => void;
}

const ShowSelectedFiles: React.FC<showSelectedFileProps> = ({
  index,
  fileName,
  fileSize,
  handleRemoveFile,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #E2E8F0",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
        padding: {
          sm: "8px 10px",
          xs: "8px",
        },
        marginBottom: "12px",
        background: "#007AE405",
        alignItems: "center",
      }}
      key={index}
    >
      <Box
        component="img"
        sx={{
          width: {
            xl: "48px",
            xs: "38px",
          },
          height: {
            xl: "48px",
            xs: "38px",
          },
        }}
        src={index % 2 === 0 ? FileIcon1 : FileIcon2}
        alt="Image Icon"
      ></Box>
      <Box
        sx={{
          padding: {
            xl: "15px",
            xs: "8px",
            sm: "10px",
          },
          width: "100%",
          textAlign: "start",
        }}
      >
        <Box
          sx={{
            fontSize: {
              xs: "14px",
              xl: "16px",
            },
            color: "#717171",
            fontWeight: "500",
            wordBreak: "break-all",
            fontFamily: "Montserrat, sans- serif",
          }}
        >
          {fileName}
          <Box
            sx={{
              fontSize: "12px",
              color: "#999999",
              paddingTop: "5px",
            }}
          >
            {fileSize ? Math.round(fileSize) : 0} kB
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            background: "#000000",
            borderRadius: "30px",
            height: "4px",
            marginTop: "15px",
          }}
        ></Box>
      </Box>

      <Box>
        <Box
          src={closeIcon}
          alt="Delete Image"
          sx={{
            cursor: "pointer",
            width: {
              xl: "28px",
              xs: "20px",
            },
          }}
          onClick={() => handleRemoveFile(index)}
          component="img"
        ></Box>
        <Box
          sx={{
            fontSize: "12px",
            color: "#999999",
            paddingTop: "5px",
            fontWeight: "600",
          }}
        >
          100%
        </Box>
      </Box>
    </Box>
  );
};

export default ShowSelectedFiles;
