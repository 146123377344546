import { SxProps, Theme } from "@mui/system";
import cardBgImage from "../../../../assets/ExpertServiceProvider/cardBgImage.png";

const styles: { [key: string]: SxProps<Theme> } = {
  background: {
    background: "#F5F5FA",
  },
  container: {
    maxWidth: "xl",
    padding: {
      xl: "0 24px",
      lg: "0 60px",
    },
  },
  boxShadow: {
    boxShadow: "0px 31px 81px 0px #00114D0F",
    background: "#fff",
    padding: {
      xl: "25px 20px 25px 30px",
      lg: "15px 20px 15px 20px",
      xs: "20px",
    },
    borderRadius: "20px",
    transform: "translateY(-60px)",
  },
  flexDirection: {
    display: "flex",
    flexDirection: {
      md: "row",
      xs: "column",
    },
  },
  fullWidth: {
    display: "flex",
    width: "100%",
    flexDirection: {
      md: "row",
      xs: "column",
    },
  },
  autocomplete: {
    paddingRight: {
      md: "30px",
      xs: "0",
    },
    borderRight: {
      md: "1px solid #2B4640CC",
      xs: "0",
    },
    borderBottom: {
      md: "none",
      xs: "1px solid #2B4640CC",
    },
    paddingBottom: {
      md: "0",
      xs: "10px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorMessage: {
    fontSize: {
      xs: "12px",
      md: "14px",
    },
    color: "#FF0000",
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    transform: "translateY(-35px)",
    marginBottom: "10px",
  },
  formControl: {
    paddingRight: {
      md: "30px",
      xs: "0",
    },
    borderRight: {
      md: "1px solid #2B4640CC",
      xs: "0",
    },
    borderBottom: {
      md: "none",
      xs: "1px solid #2B4640CC",
    },
    paddingBottom: {
      md: "0",
      xs: "10px",
    },
    paddingTop: {
      md: "0",
      xs: "10px",
    },
    display: "flex",
    justifyContent: "center",
  },
  formControlLocation: {
    paddingRight: {
      lg: "30px",
      xs: "15px",
    },
    paddingBottom: {
      md: "0",
      xs: "10px",
    },
    paddingTop: {
      md: "0",
      xs: "10px",
    },
  },
  locationBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 17px 0 17px 12px",
    borderRadius: "4px",
    cursor: "pointer",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  searchButtonBox: {
    display: "flex",
    margin: {
      md: "unset",
      xs: "auto",
    },
    alignItems: "center",
    "& .Mui-disabled": {
      opacity: "0.6",
      color: "#fff !important",
    },
    "& .Mui-disabled svg path": {
      fill: "#fff",
    },
  },
  searchButton: {
    background: "#1CB469",
    color: "#fff",
    fontSize: {
      xs: "14px",
      xl: "18px",
    },
    padding: {
      xs: "10px 20px",
    },
    height: "max-content",
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    borderRadius: "12px",
    "& svg": {
      marginTop: {
        xs: "6px",
        xl: "0",
      },
      width: {
        xs: "19px",
        xl: "auto",
      },
    },
  },
  gridContainer: {
    width: {
      lg: "90%",
      xs: "100%",
    },
    margin: {
      lg: "0 auto",
      xs: "0 auto 0",
    },
    transform: {
      xs: "translateY(-35px)",
      md: "translateY(-45px)",
    },
  },
  subCategoryButton: {
    border: "1px solid #8989A3",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    fontSize: {
      xl: "16px",
      xs: "14px",
    },
    width: "100%",
    borderRadius: "40px",
    padding: "10px 14px",
    textTransform: "capitalize",
  },
  serviceProviderBox: {
    borderRadius: "22px",
    padding: {
      xl: "24px 16px",
      xs: "20px 16px",
    },
    height: "82%",
    textAlign: "center",
    border: "1px solid #3F8CFF66",
    backgroundImage: `url(${cardBgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginBottom: {
      xs: "20px",
    },
  },
  serviceProviderImageWrapper: {
    height: {
      xl: "100px",
      xs: "60px",
    },
    width: {
      xl: "100px",
      xs: "60px",
    },
    border: "3px solid #437BE9",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    objectFit: "cover",
    borderRadius: "50%",
    margin: "auto",
  },
  serviceProviderImage: {
    height: {
      xl: "100px",
      xs: "60px",
    },
    width: {
      xl: "100px",
      xs: "60px",
    },
    borderRadius: "50%",
    objectFit: "cover",
  },
  serviceProviderName: {
    fontSize: {
      xl: "20px",
      xs: "16px",
    },
    color: "#000000",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    padding: "16px 0",
    wordBreak: "break-word",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "70%",
    margin: "auto"
  },
  serviceProviderTitleBox: {
    width: "80%",
    margin: "auto",
  },
  serviceProviderTitle: {
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    color: "#EB863D",
    background: "#FEF1EC",
    fontWeight: "500",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "uppercase",
    padding: "7px 12px",
    borderRadius: "100px",
    margin: "auto",
    wordBreak: "break-word",
    height: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dividerImage: {
    width: "100%",
    margin: "20px 0 15px",
  },
  viewDetailsButton: {
    background: "#F2F2FC",
    border: "2px solid #06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    fontSize: {
      xl: "16px",
      xs: "14px",
    },
    width: "80%",
    borderRadius: "8px",
    color: "#06038D",
    padding: {
      xl: "8px 14px",
      xs: "6px 14px",
    },
    textTransform: "capitalize",
  },
  noDataContainer: {
    display: "flex",
    justifyContent: "center",
    margin: {
      lg: "20px auto",
      xs: "0 auto"
    },
    alignItems: "center",
    textAlign: "center",
    marginBottom: "60px",
    background: "#fff",
    borderRadius: "20px",
    padding: {
      lg: "60px 40px 40px",
      xs: "20px !important"
    },
    boxShadow: "0px 69px 42px 0px #C8C8C840",
  },
  noResultImage: {
    width: {
      xl: "300px",
      xs: "200px",
    },
  },
  heading: {
    fontFamily: "Montserrat, sans-serif",
    color: "#0D0639",
    fontSize: {
      xl: "20px",
      xs: "18px",
    },
    fontWeight: "600",
    marginBottom: "20px",
  },
  text: {
    fontFamily: "Montserrat, sans-serif",
    color: "#0D0639CC",
    fontSize: {
      xl: "20px",
      xs: "16px",
    },
    fontWeight: "400",
    marginBottom: {
      xl: "40px",
      xs: "20px",
    },
    textAlign: "center",
  },
  breakText: {
    display: {
      md: "block",
      xs: "none",
    },
    height: "0px",
  },
  blueColor: {
    color: "#06038D",
  },
  noDataTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3",
  },
  noDataButton: {
    background: "#06038D",
    padding: {
      xl: "13px 60px",
      xs: "10px 30px",
    },
    borderRadius: "12px",
    fontSize: { xl: "18px", xs: "14px" },
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    color: "#fff",
    fontWeight: "600",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
  },
  paginationContainer: {
    position: "relative",
    padding: {
      lg: "30px 0 40px",
      xs: "20px 0 0",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& ul li:last-child": {
      position: {
        xs: "unset",
        md: "absolute",
      },
      right: "20px",
    },
    "& ul li:first-child": {
      position: {
        xs: "unset",
        md: "absolute",
      },
      left: "20px",
    },
    "& .MuiPaginationItem-root": {
      padding: "0",
      margin: "0"
    },
  },
  paginationItem: {
    backgroundColor: "transparent !important",
    borderTop: "2px solid #06038D",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
    color: "#000000",
    boxShadow: "none",
    borderRadius: "0",
  },
  paginationPrevious: {
    display: "flex",
    alignItems: "center",
  },
  paginationNext: {
    display: "flex",
    alignItems: "center",
  },
  paginationText: {
    color: "#00000080",
    fontSize: "18px",
    margin: "0 10px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    display: {
      sm: "block",
      xs: "none"
    }
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: {
        xs: "90%",
        sm: "70%",
        md: "600px",
      },
      maxWidth: "90%",
      borderRadius: "15px",
    },
    zIndex: 9,
  },
  dialogTitle: {
    m: 0,
    p: 2,
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    fontSize: "18ppx",
    fontWeight: "600",
  },
  closeButton: (theme: Theme) => ({
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  }),
  dialogContentBox: {
    padding: {
      md: "0 20px",
      xs: "0",
    },
    "& .formControl input": {
      paddingRight: "60px",
      fontSize: {
        xl: "16px",
        xs: "14px",
      },
    },
  },
  zipCodeSearch: {
    width: "90%",
    position: "relative",
  },
  TextField: {
    width: "100%",
    fontSize: {
      xl: "16px",
      xs: "14px",
    },
  },
  searchIcon: {
    position: "absolute",
    right: "0px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  popupSearchIcon: {
    width: "24px",
  },
  currentLocationBox: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #0D0639",
    color: "#000000",
    background: "#FAFAFA",
    borderRadius: "10px",
    padding: {
      xl: "12px 20px",
      xs: "12px 14px",
    },
    margin: "30px 0 30px",
    cursor: "pointer",
    "& img": {
      width: "22px",
    },
  },
  currentLocationText: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: {
      xl: "16px",
      xs: "14px",
    },
    fontWeight: "500",
    color: "#0D0639",
    paddingLeft: "10px",
  },
};

export default styles;
