import { SxProps, Theme } from "@mui/system";
import testimonialBgColor from "../../../../assets/Consumer/testimonialBgColor.png";

const styles: { [key: string]: SxProps<Theme> } = {
  mainBox: {
    padding: {
      xs: "30px 0",
      lg: "60px 10px 100px",
    },
    backgroundImage: `url(${testimonialBgColor})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  title: {
    fontSize: {
      lg: "40px",
      xs: "28px",
    },
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "14px",
  },
  subtitle: {
    fontSize: {
      lg: "24px",
      xs: "20px",
    },
    color: "#8989A3",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    textAlign: "center",
    marginBottom: {
      lg: "50px",
      xs: "20px",
    },
  },
  serviceBox: {
    textAlign: "center",
  },
  serviceIconBox: {
    background: "#fff",
    borderRadius: "50%",
    boxShadow: "0px 10px 20px 0px #3F8CFF1A",
    height: "200px",
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  serviceTitle: {
    fontSize: "20px",
    lineHeight: "32px",
    color: "#0D0639",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    margin: "20px 0 20px",
  },
  serviceDescription: {
    fontSize: "14px",
    lineHeight: "32px",
    color: "#2A2A2A",
    fontWeight: "400",
    fontFamily: "Montserrat, sans-serif",
    padding: {
      lg: "0 33px",
      xs: "0",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
    justifyContent: "center",
    marginTop: "30px",
  },
  contactButton: {
    background: "#1CB469",
    color: "#fff",
    padding: "13px 30px",
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #1CB469",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    marginTop: "20px",
    borderRadius: "12px",
    marginRight: {
      xs: "0",
      sm: "30px",
    },
    width: {
      xs: "100%",
      sm: "auto",
    },
  },
  learnMoreButton: {
    background: "transparent",
    color: "#06038D",
    padding: "13px 30px",
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    marginTop: "20px",
    borderRadius: "12px",
    width: {
      xs: "100%",
    },
  },
  backgroundBox: {
    background: "#06038D",
    padding: {
      xl: "60px 0 50px",
      xs: "40px 0",
      lg: "60px 20px",
    },
    position: "relative",
  },
  vectorImageBox: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  homeSellingTitle: {
    fontSize: {
      lg: "46px",
      xs: "32px",
    },
    color: "#1CB469",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "10px",
  },
  homeSellingSubtitle: {
    fontSize: {
      lg: "24px",
      xs: "20px",
    },
    color: "#fff",
    padding: {
      sm: "0",
      xs: "0 16px",
    },
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    textAlign: "center",
  },
  containerPadding: {
    padding: {
      lg: "70px 0 0 0",
      xs: "20px 0 0 0",
    },
  },
  gridContainer: {
    display: "flex",
    alignItems: {
      xl: "flex-start",
      xs: "center",
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: {
      md: "flex-end",
      xs: "center",
    },
  },
  serviceImageBox: {
    display: "flex",
    width: {
      xl: "80%",
      md: "100%",
      xs: "70%",
    },
    justifyContent: "flex-end",
  },
  loadingBox: {
    color: "white",
    position: "absolute",
    display: "flex",
    left: "20%",
  },
  serviceGridItem: {
    marginBottom: {
      xl: "60px",
      xs: "30px",
    },
  },
  serviceContainer: {
    background: "#fff",
    padding: "20px 20px 14px 20px",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    borderRadius: "10px",
    boxShadow: "0px 10px 29.2px 0px #0000001A",
  },
  serviceIconContainer: {
    height: "60px",
    width: "60px",
    background: "#FFF9E4",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  serviceContainerDescription: {
    fontSize: "14px",
    color: "#202124",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: "22px",
    paddingTop: "15px",
    fontWeight: "400",
  },
  viewMoreButtonBox: {
    marginTop: {
      xs: "40px",
      md: "0",
    },
    textAlign: {
      md: "left",
      xs: "center",
    },
  },
  viewMoreButton: {
    background: "transparent",
    color: "#fff",
    padding: "13px 30px",
    fontSize: {
      xs: "16px",
      sm: "18px",
    },
    textTransform: "capitalize",
    border: "1.6px solid #fff",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    marginTop: {
      md: "40px",
      lg: "40px",
      xl: "20px",
    },
    borderRadius: "12px",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    width: {
      xs: "100%",
      sm: "auto",
    },
  },
};

export default styles;
