import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
} from "@mui/material";
import { getQuestions } from "../../../../network/common";
import "./faq.css";
import crossmark from "../../../../assets/faq/crossmark.svg";
import search from "../../../../assets/faq/search.svg";
import plus from "../../../../assets/faq/plus.svg";
import minus from "../../../../assets/faq/minus.svg";
import styles from "./style";

const TabPanel: React.FC<{
  children?: React.ReactNode;
  index: number;
  value: number;
}> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const Faq: React.FC = () => {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [accordionData, setAccordionData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState<any>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setExpanded(false);
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    const filteredData = accordionData.filter(
      (item: any) =>
        item.question.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.answer.toLowerCase().includes(e.target.value.toLowerCase())
    );
    console.log("filtered Data is", filteredData);
    setFilteredData(filteredData);
  };

  const getQuestionsList = async () => {
    const res = await getQuestions();
    console.log(res);
    setAccordionData(res.data);
  };

  useEffect(() => {
    getQuestionsList();
  }, []);

  const groupByType = (data: any[]): { [key: string]: any[] } => {
    return data.reduce((acc: { [key: string]: any[] }, item: any) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {});
  };

  return (
    <>
      <Box sx={styles.background}>
        <Container maxWidth="xl" sx={styles.container}>
          <Box sx={styles.paddingBottom}>
            <Typography variant="h3" sx={styles.title}>
              <span className="have-questions">Have</span> questions?
            </Typography>
            <Box sx={styles.searchBox}>
              <Box className="inputFormControl">
                <TextField
                  placeholder="Search here |"
                  value={searchInput}
                  onChange={handleSearchInput}
                  InputProps={{
                    endAdornment: searchInput && (
                      <InputAdornment position="end">
                        <img
                          src={crossmark}
                          alt="cross mark Image"
                          onClick={() => setSearchInput("")}
                        />
                      </InputAdornment>
                    ),
                    style: {
                      paddingRight: "40px",
                    },
                  }}
                  sx={styles.inputFormControl}
                />
              </Box>
              <Box sx={styles.searchIconBox}>
                <img src={search} alt="Search Image" />
              </Box>
            </Box>
          </Box>
        </Container>
        <Box sx={styles.accordionContainer}>
          <Container sx={styles.container} maxWidth="xl">
            {!searchInput && (
              <Box className="tabbing-section">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  sx={styles.tabbingSection}
                >
                  <Tab label="Program Plans" id="tab-0" />
                  <Tab label="Quick Buy" id="tab-1" />
                  <Tab label="Service" id="tab-2" />
                </Tabs>
                <Box className="text" sx={styles.tabPanelText}>
                  <TabPanel value={value} index={0}>
                    {accordionData.map((item: any) => (
                      <>
                        {item.type === "Program Plans" && (
                          <Accordion
                            key={item._id}
                            expanded={expanded === item._id}
                            onChange={handleAccordionChange(item._id)}
                            sx={{ boxShadow: "none" }}
                          >
                            <AccordionSummary
                              aria-controls={`${item._id}-content`}
                              id={`${item._id}-header`}
                              expandIcon={
                                expanded === item._id ? (
                                  <img src={minus} alt="Collapse" />
                                ) : (
                                  <img src={plus} alt="Expand" />
                                )
                              }
                              sx={styles.accordionSummary}
                            >
                              {item.question}
                            </AccordionSummary>
                            <AccordionDetails sx={styles.accordionDetails}>
                              {item.answer}
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </>
                    ))}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Typography variant="body1">
                      {accordionData.map((item: any) => (
                        <>
                          {item.type === "Quick Buy" && (
                            <Accordion
                              key={item._id}
                              expanded={expanded === item._id}
                              onChange={handleAccordionChange(item._id)}
                              sx={{ boxShadow: "none" }}
                            >
                              <AccordionSummary
                                aria-controls={`${item._id}-content`}
                                id={`${item._id}-header`}
                                expandIcon={
                                  expanded === item._id ? (
                                    <img src={minus} alt="Collapse" />
                                  ) : (
                                    <img src={plus} alt="Expand" />
                                  )
                                }
                                sx={styles.accordionSummary}
                              >
                                {item.question}
                              </AccordionSummary>
                              <AccordionDetails sx={styles.accordionDetails}>
                                {item.answer}
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </>
                      ))}
                    </Typography>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Typography variant="body1">
                      {accordionData.map((item: any) => (
                        <>
                          {item.type === "Service" && (
                            <Accordion
                              key={item._id}
                              expanded={expanded === item._id}
                              onChange={handleAccordionChange(item._id)}
                              sx={{ boxShadow: "none" }}
                            >
                              <AccordionSummary
                                aria-controls={`${item._id}-content`}
                                id={`${item._id}-header`}
                                expandIcon={
                                  expanded === item._id ? (
                                    <img src={minus} alt="Collapse" />
                                  ) : (
                                    <img src={plus} alt="Expand" />
                                  )
                                }
                                sx={styles.accordionSummary}
                              >
                                {item.type === "Service" && item.question}
                              </AccordionSummary>
                              <AccordionDetails sx={styles.accordionDetails}>
                                {item.type === "Service" && item.answer}
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </>
                      ))}
                    </Typography>
                  </TabPanel>
                </Box>
              </Box>
            )}
            {searchInput && (
              <Box className="tabbing-section">
                {/* Grouping data by type */}
                {Object.entries(groupByType(filteredData)).map(
                  ([type, items]: [string, any[]]) => (
                    <Box key={type} sx={styles.searchResultType}>
                      <Typography variant="h5" sx={styles.searchResultTitle}>
                        {type}
                      </Typography>

                      {items.map((item: any) => (
                        <Accordion
                          key={item._id}
                          expanded={expanded === item._id}
                          onChange={handleAccordionChange(item._id)}
                          sx={{ boxShadow: "none" }}
                        >
                          <AccordionSummary
                            aria-controls={`${item._id}-content`}
                            id={`${item._id}-header`}
                            expandIcon={
                              expanded === item._id ? (
                                <img src={minus} alt="Collapse" />
                              ) : (
                                <img src={plus} alt="Expand" />
                              )
                            }
                            sx={{ fontSize: "20px", fontWeight: "500" }}
                          >
                            {item.question}
                          </AccordionSummary>
                          <AccordionDetails sx={styles.accordionDetails}>
                            {item.answer}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  )
                )}
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Faq;
