import { SxProps } from '@mui/material';

export const timeRangeSelectorStyles: Record<string, SxProps> = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    slotsContainer: {
        maxHeight: {
            md: '120px',
            lg: '200px',
        },
        overflowY: 'scroll',
        scrollbarWidth: 'none',
    },
    slot: {
        display: 'flex',
        gap: '10px',
        paddingBottom: '10px',
        alignItems: 'center',
    },
    addMoreContainer: {
        display: 'flex',
        columnGap: '10px',
        paddingBottom: '15px',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    addMoreText: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Montserrat, sans-serif',
    },
    closeIcon: {
        width: '24px',
        height: '24px',
    },
};
