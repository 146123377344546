import React from "react";
import Slider from "react-slick";
import { Box, Container, Typography } from "@mui/material";
import vectorDotImage from "../../../../assets/Consumer/vector-dot-image.svg";
import userIcon from "../../../../assets/Consumer/userIcon.svg";

import "./testimonials.css";

interface Slide {
  id: number;
  content: string;
  date: string;
  name: string;
}

const slides: Slide[] = [
  {
    id: 1,
    content: `"I had an amazing experience with Home Seller Savings! Their top-rated Realtor 
              professionals guided me every step of the way, making the home-selling process stress-free. 
              They also connected me with fantastic service providers who handled everything from repairs 
              to staging, ensuring my home was in perfect condition for potential buyers. I couldn't be 
              happier with the results!"`,
    date: "Dico user, 2024.25.11",
    name: "~ Todd and Mary G",
  },
  {
    id: 2,
    content: `"Home Seller Savings was a game-changer for us when selling our home. Not only did we get 
              paired with an incredible Realtor who knew exactly how to market our property, but they 
              also provided access to reliable service providers for home repairs, staging, and even cleaning.
              Their network of trusted professionals made the entire process so convenient. We sold our home 
              faster than we imagined and for a great price!"`,
    date: "Dico user, 2024.26.11",
    name: "~ Maria and John C",
  },
  {
    id: 3,
    content: `"From start to finish, Home Seller Savings delivered exceptional service. Their experienced Realtor 
              professionals were highly knowledgeable and made selling our home a breeze. We were particularly impressed by 
              the range of service providers they offered, from financing to home repairs, all of whom were top-notch. With 
              their additional connections to attorneys and other professionals, we felt fully supported throughout the entire 
              process. Highly recommended!"`,
    date: "Dico user, 2024.25.11",
    name: "~ Zach and Bridget P",
  },
  {
    id: 4,
    content: `"Choosing Home Seller Savings was the best decision we made for selling our home. 
              They provided us with a fantastic Realtor who was not only professional but also deeply 
              understanding of our needs. What stood out was their extensive network of service providers 
              who helped make all necessary repairs prior to selling  our home. Plus, having access to 
              professionals like attorneys and lenders under one roof made the whole process so smooth 
              and hassle-free. Thank you, Home Seller Savings, for making this a great experience!"`,
    date: "Dico user, 2024.27.11",
    name: "Jose and Cecil R",
  },
];

const Testimonials = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: "url(testimonialBgColor.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingTop: {
          lg: "120px",
          xs: "40px",
        },
        paddingBottom: {
          lg: "80px",
          xs: "40px",
        },
      }}
    >
      <Container>
        <Box
          sx={{
            borderRadius: "24px",
            background: "#fff",
            padding: {
              xs: "20px",
              md: "40px",
            },
            zIndex: "9",
            position: "relative",
            boxShadow: "0px 4px 56px 0px #150F9933",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontSize: {
                lg: "32px",
                xs: "24px",
              },
              color: "#0D0639",
              fontWeight: "600",
              fontFamily: "Montserrat, sans- serif",
              margin: {
                lg: "0 0 50px",
                xs: "0 0 10px",
              },
              textAlign: "center",
            }}
          >
            Real Stories from Satisfied Home Sellers
          </Typography>

          <Slider className="testimonialsSlider" {...settings}>
            {slides.map((slide) => (
              <div key={slide.id}>
                <Box
                  sx={{
                    border: "1px solid #0000001A",
                    boxShadow: "0px 10px 20px 0px #0000001A",
                    borderRadius: "16px",
                    padding: "16px",
                    background: "#fff",
                    margin: "15px",
                    minHeight: {
                      xl: "260px",
                      lg: "280px",
                      sm: "340px",
                      xs: "360px",
                    },
                    flexDirection: "column",
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <Box>

                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontSize: {
                          lg: "24px",
                          xs: "20px",
                        },
                        color: "#000",
                        fontWeight: "600",
                        fontFamily: "Montserrat, sans- serif",
                        margin: "0 0 10px",
                      }}
                    >
                      {slide.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: "13px",
                        lineHeight: "20px",
                        color: "#0D063999",
                        fontWeight: "400",
                        fontFamily: "Montserrat, sans- serif",
                        margin: "0 0 20px",
                      }}
                    >
                      {slide.content}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={userIcon} alt="UserIcon" />
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: {
                          lg: "16px",
                          xs: "14px",
                        },
                        lineHeight: "20px",
                        color: "#000",
                        fontWeight: "500",
                        margin: {
                          lg: "0 0 0 5px",
                          xs: "0 0 0 10px",
                        },
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {slide.date}
                    </Typography>
                  </Box>
                </Box>
              </div>
            ))}
          </Slider>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "-5px",
            left: "0",
          }}
        >
          <img src={vectorDotImage} alt="Vector Image" />
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
