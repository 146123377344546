import "./SignIn.css";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomTextField from "../FormElements/CustomTextField";
import passwordEnabled from "../../assets/icons/passwordEnabled.svg";
import passwordDisabled from "../../assets/icons/passwordDisabled.svg";
import showPassword from "../../assets/icons/showPassword.svg";
import usernameEnabled from "../../assets/icons/usernameEnabled.svg";
import usernameDisabled from "../../assets/icons/usernameDisabled.svg";
import signinLogo from "../../assets/images/signinLogo.svg";
import BlueButton from "../Button/BlueButton";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid2,
  Typography,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login } from "../../network/user";
import { routePaths } from "../../Router/routePaths";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/commonSlice";
import styles from "./style.js";
import checkedIcon from "../../assets/icons/checkedIcon.png";
import checkIcon from "../../assets/icons/checkIcon.png";
import signInImage from "../../assets/common/singInImage.png";

interface Credential {
  username: string;
  password: string;
}
const SignIn = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [savedCredentials, setSavedCredentials] = useState<Credential[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [initialValues, setInitialValues] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load saved credentials from localStorage on component mount
  useEffect(() => {
    const savedUsers = JSON.parse(
      localStorage.getItem("savedCredentials") || "[]"
    ) as Credential[];
    setSavedCredentials(savedUsers);

    // Set the initial values based on saved credentials
    const lastCredential = savedUsers[savedUsers.length - 1] || {};
    setInitialValues({
      username: lastCredential.username || "",
      password: lastCredential.password || "",
    });
  }, []);

  const validationSchema = Yup.object({
    username: Yup.string()
      .max(50, "Maximum username length should be 50.")
      .required("Username is required."),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password must be at least 8 characters long.")
      .max(16, "Password must be at most 16 characters long.")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
      .matches(/\d/, "Password must contain at least one number.")
      .matches(
        /[!@#$%^&*()_/?.',"]/,
        "Password must contain at least one special character."
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setErrors }) => {
      try {
        dispatch(setLoading(true));
        const res = await login({
          userName: values.username,
          password: values.password,
          userType: 0,
        });
        localStorage.setItem(
          "isProfileCompletion",
          res.data.isProfileCompletion
        );
        if (rememberMe) {
          localStorage.setItem("accessToken", res.data.token);
          const newCredential = {
            username: values.username,
            password: values.password,
          };

          const existingIndex = savedCredentials.findIndex(
            (cred) => cred.username === newCredential.username
          );

          let updatedCredentials;
          if (existingIndex > -1) {
            updatedCredentials = savedCredentials.map((cred, index) =>
              index === existingIndex ? newCredential : cred
            );
          } else {
            updatedCredentials = [...savedCredentials, newCredential];
          }

          setSavedCredentials(updatedCredentials);
          localStorage.setItem(
            "savedCredentials",
            JSON.stringify(updatedCredentials)
          );
        } else {
          localStorage.setItem("accessToken", res.data.token);
        }
        handleNext(res?.data.isProfileCompletion);
      } catch (err: any) {
        const errMessage = err?.message?.toLowerCase();
        if (errMessage?.includes("username"))
          setErrors({ username: err?.message });
        else if (errMessage?.includes("password"))
          setErrors({ password: err?.message });
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const handleNext = (isProfileComplete: boolean) => {
    isProfileComplete
      ? navigate(routePaths.dashboard)
      : navigate(routePaths.personalDetails);
  };

  const handleRegisterNow = () => {
    navigate("/serviceproviders?register=true");
  };

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRememberMe(event.target.checked);
  };

  const handlePwdTypeChange = () => {
    const type = pwdType === "password" ? "text" : "password";
    setPwdType(type);
  };

  const handleFocus = () => {
    setShowSuggestions(true); // Show suggestions on focus
  };

  const handleSelectCredential = (credential: any) => {
    formik.setFieldValue("username", credential.username);
    formik.setFieldValue("password", credential.password);
    setShowSuggestions(false); // Hide suggestions after selecting
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Grid2 container className="signin_main_container">
      <Grid2
        size={{ xs: 12, md: 6, sm: 6 }}
        sx={styles.signInContainer}
        className="signin_sub_container1"
      >
        <Box sx={styles.signInSubContainer}>
          <Box>
            <Link to="/">
              <img src={signinLogo} alt="" />
            </Link>
          </Box>
          <Box sx={styles.singInLableHeading}>Sign In</Box>
          <Box sx={styles.singInLable}>
            Let’s sign in to your Home Service account
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ position: "relative" }} ref={dropdownRef}>
              <CustomTextField
                label="Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onFocus={handleFocus}
                placeholder="Enter your username"
                adornmentEnabled={usernameEnabled}
                adornmentDisabled={usernameDisabled}
                type={"text"}
                name="username"
                error={Boolean(
                  formik.errors.username && formik.touched.username
                )}
                errorMessage={formik.errors.username}
                autoComplete="off"
              />
              {showSuggestions && savedCredentials.length > 0 && (
                <Box sx={styles.credsDropdown}>
                  <List>
                    {savedCredentials.map((credential, index) => (
                      <ListItem
                        key={index}
                        onClick={() => handleSelectCredential(credential)}
                        sx={styles.savedCredsList}
                      >
                        <Typography sx={styles.f12}>
                          {" "}
                          {credential.username}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
            <Box className="passwordField">
              <CustomTextField
                label="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Enter your password"
                adornmentEnabled={
                  pwdType === "password" ? passwordEnabled : showPassword
                }
                adornmentDisabled={passwordDisabled}
                type={pwdType}
                error={Boolean(
                  formik.errors.password && formik.touched.password
                )}
                errorMessage={formik.errors.password}
                password={true}
                onPwdIconClick={handlePwdTypeChange}
                maxChar={16}
                autoComplete="off"
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  icon={
                    <Box
                      src={checkIcon}
                      sx={styles.rememberMeCheckBox}
                      alt="Unchecked"
                      component="img"
                    ></Box>
                  }
                  checkedIcon={
                    <Box
                      component="img"
                      src={checkedIcon}
                      sx={styles.rememberMeCheckBox}
                      alt="Checked"
                    ></Box>
                  }
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
              }
              label={
                <Box component="span" sx={styles.rememberMeLabel}>
                  Remember me
                </Box>
              }
            />
            <Box sx={styles.signinBtnContainer}>
              <BlueButton
                btnName="Sign In"
                type="submit"
                disabled={!formik.values.username || !formik.values.password}
              />
            </Box>
          </form>

          <Box sx={styles.registerLable}>
            Want to become a Service provider?
            <Box
              onClick={handleRegisterNow}
              sx={styles.registerNowBtn}
              component="span"
            >
              Register Now
            </Box>
          </Box>
        </Box>
      </Grid2>
      <Grid2
        size={{ xs: 12, md: 6, sm: 6 }}
        component="div"
        sx={styles.rightSide}
      >
        <Box sx={styles.singInImageWrapper}>
          <Box
            component="img"
            src={signInImage}
            alt="hero img"
            onLoad={() => setIsLoading(false)}
            sx={styles.singInImage}
          ></Box>
          {isLoading && (
            <Box>
              <CircularProgress
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={styles.flexColumnCenter}>
          <Typography component="div" sx={styles.typographyHeader}>
            Welcome to{" "}
            <Box component="span" sx={styles.primaryColor}>
              Home Seller Savings LLC
            </Box>
          </Typography>
          <Typography component="div" sx={styles.typographyBody}>
            Trusted professionals, wide range of services, convenient
            scheduling, and transparent pricing.
          </Typography>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default SignIn;
