import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import commonSlice from "./slices/commonSlice";

const rootReducer = combineReducers({
    user: userSlice,
    common:commonSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
