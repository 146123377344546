import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import { Box, Grid2, Typography } from "@mui/material";
import CustomTextField from "../../../components/FormElements/CustomTextField";
import { routePaths } from "../../../Router/routePaths";
import Search from "../../../assets/icons/Search.svg";
import { getServices } from "../../../network/services";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../redux/slices/userSlice";
import TextError from "../../../components/TextError/TextError";
import { setLoading } from "../../../redux/slices/commonSlice";
import { RootState } from "../../../redux/rootReducer";
import useDebounce from "../../../hooks/useDebounce";
interface Image {
  _id: string;
  name: string;
  displayImage: {
    url: string;
  };
}
const EditBusiness = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector((state: RootState) => state.common.loader);
  const userData = useSelector((state: RootState) => state.user.userData);
  console.log("line 31", userData);
  const updatedSelectedItems = useSelector(
    (state: RootState) => state.user.userData?.serviceIds
  );

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const queryParams = new URLSearchParams(window.location.search);
  const previous = queryParams.get("previous");

  useEffect(() => {
    console.log("line 41", updatedSelectedItems);
    setSelectedItems(updatedSelectedItems || []);
  }, [updatedSelectedItems]);

  useEffect(() => {
    fetchServicesList();
  }, [debouncedSearchText]);

  const fetchServicesList = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getServices(searchText);
      setImages(res?.data);
    } catch (err) {
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const selectServiceHandler = (image: Image) => {
    if (selectedItems.includes(image._id)) {
      setSelectedItems(selectedItems.filter((item) => item !== image._id));
    } else {
      setSelectedItems([...selectedItems, image._id]);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const addBusinessHandler = async () => {
    try {
      const payload = { serviceIds: selectedItems };
      dispatch(setUserData(payload));
      navigate(`${routePaths.editServiceCategory}?previous=true`);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Box className="addBusinessForm">
      <ProfileWrapper
        title="Edit Business"
        btnDisabled={selectedItems?.length === 0}
        onSubmit={addBusinessHandler}
        hide={true}
        buttonText="Next"
        backHandler={() => {
          dispatch(setUserData({ backClicked: true }));
          navigate(routePaths.settings);
        }}
      >
        <Box
          sx={{
            height: "auto",
            width: { lg: "50%", sm: "80%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            paddingTop: {
              sm: "0",
              xs: "10px",
            },
          }}
        >
          <form>
            <Box>
              <CustomTextField
                value={searchText}
                onChange={handleSearch}
                placeholder="Search services"
                type={"text"}
                name="fullName"
                endAdornment={Search}
                profile={true}
                allowOnlyAlphabets={true}
                maxChar={32}
              />
            </Box>
          </form>

          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              margin: {
                xl: "15px 0 0 0",
                xs: "13px auto 0",
              },
              maxHeight: "70vh",
              width: "100%",
              marginBottom: "10px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              "-ms-overflow-style": "none",
            }}
          >
            <Grid2 container spacing={2}>
              {images.map((image) => (
                <Grid2 size={{ xs: 12, sm: 4 }} key={image._id}>
                  <Box
                    sx={{
                      border: `${selectedItems.includes(image?._id) ? "2px solid #1CB469" : "1px solid #06038D4D"}`,
                      boxShadow: `${selectedItems.includes(image?._id) ? "0px 4px 36px 0px #00000029" : ""}`,
                      padding: "8px",
                      borderRadius: "20px",
                      fontWeight: `${selectedItems.includes(image?._id) ? "600" : ""}`,
                      textAlign: "center",
                      cursor: "pointer",
                      height: "90%",
                    }}
                    onClick={() => selectServiceHandler(image)}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "100%",
                        height: {
                          xs: "150px",
                          sm: "100px",
                        },
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                      src={image.displayImage.url}
                      alt={image?._id}
                    ></Box>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: "14px",
                          xs: "12px",
                        },
                        wordBreak: "auto-phrase",
                        fontFamily: "Montserrat, sans-serif",
                        color: `${selectedItems.includes(image?._id) ? "#06038D" : "#0D0639"}`,
                        fontWeight: 600,
                        marginTop: "3px",
                        height: {
                          sm: "90%",
                          xs: "auto",
                        },
                      }}
                    >
                      {image?.name}
                    </Typography>
                  </Box>
                </Grid2>
              ))}
              {images?.length === 0 && !loader && searchText && (
                <TextError msg="No items matches your search." />
              )}
            </Grid2>
          </Box>
        </Box>
      </ProfileWrapper>
    </Box>
  );
};

export default EditBusiness;
