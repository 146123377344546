import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  background: {
    background: "#F5F5FA",
    minHeight: "100vh",
  },
  contactImage: {
    width: "100%",
    height: {
      sm: "400px",
      xs: "200px"
    },
    objectFit: "cover",
    marginTop: "-40px",
    borderRadius: "20px",
  },
  translateContainer: {
    transform: {
      sm: "translateY(-180px)",
      xs: "unset"
    },
  },
  headerText: {
    fontSize: {
      lg: "48px",
      xs: "26px",
    },
    color: "#06038D",
    fontWeight: "500",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingBottom: {
      sm: "20px",
      xs: "15px"
    },
    paddingTop: {
      sm: "0",
      xs: "15px"
    }
  },
  formContainer: {
    boxShadow: "0px 69px 42px 0px #C8C8C840",
    borderRadius: "20px",
    position: "relative",
    padding: {
      lg: "40px 0",
      xs: "65px 16px 26px",
      sm: "65px 16px 26px",
    },
    marginBottom: {
      xs: "30px",
      sm: "0"
    },
    display: "flex",
    background: "#fff",
    minHeight: {
      lg: "70vh",
      xs: "auto"
    },
  },
  backButton: {
    cursor: "pointer",
    position: "absolute",
    top: {
      lg: "30px",
      xs: "20px",
    },
    left: {
      lg: "20px",
      xs: "20px",
    },
    "& img": {
      width: {
        sm: "auto",
        xs: "30px"
      }
    }
  },
  submitButton: {
    background: "#06038D",
    padding: "13px 60px",
    borderRadius: "12px",
    fontSize: { lg: "18px", xs: "16px" },
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    color: "#fff",
    fontWeight: "600",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    "&:disabled": {
      background: "#B3B3CF",
      color: "#fff",
      boxShadow: "none",
    },
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px"
  }
};

export default styles;