import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import aboutUsBannerImage from "../../../assets/images/aboutUsBannerImage.jpg";
import quickBuyVectorImage from "../../../assets/quickBuy/quickBuyVectorImage.png";
import { useState } from "react";

const AboutUsBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box
        sx={{
          background: "#F6F6FD",
          boxShadow: "4px 8px 24px 0px #3F8CFF40",
          padding: {
            md: "0 0 20px 30px",
            xs: "0",
          },
          position: "relative",
        }}
      >
        <Grid
          sx={{
            "@media (min-width: 1536px)": {
              maxWidth: "1536px",
              margin: "auto",
            },
            flexDirection: {
              md: "row",
              xs: "column-reverse",
            },
          }}
          container
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={4}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                padding: {
                  xl: "0 20px 0 0",
                  lg: "50px 30px 50px 20px",
                  md: "0 20px 0 0",
                  xs: "16px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: {
                    xl: "0",
                    md: "20px",
                    xs: "20px",
                  },
                  padding: {
                    lg: "20px 0 30px 0",
                    md: " 20px 0 20px 0",
                    xs: "10px 0 20px 0",
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: {
                      lg: "18px",
                      xs: "16px",
                    },
                    color: "#0D0639",
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "700",
                  }}
                >
                  About Us
                </Typography>
                <Box
                  sx={{
                    width: "60px",
                    marginLeft: "20px",
                    height: "3px",
                    background: "#1CB469",
                  }}
                ></Box>
              </Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    lg: "46px",
                    xs: "32px",
                  },
                  color: "#0F172A",
                  fontFamily: "Montserrat, sans- serif",
                  fontWeight: "700",
                  lineHeight: {
                    lg: "60px",
                    xs: "44px",
                  },
                }}
              >
                Welcome to{" "}
                <span
                  style={{
                    color: "#06038D",
                  }}
                >
                  Home Seller Savings
                </span>
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    lg: "24px",
                    xs: "18px",
                  },
                  paddingTop: "20px",
                  color: "#0D0639",
                  fontFamily: "Montserrat, sans- serif",
                  fontWeight: "400",
                  lineHeight: {
                    lg: "44px",
                    xs: "28px",
                  },
                }}
              >
                your premier destination for a hassle-free and flexible home
                selling experience. We understand that every homeowner's
                situation is unique, and that's why we offer a variety of
                options to suit your specific needs
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={7}
            xl={8}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                zIndex: "9",
                height: "100%",
                marginTop: "-30px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "24px 0 0 24px",
                  width: "100%",
                  objectFit: "cover",
                  height: "100%",
                }}
                component="img"
                alt="Quick Banner Image"
                onLoad={() => setIsLoading(false)}
                src={aboutUsBannerImage}
              ></Box>
              {isLoading && (
                <CircularProgress
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </Box>
            <Box
              component="img"
              src={quickBuyVectorImage}
              sx={{
                position: "absolute",
                top: "-40px",
                left: "-120px",
              }}
            ></Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AboutUsBanner;
