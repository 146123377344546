import { SxProps } from '@mui/material';

export const calendarStyles: Record<string, SxProps> = {
    container: {
        textAlign: 'center',
        padding: '16px 16px 0 16px',
        background: '#F2F7FD',
    },
    navigation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
    },
    monthYear: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: {
            xl: '18px',
            xs: "16px"
        },
        fontWeight: 600,
        width: '160px',
    },
    daysContainer: {
        display: 'flex',
        scrollbarWidth: 'none',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        gap: 2,
        width: '100%',
    },
    day: {
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    dayText: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
    },
    dayNumber: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 500,
        fontSize: {
            xl: "16px",
            xs: "14px"
        },
        lineHeight: '20px',
    },
    blueDot: {
        width: '5px',
        height: '5px',
        borderRadius: {
            xs: '50%',
            lg: '70%'
        },
        backgroundColor: 'rgba(6, 3, 141, 1)',
    }
};
