import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box } from "@mui/material";
import CustomTextField from "../../../components/FormElements/CustomTextField";
import { routePaths } from "../../../Router/routePaths";
import { useDispatch } from "react-redux";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/commonSlice";

const AddBusinessDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    businessTitle: "",
    description: "",
  };
  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getUserProfile();
      dispatch(setUserData(res.data));
      formik.setValues({
        businessTitle: res.data.businessTitle || "",
        description: res.data.description || "",
      });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const validationSchema = Yup.object({
    businessTitle: Yup.string()
      .required("Title must be atleast 3 to 40 characters long")
      .min(3, "Title must be atleast 3 to 40 characters long")
      .max(40, "Title must be atleast 3 to 40 characters long"),
    description: Yup.string()
      .required("Description must be atleast 3 to 1000 characters long")
      .min(3, "Description must be atleast 3 to 1000 characters long")
      .max(1000, "Description must be atleast 3 to 1000 characters long"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        await updateUserProfile(values);
        dispatch(setUserData(values));
        navigate(routePaths.addBusinessImages);
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(setLoading(false));
      }
    },
  });
  return (
    <ProfileWrapper
      title="Add Business Details"
      btnDisabled={
        !formik.values.businessTitle ||
        !formik.values.description ||
        !formik.isValid
      }
      onSubmit={formik.handleSubmit}
      stepperCount={5}
    >
      <Box sx={{ height: "auto", width: { xs: "100%", md: "80%", lg: "60%" } }}>
        <form className="addBusinessDetailsForm">
          <CustomTextField
            label="Add Title"
            value={formik.values.businessTitle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter your title"
            type={"text"}
            maxChar={40}
            name="businessTitle"
            error={Boolean(
              formik.errors.businessTitle && formik.touched.businessTitle
            )}
            errorMessage={
              typeof formik.errors.businessTitle === "string"
                ? formik.errors.businessTitle
                : undefined
            }
          />
          <CustomTextField
            label="Add Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Add description here"
            type={"text"}
            name="description"
            maxChar={1000}
            error={Boolean(
              formik.errors.description && formik.touched.description
            )}
            errorMessage={formik.errors.description}
            multiline={true}
          />
        </form>
      </Box>
    </ProfileWrapper>
  );
};

export default AddBusinessDetails;
