import { useEffect } from "react";
import Footer from "../LandingPage/Consumer/Footer/footer";
import Header from "../LandingPage/Consumer/Header/header";
import QuickBuyBanner from "./QuickBuy";
import QuickBuyContent from "./QuickBuyContent/QuickBuyContent";

const QuickBuy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <QuickBuyBanner />
      <QuickBuyContent />
      <Footer />
    </>
  );
};

export default QuickBuy;
