import { useState } from "react";
import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import { routePaths } from "../../../../Router/routePaths";
import { getStyles } from "../../Consumer/Header/style";
import MenuCloseIcon from "../../../../assets/icons/menuCloseIcon.svg";
import headerLogo from "../../../../assets/common/header_logo.png";
import headerLogoHomeSeller from "../../../../assets/common/header_logo_home_seller.png";
import heroImage from "../../../../assets/ServiceProvider/hero-image.png";
import menuIcon from "../../../../assets/common/menu_icon.svg";

interface HeaderProps {
  scrollToForm: () => void;
}

export const Header: React.FC<HeaderProps> = ({ scrollToForm }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const styles = getStyles(menuVisible);

  return (
    <Box sx={styles.bannerBgColor}>
      <Box sx={styles.box}>
        <Container maxWidth="xl">
          <Box sx={styles.toggleMenu}>
            <Box>
              <NavLink to="/">
                <img src={headerLogo} alt="Header Logo" width="70%" />
              </NavLink>
            </Box>
            <Box onClick={toggleMenu} sx={styles.toggleMenuIcon}>
              <img src={menuIcon} alt="Menu Icon" />
            </Box>
          </Box>

          <List className="headerMenu" sx={styles.menuList}>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.logoListItemText}>
                <NavLink to="/">
                  <Link href="#">
                    <Box
                      component="img"
                      sx={styles.headerLogo}
                      src={headerLogoHomeSeller}
                      alt="Header Logo"
                    ></Box>
                  </Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to="/expertServiceProvider"
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>Service Providers</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to="/aboutUs"
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>About Us</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to="/contact"
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>Contact Us</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.primaryButton}>
              <ListItemText sx={styles.primaryButtonLinkText}>
                <NavLink to="/serviceproviders">
                  <Link href="#" sx={styles.primaryButtonText}>
                    Become a Service Provider
                  </Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.menucloseIcon}>
              <Box
                onClick={toggleMenu}
                component="img"
                src={MenuCloseIcon}
                alt="Menu Close Icon"
              ></Box>
            </ListItem>
          </List>
        </Container>
      </Box>

      <Box sx={styles.bannerWrapper}>
        <Grid sx={styles.bannerContainer} container>
          <Grid item xs={12} sm={12} lg={5}>
            <Box>
              <Typography variant="h3" sx={styles.bannerHeading}>
                <span style={styles.bannerHighlightText}>Join Us &</span> Become
                a Part of a winning Team of Professionals
              </Typography>
              <Typography variant="h3" sx={styles.bannerText}>
                Deliver your expertise to a wider audience and grow your
                business by joining our trusted network of professionals.
              </Typography>
              <Box>
                <NavLink to={routePaths.signIn}>
                  <Button sx={styles.signInButton}>Sign In</Button>
                </NavLink>
                <Button sx={styles.registerButton} onClick={scrollToForm}>
                  Register Now
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={7} sx={{position:"relative"}}>
            <img
              src={heroImage}
              width="100%"
              alt="bannerImage"
              onLoad={() => setIsLoading(false)}
            />
            {isLoading && (
              <CircularProgress
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  zIndex:"999"
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
