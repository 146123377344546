import Header from "./Header/header";
import Banner from "./Banner/banner";
import Services from "./Services/services";
import Testimonials from "./Testimonials/testimonials";
import Faq from "./FAQ/faq";
import Footer from "./Footer/footer";
import { useEffect } from "react";

const Consumer = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <Banner />
            <Services />
            <Testimonials />
            <Faq />
            <Footer />
        </>
    )
}

export default Consumer;