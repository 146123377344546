import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import leftArrow from "../../assets/icons/leftArrow.svg";
import rightArrow from "../../assets/icons/rightArrow.svg";
import { getDaysInMonth, scrollDays } from '../../utils/calendar';
import { calendarStyles } from './style'; // Import styles

const CustomCalendar: React.FC<{ onDateSelect: (date: Dayjs | null) => void, serviceTiming?: any }> = ({ onDateSelect, serviceTiming }) => {
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs());
    const daysContainerRef = useRef<HTMLDivElement | null>(null);
    const currentDayRef = useRef<HTMLDivElement | null>(null);

    const today = dayjs();

    const handlePrevMonth = () => {
        setCurrentMonth(currentMonth.subtract(1, 'month'));
    };

    const handleNextMonth = () => {
        setCurrentMonth(currentMonth.add(1, 'month'));
    };

    useEffect(() => {
        if (daysContainerRef.current) {
            if (currentMonth.isSame(today, 'month')) {
                // Scroll to today's date if we're on the current month
                if (currentDayRef.current) {
                    currentDayRef.current.scrollIntoView({
                        inline: 'center',
                        behavior: 'smooth',
                    });
                }
            } else {
                // Scroll to the first day of the month if it's a different month
                const firstDayRef = daysContainerRef.current.querySelector(`[data-day="01"]`);
                if (firstDayRef) {
                    firstDayRef.scrollIntoView({
                        inline: 'center',
                        behavior: 'smooth',
                    });
                }
            }
        }
    }, [currentMonth]);


    const daysInMonth = getDaysInMonth(currentMonth);

    const hasSlotForDate = (day: Dayjs) => {
        const formattedDay = day.format('YYYY-MM-DD');
        return Object.values(serviceTiming || {}).some((dayInfo: any) =>
            dayInfo.slots?.some((slot: any) => slot.monthDate === formattedDay)
        );
    };

    const handleDateSelection = (day: Dayjs) => {
        if (selectedDate && day.isSame(selectedDate, 'day')) {
            setSelectedDate(null);
            onDateSelect(null);
        } else {
            setSelectedDate(day);
            onDateSelect(day);
        }
    };

    return (
        <Box sx={calendarStyles.container}>
            <Box sx={calendarStyles.navigation}>
                <img src={leftArrow} onClick={handlePrevMonth} style={{ cursor: 'pointer' }} />
                <Typography sx={calendarStyles.monthYear}>
                    {currentMonth.format('MMMM YYYY')}
                </Typography>
                <img src={rightArrow} onClick={handleNextMonth} style={{ cursor: 'pointer' }} />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingTop={2}>
                <img src={leftArrow} onClick={() => scrollDays('left', daysContainerRef, daysInMonth)} style={{ cursor: 'pointer' }} />
                <Box ref={daysContainerRef} sx={calendarStyles.daysContainer}>
                    {daysInMonth.map((day) => {
                        const isBeforeToday = day.isBefore(today, 'day');
                        const hasSlot = hasSlotForDate(day);

                        return (
                            <Box
                                key={day.format('DD-MM-YYYY')}
                                ref={day.isSame(today, 'day') ? currentDayRef : null}
                                onClick={!isBeforeToday ? () => handleDateSelection(day) : undefined}
                                data-day={day.format('DD')}
                                sx={{
                                    ...calendarStyles.day,
                                    cursor: !isBeforeToday ? 'pointer' : 'not-allowed',
                                    color: selectedDate?.isSame(day, 'day') ? 'white' : isBeforeToday ? '#999' : '',
                                    backgroundColor: selectedDate?.isSame(day, 'day') ? 'rgba(6, 3, 141, 1)' : hasSlot ? 'lightGrey' : '',
                                }}
                            >
                                <Typography sx={{
                                    ...calendarStyles.dayText, fontSize: {
                                        xl: "12px",
                                        xs: "8px"
                                    },
                                    color: selectedDate?.isSame(day, 'day') ? 'white' : 'rgba(0, 0, 0, 0.5)'
                                }}>
                                    {day.format('ddd').toUpperCase()}
                                </Typography>
                                <Typography sx={calendarStyles.dayNumber}>
                                    {day.format('DD')}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
                <img src={rightArrow} onClick={() => scrollDays('right', daysContainerRef, daysInMonth)} style={{ cursor: 'pointer' }} />
            </Box>
        </Box>
    );
};

export default CustomCalendar;
