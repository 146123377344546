import {
  Typography,
  Box,
  InputLabel,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import backIcon from "../../../assets/icons/backIcon.svg";
import styles from "./style";
import "./EditAddress.css";
import { updateUserProfile } from "../../../network/user";
import { DialogTitle, DialogContent, Dialog, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../Router/routePaths";
import { formatInput, validateField } from "../../../utils/validationUtils";
import successPopupImage from "../../../assets/common/successPopupImage.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";

const getSx = (val: string | null) => {
  return {
    borderColor: !!val ? "rgba(255, 0, 0, 1)" : undefined,
    "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
  };
};

const EditAddress = () => {
  const [userAddressDetails, setUserAddressDetails] = useState<any>({
    address: "",
    city: "",
    province: "",
    zipCode: "",
    errors: {},
  });
  const [updatedFields, setUpdatedFields] = useState<any>({});
  const [updateDetailsModal, setUpdateDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state: RootState) => state.user.userData);

  const navigate = useNavigate();

  const navigateBackHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (userData) {
      setUserAddressDetails({
        address: userData?.address,
        city: userData?.city,
        province: userData?.province,
        zipCode: userData?.zipCode,
        errors: { address: "", city: "", province: "", zipCode: "" },
      });
    }
  }, [userData]);

  const handleInputChange = (e: any) => {
    let { name, value } = e.target;
    if (name === "zipCode" || name === "city" || name === "province") {
      value = formatInput(name, value);
    }
    if (name === "address") {
      value = formatInput(name, value, 100);
    }
    const errorMessage = validateField(name, value);
    setUserAddressDetails({
      ...userAddressDetails,
      [name]: value,
      errors: { ...userAddressDetails.errors, [name]: errorMessage },
    });
    setUpdatedFields({
      ...updatedFields,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await updateUserProfile(updatedFields);
    if (response) {
      setIsLoading(false);
      setUpdateDetailsModal(true);
    }
  };
  const handleClickUpdateDetailsModalClose = () => {
    navigate(routePaths.settings);
  };

  return (
    <Box sx={styles.editAddressPage}>
      <Box sx={styles.editAddressWrapper}>
        <Box
          src={backIcon}
          component="img"
          alt="backIcon"
          sx={styles.backIcon}
          onClick={navigateBackHandler}
        ></Box>
        <Typography sx={styles.editAddressTitle}>Edit Address</Typography>
        <Box
          sx={{
            width: {
              xl: "60%",
              margin: "auto",
            },
            paddingTop: {
              sm: "0",
              xs: "15px",
            },
          }}
        >
          <form onSubmit={handleFormSubmit}>
            <Box className="formControl">
              <InputLabel className="inputLable">Address</InputLabel>
              <TextField
                name="address"
                value={userAddressDetails?.address}
                onChange={handleInputChange}
                placeholder="Add Address"
                error={!!userAddressDetails.errors.address}
                helperText={userAddressDetails.errors.address}
                sx={getSx(userAddressDetails.errors.address)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">City</InputLabel>
              <TextField
                name="city"
                value={userAddressDetails?.city}
                onChange={handleInputChange}
                placeholder="Enter City"
                error={!!userAddressDetails.errors.city}
                helperText={userAddressDetails.errors.city}
                sx={getSx(userAddressDetails.errors.city)}
              />
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Box
                    className="formControl"
                    sx={{
                      marginRight: {
                        lg: "5px",
                        xs: "0",
                      },
                    }}
                  >
                    <InputLabel className="inputLable">Province</InputLabel>
                    <TextField
                      name="province"
                      value={userAddressDetails?.province}
                      onChange={handleInputChange}
                      placeholder="Enter Province"
                      error={!!userAddressDetails.errors.province}
                      helperText={userAddressDetails.errors.province}
                      sx={getSx(userAddressDetails.errors.province)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box
                    className="formControl"
                    sx={{
                      marginLeft: {
                        lg: "5px",
                        xs: "0",
                      },
                    }}
                  >
                    <InputLabel className="inputLable">Zip Code</InputLabel>
                    <TextField
                      name="zipCode"
                      value={userAddressDetails?.zipCode}
                      type="text"
                      onChange={handleInputChange}
                      placeholder="Add Zip Code"
                      error={!!userAddressDetails.errors.zipCode}
                      helperText={userAddressDetails.errors.zipCode}
                      sx={getSx(userAddressDetails.errors.zipCode)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {userAddressDetails.error && (
              <p className="errorText">{userAddressDetails.error}</p>
            )}
            <Box sx={styles.buttonContainer} className="button">
              {isLoading ? (
                <Button sx={styles.submitButton} type="submit" disabled={true}>
                  <CircularProgress size={40} />
                </Button>
              ) : (
                <button
                  className="activeBtn"
                  disabled={
                    userAddressDetails.errors.address ||
                    userAddressDetails.errors.city ||
                    userAddressDetails.errors.province ||
                    userAddressDetails.errors.zipCode ||
                    userAddressDetails.address === "" ||
                    userAddressDetails.city === "" ||
                    userAddressDetails.province === "" ||
                    userAddressDetails.zipCode === ""
                  }
                  onClick={handleFormSubmit}
                >
                  Update Address
                </button>
              )}
            </Box>
          </form>
          <Dialog
            onClose={handleClickUpdateDetailsModalClose}
            aria-labelledby="customized-dialog-title"
            open={updateDetailsModal}
            sx={{
              "& .MuiDialog-paper": {
                width: {
                  xs: "90%",
                  sm: "70%",
                  md: "400px",
                },
                borderRadius: "40px",
                maxWidth: "90%",
              },
            }}
          >
            <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
              <img src={successPopupImage} alt="Sucess Image" />
              <Box>Your Address details has been updated successfully!</Box>
            </DialogTitle>
            <DialogContent sx={styles.dialogContent}>
              <Box sx={styles.contentBox}>
                <Box sx={styles.buttonContainer}>
                  <Button
                    sx={styles.doneButton}
                    onClick={handleClickUpdateDetailsModalClose}
                  >
                    Done
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};

export default EditAddress;
