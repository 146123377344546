const headerStyle = {
    dialogTitle: {
        m: 0,
        fontFamily: "Montserrat, sans- serif",
        textAlign: "center",
        fontSize: "22px",
        fontWeight: "700",
    },
    yesButton: {
        background: "#B3B3CF",
        padding: "13px 80px",
        borderRadius: "40px",
        fontSize: {
            lg: "18px",
            xs: "16px",
        },
        color: "#fff",
        fontWeight: "500",
        textDecoration: "none",
        fontFamily: "Montserrat, sans-serif",
        textTransform: "capitalize",
        margin: {
            sm: "0 20px 0 0",
            xs: "0 0 20px 0",
        },
        width: {
            sm: "max-content",
            xs: "100%",
        },
    },
    noButton: {
        background: "#06038D",
        padding: "13px 80px",
        borderRadius: "40px",
        fontSize: {
            lg: "18px",
            xs: "16px",
        },
        color: "#fff",
        fontWeight: "500",
        textDecoration: "none",
        fontFamily: "Montserrat, sans-serif",
        textTransform: "capitalize",
        width: {
            sm: "max-content",
            xs: "100%",
        },
    },
    buttonWrapper: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: {
            sm: "row",
            xs: "column",
        },
    },
    dialogContentWrapper: {
        padding: {
            lg: "30px 90px 20px",
            xs: "0 0 20px 0",
        },
    },
    dialogBox: {
        padding: "0 20px",
    }
};

export default headerStyle;

