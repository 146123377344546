import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Toaster {
    color: 'error' | 'warning' | 'info' | 'success' | null;
    msg: string | null;
}

interface CommonState {
    toaster: Toaster | null;
    loader: boolean;
}

const initialState: CommonState = {
    toaster: null,
    loader: false,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        showToast: (state, action: PayloadAction<Toaster | null>) => {
            state.toaster = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        },
    },
});

// Exporting actions and reducer
export const { showToast, setLoading } = commonSlice.actions;
export default commonSlice.reducer;
