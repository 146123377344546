import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import { Box, Grid2, Typography } from "@mui/material";
import CustomTextField from "../../../components/FormElements/CustomTextField";
import { routePaths } from "../../../Router/routePaths";
import Search from "../../../assets/icons/Search.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { getSubCategories } from "../../../network/services";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/commonSlice";
import TextError from "../../../components/TextError/TextError";
import useDebounce from "../../../hooks/useDebounce";
import styles from "./style";
interface subCategory {
  categoryName: string;
  subcategories: any;
}

interface SubCategory {
  _id: string;
  subCategoryName: string;
  displayImage?: DisplayImage;
}

interface DisplayImage {
  url: string;
  key: string;
}

interface Category {
  _id: string;
  categoryName: string;
  displayImage?: DisplayImage;
  subcategories: SubCategory[];
}

interface ResponseData {
  data: Category[];
}
const SelectServiceSubCategory = () => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [staticSelected, setStaticSelected] = useState<string[]>([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [subCategories, setSubCategories] = useState<subCategory[]>([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const loader = useSelector((state: RootState) => state.common.loader);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getUserProfile();
      dispatch(setUserData(res.data));
      setCategoryIds(res?.data?.categoryIds);
      setSelectedItems(res?.data?.subCategoryIds || []);
      setStaticSelected(res?.data?.subCategoryIds || []);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchSubCategories(debouncedSearchText);
  }, [debouncedSearchText, categoryIds]);

  const fetchSubCategories = async (textArg: string) => {
    try {
      const payload = {
        categoryIds: categoryIds || [],
        keyword: searchText,
      };
      const res = await getSubCategories(payload);
      const subCategoryIds = deriveCategoryIds(
        res,
        categoryIds,
        staticSelected
      );
      setSelectedItems(subCategoryIds);
      !textArg && setStaticSelected(subCategoryIds);
      setSubCategories(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const deriveCategoryIds = (
    res: ResponseData | undefined,
    categoryIds: string[],
    selectedItems: string[]
  ): string[] => {
    const catIds: string[] = [];
    if (res?.data && Array.isArray(res.data)) {
      res.data.forEach((category) => {
        if (categoryIds.includes(category?._id)) {
          category?.subcategories?.forEach((subcategory: any) => {
            if (selectedItems.includes(subcategory._id)) {
              catIds.push(subcategory._id);
            }
          });
        }
      });
    }
    return catIds;
  };

  const handleSelectServiceCategories = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
      setStaticSelected(staticSelected.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
      setStaticSelected([...staticSelected, id]);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const selectCategoryHandler = async () => {
    try {
      const payload = { subCategoryIds: staticSelected };
      await updateUserProfile(payload);
      dispatch(setUserData(payload));
      navigate(routePaths.addBusinessDetails);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box className="selectSubServiceCategoryForm">
      <ProfileWrapper
        title="Select Service Sub Category"
        btnDisabled={staticSelected?.length === 0}
        onSubmit={selectCategoryHandler}
        stepperCount={4}
      >
        <Box sx={styles.innerContainer}>
          <form>
            <Box>
              <CustomTextField
                value={searchText}
                onChange={handleSearch}
                placeholder="Search sub categories"
                type={"text"}
                name="fullName"
                endAdornment={Search}
                profile={true}
                allowOnlyAlphabets={true}
                maxChar={32}
              />
            </Box>
          </form>

          <Box sx={styles.categoriesContainer}>
            {subCategories?.length === 0 && !loader && searchText && (
              <TextError msg="No items matches your search." />
            )}
            {subCategories.map((item, index) => (
              <>
                {item?.subcategories?.length > 0 && (
                  <Typography
                    sx={{
                      ...styles.categoryName,
                      mt: index === 0 ? 0 : 2,
                    }}
                  >
                    {item?.categoryName}
                  </Typography>
                )}
                <Grid2 container spacing={2}>
                  {item?.subcategories?.map((subCat: any) => {
                    return (
                      <Grid2 key={subCat._id}>
                        <Box
                          sx={{
                            ...styles.subcategoriesGrid,
                            border: `${selectedItems.includes(subCat._id) ? "1px solid #1CB469" : "1px solid #8989A3"}`,
                            background: `${selectedItems.includes(subCat._id) ? "#1CB469" : ""}`,
                          }}
                          onClick={() =>
                            handleSelectServiceCategories(subCat._id)
                          }
                        >
                          <Typography
                            sx={{
                              ...styles.subCatName,
                              color: `${selectedItems.includes(subCat._id) ? "white" : "#8989A3"}`,
                            }}
                          >
                            {subCat.subCategoryName}
                          </Typography>
                        </Box>
                      </Grid2>
                    );
                  })}
                </Grid2>
              </>
            ))}
          </Box>
        </Box>
      </ProfileWrapper>
    </Box>
  );
};

export default SelectServiceSubCategory;
