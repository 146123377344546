import React, { useEffect } from 'react';
import stepper1Active from "../../../assets/icons/stepper/stepper1Active.svg";
import stepper2Active from "../../../assets/icons/stepper/stepper2Active.svg";
import stepper3Active from "../../../assets/icons/stepper/stepper3Active.svg";
import stepper4Active from "../../../assets/icons/stepper/stepper4Active.svg";
import stepper5Active from "../../../assets/icons/stepper/stepper5Active.svg";
import stepper6Active from "../../../assets/icons/stepper/stepper6Active.svg";
import stepper7Active from "../../../assets/icons/stepper/stepper7Active.svg";
import stepper8Active from "../../../assets/icons/stepper/stepper8Active.svg";
import stepper1Done from "../../../assets/icons/stepper/stepper1Done.svg";
import stepper2Done from "../../../assets/icons/stepper/stepper2Done.svg";
import stepper3Done from "../../../assets/icons/stepper/stepper3Done.svg";
import stepper4Done from "../../../assets/icons/stepper/stepper4Done.svg";
import stepper5Done from "../../../assets/icons/stepper/stepper5Done.svg";
import stepper6Done from "../../../assets/icons/stepper/stepper6Done.svg";
import stepper7Done from "../../../assets/icons/stepper/stepper7Done.svg";
import stepper2Inactive from "../../../assets/icons/stepper/stepper2Inactive.svg";
import stepper3Inactive from "../../../assets/icons/stepper/stepper3Inactive.svg";
import stepper4Inactive from "../../../assets/icons/stepper/stepper4Inactive.svg";
import stepper5Inactive from "../../../assets/icons/stepper/stepper5Inactive.svg";
import stepper6Inactive from "../../../assets/icons/stepper/stepper6Inactive.svg";
import stepperInactive from "../../../assets/icons/stepper/stepper7Inactive.svg";
import stepper8Inactive from "../../../assets/icons/stepper/stepper8Inactive.svg";

// Define the props interface
interface CustomStepIconProps {
    icon: number; // Step numbers
    status: 'active' | 'inactive' | 'done'; // Status of the step
}

// Define the icon structure for each step
interface IconMap {
    [key: number]: {
        active?: string;
        inactive?: string;
        done?: string;
    };
}

// Icon map with correct paths for different states
const iconMap: IconMap = {
    1: { active: stepper1Active, done: stepper1Done },
    2: { active: stepper2Active, inactive: stepper2Inactive, done: stepper2Done },
    3: { active: stepper3Active, inactive: stepper3Inactive, done: stepper3Done },
    4: { active: stepper4Active, inactive: stepper4Inactive, done: stepper4Done },
    5: { active: stepper5Active, inactive: stepper5Inactive, done: stepper5Done },
    6: { active: stepper6Active, inactive: stepper6Inactive, done: stepper6Done },
    7: { active: stepper7Active, inactive: stepperInactive, done: stepper7Done },
    8: { active: stepper8Active, inactive: stepper8Inactive, done: stepperInactive },
};

// Function to preload images
const preloadImages = (images: string[]) => {
    images.forEach((src) => {
        const img = new Image();
        img.src = src;
    });
};

const CustomStepIcon: React.FC<CustomStepIconProps> = ({ icon, status }) => {
    // Preload all icons when the component mounts
    useEffect(() => {
        const allIcons = [
            stepper1Active,
            stepper2Active,
            stepper3Active,
            stepper4Active,
            stepper5Active,
            stepper6Active,
            stepper7Active,
            stepper8Active,
            stepper1Done,
            stepper2Done,
            stepper3Done,
            stepper4Done,
            stepper5Done,
            stepper6Done,
            stepper7Done,
            stepper2Inactive,
            stepper3Inactive,
            stepper4Inactive,
            stepper5Inactive,
            stepper6Inactive,
            stepperInactive,
            stepper8Inactive,
        ];
        
        preloadImages(allIcons);
    }, []);

    // Use the inactive icon as a placeholder if the icon is being changed
    const placeholderSrc = iconMap[icon].inactive;
    const iconSrc = iconMap[icon][status] || placeholderSrc; // Use the inactive icon if the status icon is not available

    if (!iconSrc) {
        console.error(`Icon not found for Step ${icon} with status ${status}`);
        return null; // Handle missing icon case
    }

    // Set icon size based on status
    const iconSize = (status === 'done' || status === 'active') ? '100%' : '100%';
    const mtop = (status === 'done' || status === 'active') ? '2px' : '';

    return (
        <div style={{ 
            width: '40px', 
            height: '50px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
           
        }}>
            <img
                src={iconSrc}
                alt={`Step ${icon} - ${status}`}
                style={{ 
                    width: iconSize, 
                    height: iconSize, 
                    marginTop: mtop,
                    transition: 'opacity 0.3s ease',
                }} 
            />
        </div>
    );
};

export default CustomStepIcon;
