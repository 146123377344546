import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ProfileWrapper from '../ProfileWrapper/ProfileWrapper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import CustomTextField from '../../../components/FormElements/CustomTextField';
import { routePaths } from '../../../Router/routePaths';
import { getUserProfile, updateUserProfile } from '../../../network/user';
import { setUserData } from '../../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { formatPhoneNumber } from '../../../utils/common';
import addIcon from '../../../assets/icons/addIcon.svg'
import AddAvailabilityModal from './AddAvailabilityModal';
import styles from './style';

const AddOperatingHours = () => {
    const [open, setOpen] = useState(false)
    const [selectedDay, setSelectedDay] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const initialValues = {
        fullName: '',
        phone: '',
        email: '',
    }

    const validationSchema = Yup.object({
        fullName: Yup.string()
            .max(35, 'Maximum username length should be 35.')
            .required('Username is required.'),
        phone: Yup.string()
            .required('Password is required.')
            .min(10, 'Phone number must be at least 10 digits.'),
        email: Yup.string()
            .required('Password is required.'),

    })

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length >= 2 && e.target.value.length < 16) {
            const rawValue = e.target.value.slice(3).replace(/\D/g, '');
            formik.setFieldValue('phone', rawValue);
            e.target.value = rawValue;
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                if (formik.dirty) {
                    const payload = {
                        fullName: formik.values.fullName,
                        phoneNumber: formik.values.phone.includes('+1') ? formik.values.phone : `+1${formik.values.phone}`
                    }
                    const res = await updateUserProfile(payload)
                    dispatch(setUserData(payload))
                }
                // navigate(routePaths.uploadProfile)
            } catch (err) {
                console.log(err)
            }
        },
    });

    useEffect(() => {
        getProfileDetails()
    }, [])

    const getProfileDetails = async () => {
        try {
            const res = await getUserProfile()
            dispatch(setUserData(res.data))
            formik.setValues({
                fullName: res.data.fullName || '',
                phone: res.data.phoneNumber || '',
                email: res.data.email || '',
            });
            console.log(res)
        } catch (err) {
            console.log(err)
        }
    }

    const openModalHandler = (day: string) => {
        setOpen(true)
        setSelectedDay(day)
    }
    const closeModalHandler = () => {
        setOpen(false)
        setSelectedDay('')
    }

    return (
        <Box className="addOperatingHours">
            <ProfileWrapper title="Add Operating Hours" btnDisabled={true} onSubmit={formik.handleSubmit} stepperCount={7}>
                <Box sx={styles.addOpExternalBox}>
                    <Box sx={styles.addOpDaysBox}>
                        {days.map(day =>
                            <Box >
                                <Box>
                                    <Typography sx={styles.addOpDayFont}>
                                        {day}
                                    </Typography>
                                </Box>
                                <Box sx={styles.addAvailabilityBox}>
                                    <Typography sx={styles.addAvailabilityTextFont}>
                                        Add Availability
                                    </Typography>
                                    <img src={addIcon} alt="add" style={{ cursor: 'pointer' }} onClick={() => openModalHandler(day)} />
                                </Box>
                            </Box>
                        )}

                    </Box>
                </Box>
                {open && <AddAvailabilityModal handleClose={closeModalHandler} selectedDay={selectedDay} from="new" />}
            </ProfileWrapper>
        </Box>

    )
}

export default AddOperatingHours