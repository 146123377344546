import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  background: {
    background: "#F5F5FA",
    padding: {
      lg: "100px 0",
      md: "40px 0",
      xs: "30px 0",
    },
    position: "relative",
    overflow: "hidden",
  },
  formBox: {
    boxShadow: "0px 69px 42px 0px #C8C8C840",
    borderRadius: "20px",
    position: "relative",
    padding: {
      lg: "50px 0",
      sm: "20px 20px 30px 20px",
      xs: "20px",
    },
    background: "#fff",
    minHeight: {
      xs: "auto",
      lg: "70vh"
    },
    "& .inqueryFormBox": {
      paddingTop: {
        xs: "10px"
      }
    }
  },
  gridItem: {
    margin: "auto",
  },
  UploadformTitle: {
    fontSize: {
      sm: "20px",
      xs: "18px"
    },
    wordBreak: "break-word",
    color: "#0D0639",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingBottom: "10px",
    paddingTop: {
      xs: "30px",
      sm: "0"
    }
  },
  formTitle: {
    fontSize: {
      sm: "20px",
      xs: "18px"
    },
    color: "#0D0639",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingBottom: {
      lg: "10px",
      xs: "0"
    },
  },
  requiredFields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  requiredText: {
    fontFamily: "Montserrat, sans-serif",
    color: '#0D0639',
    padding: "20px 7px",
    fontWeight: 500,
  },
  errorText: {
    color: 'red',
    fontFamily: "Montserrat, sans-serif",
  },
  buttonContainer: {
    textAlign: "center",
    marginTop: {
      lg: "40px",
      sm: "10px",
      xs: "0"
    },
  },
  backButton: {
    position: "absolute",
    cursor: "pointer",
    top: {
      lg: "40px",
      xs: "10px",
    },
    left: {
      lg: "40px",
      xs: "10px",
    },
  },
  addMoreZipCode: {
    display: "flex",
    alignItems: "center",
    padding: "20px 0 30px",
    cursor: "pointer",
  },
  addMoreZipCodeText: {
    fontSize: "16px",
    color: "#000000",
    paddingLeft: "10px",
    fontWeight: "400",
    fontFamily: "Montserrat, sans-serif",
  },
  uploadBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    padding: "15px",
    borderRadius: "12px",
    cursor: "pointer",
    border: "3px dotted #06038D",
    background: "#06038D0D",
    margin: "30px 0 10px 0",
  },
  uploadText: {
    fontSize: "16px",
    color: "#0D0639",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingBottom: "5px",
  },
  uploadSubText: {
    fontSize: "14px",
    color: "#8989A3",
    fontWeight: "400",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingBottom: "10px",
    lineHeight: "18px",
  },
  noteText: {
    fontSize: "14px",
    color: "#8989A3",
    fontWeight: "400",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingBottom: "10px",
    lineHeight: "18px",
  },
  fileItem: {
    border: "1px solid #E2E8F0",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-between",
    padding: {
      xs: "8px",
      md: "20px 16px",
    },
    margin: "15px 0",
    background: "#007AE405",
    alignItems: "center",
  },
  fileItemText: {
    padding: {
      xs: "8px",
      md: "15px",
    },
    width: "100%",
    textAlign: "start",
  },
  fileName: {
    fontSize: {
      xs: "14px",
      md: "16px",
    },
    color: "#717171",
    wordBreak: "break-word",
    fontWeight: "500",
    fontFamily: "Montserrat, sans-serif",
  },
  fileSize: {
    fontSize: "12px",
    color: "#999999",
    paddingTop: "5px",
  },
  fileProgress: {
    width: "100%",
    background: "#000000",
    borderRadius: "30px",
    height: "4px",
    marginTop: "15px",
  },
  fileProgressText: {
    fontSize: "12px",
    color: "#999999",
    paddingTop: "25px",
    fontWeight: "600",
  },
  successImageContainer: {
    textAlign: "center",
  },
  successTitle: {
    fontSize: {
      lg: "24px",
      xs: "20px",
    },
    color: "#0D0639",
    fontWeight: "700",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    paddingTop: "30px",
  },
  sucessStausImage: {
    width: {
      sm: "auto",
      xs: "100px"
    },
  },
  successSubTitle: {
    fontSize: {
      lg: "20px",
      xs: "16px",
    },
    color: "#8989A3",
    fontWeight: "400",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    lineHeight: "24px",
    paddingTop: "20px",
    paddingBottom: {
      sm: "0",
      xs: "15px"
    }
  },
  vectorImage: {
    position: "absolute",
    right: "0",
    top: "-170px",
    display: {
      lg: "block",
      xs: "none",
    },
  },
  skipFont: {
    cursor: "pointer",
    position: "absolute",
    top: "50px",
    fontWeight: "500",
    right: "45px",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    color: "#8989A3",
  },
  w500: {
    width: 500
  },
  optionalFont: {
    color: "#8989A3",
    paddingLeft: "5px"
  },
  marginL: {
    marginLeft: {
      lg: "5px",
      xs: "0"
    }
  }
};

export default styles;