import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import camera from "../../../assets/icons/cameraIcon.svg";
import { routePaths } from "../../../Router/routePaths";
import UploadImage from "../UploadImage/UploadImage";
import uploadProfile from "../../../assets/images/uploadProfile.svg";
import { uploadImage } from "../../../network/common";
import { useDispatch } from "react-redux";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading, showToast } from "../../../redux/slices/commonSlice";
import "cropperjs/dist/cropper.css";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";

const UpdateProfile = () => {
  const [preview, setPreview] = useState("");
  const [payload, setPayload] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getProfileDetails();
  }, []);

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(setLoading(true));
    const res = await uploadImage(formData);
    dispatch(
      setUserData({
        profilePic: res?.data?.fileUrl,
      })
    );
    setPayload({ url: res.data.fileUrl, key: res.data.key });
    setPreview(res?.data?.fileUrl);
    dispatch(setLoading(false));
    setOpen(false);
  };

  const getProfileDetails = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getUserProfile();
      dispatch(setUserData(res.data));
      setPayload(res?.data?.profileImage);
      setPreview(res?.data.profileImage?.url);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFileChange = async (e: any) => {
    setOpen(true);
    try {
      if (e.target.files && e.target.files.length > 0) {
        dispatch(setLoading(true));
        const file = e.target.files[0];
        const acceptedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

        if (!acceptedImageTypes.includes(file.type)) {
          dispatch(
            showToast({
              color: "error",
              msg: "Please upload images in these formats (jpeg, png, jpg)",
            })
          );
          return;
        }
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
      } else {
        setPreview("");
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleFileRef = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const addProfileHandler = async () => {
    try {
      dispatch(setLoading(true));
      await updateUserProfile({ profileImage: payload });
      dispatch(setUserData({ profileImage: payload }));
      navigate(routePaths.addBusiness);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <ProfileWrapper
      description="The image you are adding will be seen as it is by your customers"
      title="Upload Profile Picture"
      btnDisabled={!preview}
      onSubmit={addProfileHandler}
      stepperCount={2}
    >
      <UploadImage
        handleFileChange={handleFileChange}
        fileRef={fileRef}
        preview={preview}
        camera={camera}
        handleFileRef={handleFileRef}
        uploadImage={uploadProfile}
      />
      {image && (
        <ImageCropper
          open={open}
          image={image}
          onCrop={handleImageUpload}
          onCancel={handleDialogClose}
        />
      )}
    </ProfileWrapper>
  );
};

export default UpdateProfile;
