import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  InputLabel,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "./setting.css";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardFooter from "../Dashboard/DashboardFooter";
import styles from "./style";
import {
  deleteAccount,
  getUserProfile,
  updateUserProfile,
} from "../../../network/user";
import { uploadImage } from "../../../network/common";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slices/commonSlice";
import ChangePassword from "./ChangePassword";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { routePaths } from "../../../Router/routePaths";
import { formatInput, validateField } from "../../../utils/validationUtils";
import { CircularProgress } from "@mui/material";
import activeChangePassword from "../../../assets/settings/activeChangePassword.svg";
import activeDeleteAccount from "../../../assets/settings/activeDeleteAccount.svg";
import activeEditProfile from "../../../assets/settings/activeEditProfile.svg";
import changePassword from "../../../assets/settings/changePassword.svg";
import deleteStatusIcon from "../../../assets/settings/deleteStatusIcon.png";
import deleteAccountImage from "../../../assets/settings/deleteAccount.svg";
import editIcon from "../../../assets/settings/editIcon.svg";
import editProfile from "../../../assets/settings/editProfile.svg";
import imageEditIcon from "../../../assets/settings/imageEditIcon.svg";
import DashboardMobileHeader from "../Dashboard/DashboardMobileHeader";
import successPopupImage from "../../../assets/common/successPopupImage.png";
import fileIcon from "../../../assets/settings/fileIcon.png";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";
import "cropperjs/dist/cropper.css";
import {
  setEditingProfile,
  setUpdatedFields,
  setUserData,
} from "../../../redux/slices/userSlice";
import { RootState } from "../../../redux/rootReducer";

interface DocPayload {
  url: string;
  key: string;
  size: number;
  name: string;
}

const getSx = (val: string | null) => {
  return {
    borderColor: !!val ? "rgba(255, 0, 0, 1)" : undefined,
    "& .MuiFormHelperText-root": {
      margin: "5px 5px -6px 5px",
      fontFamily: "Montserrat, sans-serif",
    },
  };
};

const formatFullAddress = (
  address: string,
  city: string,
  province: string,
  zipCode: string
) => {
  return `${address}, ${city}, ${province}, ${zipCode}`;
};

const Settings: React.FC = () => {
  const [value, setValue] = useState(0);
  const userProfile = useSelector((state: RootState) => state.user.userData);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(userProfile?.profilePic);
  const isEditingProfile = useSelector(
    (state: RootState) => state.user.isEditingProfile
  );
  const updatedFields = useSelector(
    (state: RootState) => state.user.updatedFields
  );

  const [updateDetailsModal, setUpdateDetailsModal] = React.useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [payload, setPayload] = useState<DocPayload[]>([]);
  const backClicked = useSelector(
    (state: RootState) => state.user.userData?.backClicked
  );

  useEffect(() => {
    const isProfileCompletion = localStorage.getItem("isProfileCompletion");
    if (isProfileCompletion !== "true") {
      navigate(routePaths.signIn);
    }
  }, [navigate]);

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(setLoading(true));
    const res = await uploadImage(formData);
    dispatch(
      setUpdatedFields({
        profileImage: {
          url: res?.data?.fileUrl,
          key: res?.data?.key,
        },
      })
    );
    dispatch(
      setUserData({
        profilePic: res?.data?.fileUrl,
      })
    );
    dispatch(setLoading(false));
    setOpen(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleInputChange = async (
    event: any,
    index?: number,
    docName?: string
  ) => {
    let { name, value, type, files } = event.target;

    if (name === "fullName" || name === "phoneNumber") {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "description") {
      value = formatInput(name, value, 1000);
    }
    if (name === "businessTitle") {
      value = formatInput(name, value, 40);
    }

    if (name === "phoneNumber" && !value.startsWith("+1")) {
      value = `+1${value.replace(/^\+?/, "")}`;
    }

    const errorMessage = validateField(name, value);

    if (type === "file" && name === "profilePic") {
      setOpen(true);
      event.preventDefault();
      let files;
      if (event.dataTransfer) {
        files = event.dataTransfer.files;
      } else if (event.target) {
        files = event.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    } else {
      dispatch(setUpdatedFields({ [name]: value }));
      dispatch(
        setUserData({
          [name]: value,
          errors: { ...userProfile?.errors, [name]: errorMessage },
        })
      );
    }
  };

  const handleEditClick = (field: string) => {
    if (field === "services") {
      navigate(routePaths.editBusiness);
    } else if (field === "address") {
      navigate(routePaths.editAddress);
    } else {
      dispatch(setEditingProfile({ [field]: true }));
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClickUpdateDetailsModalClose = () => {
    setUpdateDetailsModal(false);
  };

  const handleClickUpdateDetailsModalOpen = async () => {
    setIsLoading(true);
    const response = await updateUserProfile(updatedFields);
    if (response.type === "success") {
      setIsLoading(false);
      setUpdateDetailsModal(true);
      dispatch(
        setEditingProfile({
          profilePic: false,
          fullName: false,
          phoneNumber: false,
          email: false,
          services: false,
          categories: false,
          subCategories: false,
          businessTitle: false,
          description: false,
          address: false,
        })
      );
    }
  };

  const handleClickDeleteAccountModalClose = () => {
    setDeleteAccountModal(false);
  };

  const handleClickDeleteAccountModalOpen = () => {
    setDeleteAccountModal(true);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const getProfileDetails = async (isEdited: boolean) => {
    const response = await getUserProfile();
    const data = response.data;
    setPayload(data.businessDocuments);
    {
      !isEdited &&
        dispatch(
          setUserData({
            profilePic: data.profileImage?.url,
            fullName: data.fullName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            serviceNames: data.serviceDetails
              .map((service: any) => service.name)
              .join(", "),
            categoryDetails: data.categoryDetails,
            subCategoryDetails: data.subCategoryDetails,
            serviceImages: data.businessImages,
            businessTitle: data.businessTitle,
            description: data.description,
            businessDocuments: data.businessDocuments,
            address: data.address,
            city: data.city,
            province: data.province,
            zipCode: data.zipCode,
            zipCodes: data?.zipCodes?.join(","),
            isProfileCompleted: data.isProfileCompletion,
            errors: {
              phoneNumber: "",
              email: "",
              fullName: "",
              zipCode: "",
              message: "",
              address: "",
              city: "",
              state: "",
              businessTitle: "",
            },
          })
        );
    }
    (isEdited || backClicked) &&
      dispatch(
        setUserData({
          serviceNames: data.serviceDetails
            .map((service: any) => service.name)
            .join(", "),
          categoryDetails: data.categoryDetails,
          subCategoryDetails: data.subCategoryDetails,
          serviceImages: data.businessImages,
          businessDocuments: data.businessDocuments,
          address: data.address,
          city: data.city,
          province: data.province,
          zipCode: data.zipCode,
          zipCodes: data?.zipCodes?.join(","),
          serviceIds: data.serviceIds,
          categoryIds: data.categoryIds,
          subCategoryIds: data.subCategoryIds,
          isProfileCompleted: data.isProfileCompletion,
        })
      );
  };

  useEffect(() => {
    const isEdited = Object.values(isEditingProfile).some(
      (value) => value === true
    );
    getProfileDetails(isEdited);
  }, [backClicked]);

  useEffect(() => {
    const hasErrors = Object.values(userProfile?.errors || "").some(
      (error) => error !== ""
    );
    if (
      !hasErrors &&
      userProfile?.fullName !== "" &&
      userProfile?.phoneNumber !== "" &&
      userProfile?.email !== "" &&
      userProfile?.businessTitle !== "" &&
      userProfile?.description !== ""
    ) {
      dispatch(setUserData({ disabled: false }));
    } else {
      dispatch(setUserData({ disabled: true }));
    }
  }, [
    userProfile?.errors,
    userProfile?.fullName,
    userProfile?.phoneNumber,
    userProfile?.email,
    userProfile?.businessTitle,
    userProfile?.description,
  ]);

  const deleteAccountHandler = async () => {
    try {
      dispatch(setLoading(true));
      await deleteAccount();
      localStorage.clear();
      setDeleteAccountModal(false);
      navigate(routePaths.signIn);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: {
            md: "none",
            xs: "block",
          },
          position: "absolute",
          width: "100%",
        }}
      >
        <DashboardMobileHeader />
      </Box>
      <Box
        sx={{
          display: {
            md: "block",
            xs: "none",
          },
        }}
      >
        <DashboardHeader />
      </Box>
      <Box className="settingsPage" sx={styles.settingPage}>
        <Box sx={styles.settingsBox}>
          <Typography variant="h3" sx={styles.settingsTitle}>
            Settings
          </Typography>
        </Box>
        <Box className="settingTabs" sx={styles.settingTabs}>
          <Box sx={styles.stickyBox}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              sx={styles.tabs}
            >
              <Tab
                label="Edit Profile"
                icon={
                  <Box className="icon">
                    <img src={editProfile} alt="Edit Profile" />
                    <Box
                      component="img"
                      src={activeEditProfile}
                      alt="Edit Profile"
                      className="active"
                    ></Box>
                  </Box>
                }
                iconPosition="start"
              />
              <Tab
                label="Change Password"
                icon={
                  <Box className="icon">
                    <img src={changePassword} alt="ChangePassword" />
                    <Box
                      component="img"
                      src={activeChangePassword}
                      alt="Change Password"
                      className="active"
                    ></Box>
                  </Box>
                }
                iconPosition="start"
              />
              <Box
                sx={styles.deleteAccountBox}
                onClick={handleClickDeleteAccountModalOpen}
                className="deleteAccountBox"
              >
                <Box sx={styles.deleteAccountIconBox}>
                  <Box>
                    <img src={deleteAccountImage} alt="Delete Account" />
                    <Box
                      component="img"
                      src={activeDeleteAccount}
                      alt="Delete Account"
                      className="active"
                    ></Box>
                  </Box>
                </Box>
                Delete Account
              </Box>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box>
              <Box>
                <Box sx={styles.generalDetailsTitle}>General Details</Box>
                <Box>
                  <Typography variant="body1" sx={styles.profileImagesTitle}>
                    Profile Image
                  </Typography>
                  <Box sx={styles.profileImageBox}>
                    <Box
                      sx={styles.profileImage}
                      component="img"
                      src={userProfile?.profilePic}
                    ></Box>
                    <Box sx={styles.editIconBox}>
                      <Box
                        sx={styles.editIconContainer}
                        component="label"
                        tabIndex={-1}
                      >
                        <Box
                          src={imageEditIcon}
                          sx={styles.editIconImage}
                          alt="Image Edit Icon"
                          component="img"
                          onClick={() => handleEditClick("profilePic")}
                        ></Box>
                        <VisuallyHiddenInput
                          name="profilePic"
                          type="file"
                          accept="image/*"
                          onChange={handleInputChange}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={`formControl ${userProfile?.errors?.fullName ? "errorWrapper" : ""}`}
                  sx={styles.formControl}
                >
                  <InputLabel className="inputLable">Name</InputLabel>
                  <TextField
                    name="fullName"
                    value={userProfile?.fullName || ""}
                    placeholder="Name"
                    onChange={handleInputChange}
                    disabled={!isEditingProfile?.fullName}
                    error={!!userProfile?.errors?.fullName}
                    helperText={userProfile?.errors?.fullName}
                    sx={getSx(userProfile?.errors?.fullName)}
                  />
                  <Box
                    onClick={() => handleEditClick("fullName")}
                    className="editIcon"
                  >
                    <img src={editIcon} alt="Edit Icon" />
                  </Box>
                </Box>
                <Box
                  className={`formControl ${userProfile?.errors?.phoneNumber ? "errorWrapper" : ""}`}
                  sx={styles.formControl}
                >
                  <InputLabel className="inputLable">Phone Number</InputLabel>
                  <TextField
                    name="phoneNumber"
                    value={userProfile?.phoneNumber || ""}
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                    disabled={!isEditingProfile?.phoneNumber}
                    error={!!userProfile?.errors?.phoneNumber}
                    helperText={userProfile?.errors?.phoneNumber}
                    sx={getSx(userProfile?.errors?.phoneNumber)}
                  />
                  <Box
                    onClick={() => handleEditClick("phoneNumber")}
                    className="editIcon"
                  >
                    <img src={editIcon} alt="Edit Icon" />
                  </Box>
                </Box>
                <Box
                  className={`formControl ${userProfile?.errors?.email ? "errorWrapper" : ""}`}
                  sx={styles.formControl}
                >
                  <InputLabel className="inputLable">Email Address</InputLabel>
                  <TextField
                    name="email"
                    value={userProfile?.email || ""}
                    placeholder="Email Address"
                    disabled={!isEditingProfile?.email}
                    error={!!userProfile?.errors?.email}
                    helperText={userProfile?.errors?.email}
                    sx={getSx(userProfile?.errors?.email)}
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={styles.businessDetailsTitle}>Business Details</Box>
                <Box className="formControl" sx={styles.formControl}>
                  <InputLabel className="inputLable">Service Name</InputLabel>
                  <TextField
                    name="service"
                    value={userProfile?.serviceNames}
                    placeholder="Service Name"
                    onChange={handleInputChange}
                    disabled={!isEditingProfile?.services}
                  />
                  <Box
                    onClick={() => handleEditClick("services")}
                    className="editIcon"
                  >
                    <img src={editIcon} alt="Edit Icon" />
                  </Box>
                </Box>
                <Box className="formControl" sx={styles.formControl}>
                  <Box sx={styles.serviceCategoryBox}>
                    <InputLabel className="inputLable">
                      Service Category
                    </InputLabel>
                    <NavLink to={routePaths.editServiceCategory}>
                      <Box
                        component="img"
                        src={editIcon}
                        alt="editIcon"
                        className="boxEditIcon"
                      ></Box>
                    </NavLink>
                  </Box>
                  <Box sx={styles.serviceCategoryList}>
                    {userProfile?.categoryDetails?.map((category: any) => (
                      <Box sx={styles.serviceCategoryItem}>
                        <Box sx={styles.serviceCategoryIcon}>
                          <img
                            src={category.displayImage.url}
                            width="100%"
                            alt="Pipe Installation Image"
                          />
                        </Box>
                        {category.categoryName}
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box className="formControl" sx={styles.formControl}>
                  <Box sx={styles.serviceCategoryBox}>
                    <InputLabel className="inputLable">
                      Service Sub Category
                    </InputLabel>
                    <NavLink to={routePaths.editServiceSubCategory}>
                      <Box
                        component="img"
                        src={editIcon}
                        alt="editIcon"
                        className="boxEditIcon"
                      ></Box>
                    </NavLink>
                  </Box>
                  <Box sx={styles.serviceCategoryList}>
                    {userProfile?.subCategoryDetails?.map(
                      (subCategory: any) => (
                        <Box sx={styles.serviceSubCategoryItem}>
                          {subCategory.subCategoryName}
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
                <Box className="formControl" sx={styles.formControl}>
                  <Box sx={styles.serviceCategoryBox}>
                    <InputLabel className="inputLable">
                      Service Images
                    </InputLabel>
                    <Box component="label" tabIndex={-1}>
                      <NavLink to={routePaths.editBusinessImages}>
                        <Box
                          component="img"
                          src={editIcon}
                          alt="editIcon"
                          className="boxEditIcon"
                        ></Box>
                      </NavLink>
                      <VisuallyHiddenInput type="file" />
                    </Box>
                  </Box>
                  <Box sx={styles.serviceImagesList}>
                    {userProfile?.serviceImages?.map((image: any) => (
                      <Box
                        component="img"
                        src={image.url}
                        sx={styles.serviceImage}
                      ></Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  className={`formControl ${userProfile?.errors?.businessTitle ? "errorWrapper" : ""}`}
                  sx={styles.formControl}
                >
                  <InputLabel className="inputLable">Title</InputLabel>
                  <TextField
                    name="businessTitle"
                    value={userProfile?.businessTitle || ""}
                    placeholder="Title"
                    onChange={handleInputChange}
                    disabled={!isEditingProfile?.businessTitle}
                    error={!!userProfile?.errors?.businessTitle}
                    helperText={userProfile?.errors?.businessTitle}
                    sx={getSx(userProfile?.errors?.businessTitle)}
                  />
                  <Box
                    onClick={() => handleEditClick("businessTitle")}
                    className="editIcon"
                  >
                    <img src={editIcon} alt="Edit Icon" />
                  </Box>
                </Box>
                <Box
                  className="formControl descriptionInput"
                  sx={styles.formControl}
                >
                  <InputLabel className="inputLable">Description</InputLabel>
                  <TextField
                    name="description"
                    value={userProfile?.description || ""}
                    placeholder="Description"
                    onChange={handleInputChange}
                    disabled={!isEditingProfile?.description}
                    error={!!userProfile?.errors?.description}
                    helperText={userProfile?.errors?.description}
                    sx={getSx(userProfile?.errors?.description)}
                    multiline
                    maxRows={3}
                  />
                  <Box
                    onClick={() => handleEditClick("description")}
                    className="editIcon description"
                  >
                    <Box component="img" src={editIcon} alt="Edit Icon"></Box>
                  </Box>
                </Box>
                <Box className="formControl" sx={styles.formControl}>
                  <InputLabel className="inputLable">
                    License/Insurance
                  </InputLabel>
                  <Box sx={styles.licenseBox}>
                    <Box sx={styles.licenseList}>
                      {userProfile?.businessDocuments?.map(
                        (document: any, index: number) => (
                          <Box key={index} sx={styles.licenseItem}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Box
                                component="img"
                                src={fileIcon}
                                alt="fileIcon"
                                sx={styles.licenseIcon}
                              ></Box>
                              <Box sx={styles.licenseText}>
                                {document?.name}
                                <Box sx={styles.licenseSize}>
                                  {Math.ceil(document?.size) + " " + "KB"}
                                </Box>
                              </Box>
                            </Box>
                            {index === 0 && (
                              <Box
                                sx={styles.editIcon}
                                component="label"
                                tabIndex={-1}
                              >
                                <NavLink to={routePaths.editLicenseDocuments}>
                                  <Box
                                    src={editIcon}
                                    alt="Edit Icon"
                                    component="img"
                                  />
                                </NavLink>
                                <VisuallyHiddenInput
                                  type="file"
                                  name="businessDocuments"
                                  onChange={(e) =>
                                    handleInputChange(e, index, document.name)
                                  }
                                />
                              </Box>
                            )}
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.addressBox}>
                  <Box className="formControl" sx={styles.formControl}>
                    <InputLabel className="inputLable">Address</InputLabel>
                    <TextField
                      name="address"
                      value={formatFullAddress(
                        userProfile?.address || "",
                        userProfile?.city || "",
                        userProfile?.province || "",
                        userProfile?.zipCode || ""
                      )}
                      placeholder="Description"
                      onChange={handleInputChange}
                      disabled={!isEditingProfile?.address}
                    />
                    <Box
                      onClick={() => handleEditClick("address")}
                      className="editIcon"
                    >
                      <img src={editIcon} alt="Edit Icon" />
                    </Box>
                  </Box>
                </Box>
                <Box className="formControl zipcode" sx={styles.formControl}>
                  <InputLabel className="inputLable">
                    Service Location Zip Codes
                  </InputLabel>
                  <TextField
                    name="zipCodes"
                    value={userProfile?.zipCodes || ""}
                    disabled
                    placeholder="Service Location Zip Codes"
                  />
                </Box>

                {isLoading ? (
                  <Box sx={styles.saveButtonContainer}>
                    <Button sx={styles.saveButton}>
                      <CircularProgress size={40} />
                    </Button>
                  </Box>
                ) : (
                  <Box sx={styles.saveButtonContainer}>
                    <Button
                      sx={styles.saveButton}
                      onClick={handleClickUpdateDetailsModalOpen}
                      disabled={userProfile?.disabled}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePassword />
          </TabPanel>
        </Box>
        <Box
          sx={{
            paddingLeft: {
              md: "0",
              sm: "50px",
              xs: "0",
            },
          }}
        >
          <DashboardFooter />
        </Box>
        <Dialog
          onClose={handleClickUpdateDetailsModalClose}
          aria-labelledby="customized-dialog-title"
          open={updateDetailsModal}
          sx={{
            "& .MuiDialog-paper": {
              width: {
                xs: "90%",
                sm: "70%",
                md: "400px",
              },
              borderRadius: "40px",
              maxWidth: "90%",
            },
          }}
        >
          <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
            <img src={successPopupImage} alt="Sucess Image" />
            <Box>Your details has been updated successfully!</Box>
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <Box sx={styles.contentBox}>
              <Box sx={styles.buttonContainer}>
                <Button
                  sx={styles.doneButton}
                  onClick={handleClickUpdateDetailsModalClose}
                >
                  Done
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
        {image && (
          <ImageCropper
            open={open}
            image={image}
            onCrop={handleImageUpload}
            onCancel={handleDialogClose}
          />
        )}

        {deleteAccountModal && (
          <ConfirmationModal
            content={" Are you sure you want to delete this account?"}
            onReject={handleClickDeleteAccountModalClose}
            onAccept={deleteAccountHandler}
            imgSrc={deleteStatusIcon}
          />
        )}
      </Box>
    </Box>
  );
};

const TabPanel: React.FC<{
  children?: React.ReactNode;
  index: number;
  value: number;
}> = (props) => {
  const { children, index, value, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ width: "75%" }}
    >
      {value === index && (
        <Box
          sx={{
            padding: {
              md: "10px 40px",
              xs: "0 0 10px 0",
              sm: "0 0 10px 15px",
            },
          }}
        >
          {children}
        </Box>
      )}
    </Typography>
  );
};

export default Settings;
