import React, { useState } from "react";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
// import "./header.css";

import { getStyles } from "../../../components/LandingPage/Consumer/Header/style";

import MenuCloseIcon from "../../../assets/icons/menuCloseIcon.svg";
import headerLogo from "../../../assets/common/header_logo.png";
import headerLogoHomeSeller from "../../../assets/common/header_logo_home_seller.png";
import logoutStatusIcon from "../../../assets/Dashboard/logoutStatusIcon.png";
import menuIcon from "../../../assets/common/menu_icon.svg";
import { routePaths } from "../../../Router/routePaths";

interface HeaderProps {
  HeaderRef?: React.RefObject<HTMLDivElement>;
}

const DashboardMobileHeader: React.FC<HeaderProps> = ({ HeaderRef }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [logoutAccountModal, setLogoutAccountModal] = React.useState(false);
  const navigate = useNavigate();

  const handleClickLogoutAccountModalClose = () => {
    setLogoutAccountModal(false);
  };

  const handleClickLogoutAccountModalOpen = () => {
    setLogoutAccountModal(true);
  };

  const logoutHandler = () => {
    localStorage.removeItem("accessToken");
    navigate(routePaths.signIn);
  };

  const styles = getStyles(menuVisible);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <Box ref={HeaderRef} sx={styles.box}>
        <Container maxWidth="xl">
          <Box sx={styles.toggleMenu}>
            <Box>
              <NavLink to="/">
                <img src={headerLogo} alt="Header Logo" width="70%" />
              </NavLink>
            </Box>
            <Box onClick={toggleMenu} sx={styles.toggleMenuIcon}>
              <img src={menuIcon} alt="Menu Icon" />
            </Box>
          </Box>

          <List className="headerMenu" sx={styles.menuList}>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.logoListItemText}>
                <NavLink to="/">
                  <Link href="#">
                    <Box
                      component="img"
                      sx={styles.headerLogo}
                      src={headerLogoHomeSeller}
                      alt="Header Logo"
                    ></Box>
                  </Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>Dashboard</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to="/availability"
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>Availability</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink
                  to="/settings"
                  className={({ isActive }) => (isActive ? "activeNav" : "")}
                >
                  <Link sx={styles.link}>Settings</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemText sx={styles.listItemText}>
                <NavLink to="" onClick={handleClickLogoutAccountModalOpen}>
                  <Link sx={styles.link}>Logout</Link>
                </NavLink>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.menucloseIcon}>
              <Box
                onClick={toggleMenu}
                component="img"
                src={MenuCloseIcon}
                alt="Menu Close Icon"
              ></Box>
            </ListItem>
          </List>
        </Container>
      </Box>

      {/* Logout Accound Modal */}
      <Dialog
        onClose={handleClickLogoutAccountModalClose}
        aria-labelledby="customized-dialog-title"
        open={logoutAccountModal}
        sx={{
          "& .MuiDialog-paper": {
            width: {
              xs: "90%",
              sm: "70%",
              md: "400px",
            },
            borderRadius: "40px",
            maxWidth: "90%",
          },
        }}
      >
        <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
          <Box src={logoutStatusIcon} alt="Sucess Image" component="img" sx={{
            width:"auto",
            "@media (orientation: landscape) and (max-width: 999px)": {
            width:"140px",
            }
          }}>

          </Box>
          <Box>
            Are you sure you want to logout from Home Seller Service LLC?
          </Box>
        </DialogTitle>

        <DialogContent
          sx={{
            padding: {
              lg: "30px 90px 20px",
              xs: "0 0 20px 0",
            },
            "@media (orientation: landscape) and (max-width: 999px)": {
              overflowY:"unset",
            }
          }}
        >
          <Box
            sx={{
              padding: "0 20px",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: {
                  sm: "row",
                  xs: "column",
                },
              }}
            >
              <Button sx={styles.yesButton} onClick={logoutHandler}>
                Yes
              </Button>
              <Button
                sx={styles.noButton}
                onClick={handleClickLogoutAccountModalClose}
              >
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DashboardMobileHeader;
