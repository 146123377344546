import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserProfile } from "../../network/user";
import { formatUserData } from "../../utils/formatUserData";

interface UserData {
  address?: string;
  businessDocuments?: any[];
  businessImages?: any[];
  categoryIds?: string[];
  city?: string;
  createdAt?: string;
  description?: string;
  email?: string;
  fullName?: string;
  isDeleted?: boolean;
  isNotificationEnabled?: boolean;
  phoneNumber?: string;
  province?: string;
  serviceIds?: string[];
  serviceTiming?: Record<string, any>;
  status?: string;
  subCategoryIds?: string[];
  updatedAt?: string;
  userName?: string;
  userType?: number;
  zipCode?: string;
  zipCodes?: string[];
  _id?: string;
  isProfileCompleted?: boolean;
  backClicked?: boolean;
  [key: string]: any;
}

interface UserState {
  userData: UserData | null;
  isEditingProfile: Record<string, boolean>;
  updatedFields?: Record<string, any>;
}

const initialState: UserState = {
  userData: null,
  isEditingProfile: {
    profilePic: false,
    fullName: false,
    phoneNumber: false,
    email: false,
    services: false,
    categories: false,
    subCategories: false,
    businessTitle: false,
    description: false,
    address: false,
  },
  updatedFields: {},
};

export const fetchProfileDetails = createAsyncThunk(
  "user/fetchProfileDetails",
  async (_, { dispatch }) => {
    const response = await getUserProfile();
    const data = response.data;
    dispatch(setUserData(formatUserData(data)));
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<Partial<UserData>>) {
      if (!state.userData) {
        state.userData = action.payload;
      } else {
        state.userData = {
          ...state.userData,
          ...action.payload,
        };
      }
    },
    setEditingProfile(
      state,
      action: PayloadAction<Partial<Record<string, boolean>>>
    ) {
      state.isEditingProfile = {
        ...state.isEditingProfile,
        ...Object.fromEntries(
          Object.entries(action.payload).map(([key, value]) => [
            key,
            value ?? false,
          ])
        ),
      };
    },
    setUpdatedFields(state, action: PayloadAction<Record<string, any>>) {
      state.updatedFields = {
        ...state.updatedFields,
        ...action.payload,
      };
    },
    clearUserData(state) {
      state.userData = null;
    },
  },
});

export const {
  setUserData,
  clearUserData,
  setEditingProfile,
  setUpdatedFields,
} = userSlice.actions;
export default userSlice.reducer;
