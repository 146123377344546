import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import { Box, Grid2, Typography } from "@mui/material";
import CustomTextField from "../../../components/FormElements/CustomTextField";
import { routePaths } from "../../../Router/routePaths";
import Search from "../../../assets/icons/Search.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { getCategories } from "../../../network/services";
import { setUserData } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/commonSlice";
import TextError from "../../../components/TextError/TextError";
import useDebounce from "../../../hooks/useDebounce";
import styles from "../SelectServiceSubCategory/style";

interface Category {
  serviceName: string;
  categories: any;
}
interface DisplayImage {
  url: string;
  key: string;
}

interface Category {
  categoryName: string;
  displayImage: DisplayImage;
  subcategories: Array<any>;
  _id: string;
}

interface Service {
  serviceName: string;
  _id: string;
  categories: Category[];
}

interface ResponseData {
  data: Service[];
}
const EditServiceCategory = () => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [serviceIds, setServiceIds] = useState<string[]>([]);
  const [staticSelected, setStaticSelected] = useState<string[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector((state: RootState) => state.common.loader);
  const userData = useSelector((state: RootState) => state.user.userData);
  const updatedSelectedItems = useSelector(
    (state: RootState) => state.user.userData?.categoryIds
  );
  const queryParams = new URLSearchParams(window.location.search);
  const previous = queryParams.get("previous");

  useEffect(() => {
    setSelectedItems(updatedSelectedItems || []);
  }, [updatedSelectedItems]);

  useEffect(() => {
    if (userData?.serviceIds) {
      setServiceIds(userData.serviceIds);
    }
  }, [userData]);

  useEffect(() => {
    setSelectedItems(updatedSelectedItems || []);
    setStaticSelected(updatedSelectedItems || []);
  }, [userData]);

  useEffect(() => {
    serviceIds?.length > 0 && fetchCategories(debouncedSearchText);
  }, [debouncedSearchText, serviceIds]);

  const fetchCategories = async (textArg: String) => {
    try {
      dispatch(setLoading(true));
      const payload = {
        serviceIds: serviceIds || [],
        keyword: searchText,
      };
      const res = await getCategories(payload);
      const categoryIds = deriveCategoryIds(res, serviceIds, staticSelected);
      !textArg && setStaticSelected(categoryIds);
      setCategories(res?.data);
    } catch (err) {
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const deriveCategoryIds = (
    res: ResponseData | undefined,
    serviceIds: string[],
    selectedItems: string[]
  ): string[] => {
    const catIds: string[] = [];
    if (res?.data && Array.isArray(res.data)) {
      res.data.forEach((service) => {
        if (serviceIds.includes(service._id)) {
          service.categories.forEach((category) => {
            if (selectedItems.includes(category._id)) {
              catIds.push(category._id);
            }
          });
        }
      });
    }
    return catIds;
  };

  const handleSelectServiceCategories = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
      setStaticSelected(staticSelected.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
      setStaticSelected([...staticSelected, id]);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const selectCategoryHandler = async () => {
    try {
      dispatch(setLoading(true));
      const payload = { categoryIds: staticSelected };
      dispatch(setUserData(payload));
      navigate(`${routePaths.editServiceSubCategory}?previous=true`);
    } catch (err) {
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <Box
      className="selectServiceCategoryForm"
      sx={styles.selectSubServiceCategoryForm}
    >
      <ProfileWrapper
        title="Edit Service Category"
        btnDisabled={staticSelected.length === 0  }
        onSubmit={selectCategoryHandler}
        hide={true}
        buttonText="Next"
        backHandler={() => navigate(`${routePaths.editBusiness}?previous=true`)}
      >
        <Box sx={styles.innerContainer}>
          <form>
            <Box>
              <CustomTextField
                value={searchText}
                onChange={handleSearch}
                placeholder="Search Categories"
                type={"text"}
                name="fullName"
                endAdornment={Search}
                profile={true}
                allowOnlyAlphabets={true}
                maxChar={32}
              />
            </Box>
          </form>

          <Box sx={styles.categoriesContainer}>
            {categories?.length === 0 && !loader && searchText && (
              <TextError msg="No items matches your search." />
            )}
            {categories?.map((item, index) => (
              <>
                {item?.categories?.length > 0 && (
                  <Typography
                    sx={{
                      ...styles.categoryName,
                      mt: index === 0 ? 0 : 2,
                    }}
                  >
                    {item?.serviceName}
                  </Typography>
                )}
                <Grid2 container spacing={2}>
                  {item?.categories?.map((subCat: any) => {
                    return (
                      <Grid2 key={subCat?._id}>
                        <Box
                          sx={{
                            ...styles.categoriesGrid,
                            border: `${selectedItems.includes(subCat?._id) ? "1px solid #1CB469" : "1px solid #8989A3"}`,
                            background: `${selectedItems.includes(subCat?._id) ? "#1CB469" : ""}`,
                          }}
                          onClick={() =>
                            handleSelectServiceCategories(subCat?._id)
                          }
                        >
                          <Box
                            sx={styles.imageStyles}
                            src={subCat?.displayImage?.url}
                            alt={subCat?.categoryName}
                            component="img"
                          ></Box>
                          <Typography
                            sx={{
                              ...styles.subCatName,
                              color: `${selectedItems.includes(subCat?._id) ? "white" : "#8989A3"}`,
                            }}
                          >
                            {subCat?.categoryName}
                          </Typography>
                        </Box>
                      </Grid2>
                    );
                  })}
                </Grid2>
              </>
            ))}
          </Box>
        </Box>
      </ProfileWrapper>
    </Box>
  );
};

export default EditServiceCategory;
