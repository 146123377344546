import { useEffect } from "react";
import Footer from "../LandingPage/Consumer/Footer/footer";
import Header from "../LandingPage/Consumer/Header/header";
import GetHelp from "../LandingPage/ServiceProvider/GetHelp/GetHelp";
import Content from './Content';

const PrivacyPolicy = () => {
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
        <>
            <Header />
            <Content />
            <GetHelp />
            <Footer />
        </>
    )
}

export default PrivacyPolicy;