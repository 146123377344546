import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  background: {
    background: "#F5F5FA",
    minHeight: "100vh",
  },
  contactWrappercontainer: {
    display: "flex",
    alignItems: "center",
    padding: {
      lg: "20px 0",
      sm: "10px 0",
      xs: "0"
    },
  },
  list: {
    display: "flex",
    alignItems: "center",
    padding: {
      xs: "0",
      lg: "8px 0"
    }
  },
  link: {
    fontSize: {
      xl: "20px",
      xs: "16px",
    },
    fontWeight: "500",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    opacity: "0.4",
    marginRight: "20px",
    fontFamily: "Montserrat, sans-serif",
  },
  linkActive: {
    fontSize: {
      xl: "20px",
      xs: "16px",
    },
    fontWeight: "500",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    fontFamily: "Montserrat, sans-serif",
  },
  card: {
    background: "#FFFFFF",
    padding: {
      xl: "30px",
      xs: "16px",
    },
    borderRadius: "20px",
  },
  cardHeader: {
    marginBottom: "30px",
    display: "flex",
    flexDirection: {
      md: "row",
      xs: "column",
    },
    justifyContent: "space-between",
    alignItems: {
      md: "center",
      xs: "flex-start",
    },
  },
  profileContainer: {
    display: "flex",
    alignItems: {
      md: "center",
      xs: "flex-start",
    },
    flexDirection: {
      md: "row",
      xs: "column",
    },
  },
  profileImage: {
    border: "3px solid #437BE9",
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    objectFit: "cover",
    height: {
      xl: "80px",
      xs: "60px"
    },
    width: {
      xl: "80px",
      xs: "60px"
    },
    borderRadius: "50%",
  },
  profileDetails: {
    display: "flex",
    alignItems: {
      md: "center",
      xs: "flex-start",
    },
    flexDirection: {
      md: "row",
      xs: "column",
    },
    marginTop: {
      md: "0",
      xs: "10px",
    },
    marginBottom: {
      md: "0",
      xs: "20px",
    },
  },
  fullName: {
    fontSize: {
      xl: "20px",
      xs: "16px"
    },
    color: "#000000",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",
    padding: {
      sm: "16px 0 16px 12px",
      xs: "8px 0 8px 12px"
    },
    marginLeft: {
      md: "20px",
      xs: "0",
    },
  },
  statusDot: {
    minHeight: "8px",
    minWidth: "8px",
    maxHeight: "8px",
    maxWidth: "8px",
    borderRadius: "50%",
    background: "#3F8CFF",
    margin: "0 10px",
    display: {
      md: "flex",
      xs: "none",
    },
  },
  businessTitle: {
    fontSize: {
      xl: "16px",
      xs: "12px"
    },
    color: "#EB863D",
    background: "#FEF1EC",
    fontWeight: "500",
    // width: "70%",
    // wordBreak: "break-all",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "uppercase",
    padding: {
      xl: "7px 12px",
      xs: "5px 12px"
    },
    height: "max-content",
    borderRadius: "100px",
    marginRight: "20px"
  },
  button: {
    background: "#06038D",
    padding: {
      xl: "13px 20px",
      xs: "10px 30px"
    },
    borderRadius: "12px",
    fontSize: {
      xl: "18px",
      xs: "14px",
    },
    boxShadow: "4px 8px 24px 0px #3F8CFF40",
    color: "#fff",
    fontWeight: "600",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    width: "max-content",
  },
  inquirySentbutton: {
    background: '#35A609',
    padding: {
      xl: "13px 20px",
      xs: "10px 30px"
    },
    borderRadius: '12px',
    fontSize: {
      xl: "18px",
      xs: "14px"
    },
    color: '#fff',
    fontWeight: '600',
    textDecoration: 'none',
    width: "max-content",
    fontFamily: 'Montserrat, sans-serif',
    textTransform: "capitalize"

  },
  operatingHours: {
    background: "#FAFAFA",
    border: "1px solid #0D063966",
    borderRadius: "10px",
    padding: "14px",
  },
  operatingHoursTitle: {
    color: "#0D0639",
    fontSize: {
      xl: "16px",
      xs: "14px"
    },
    fontWeight: "500",
    display: "block",
    marginBottom: "8px",
    fontFamily: 'Montserrat, sans-serif',
  },
  operatingHoursText: {
    color: "#0D0639",
    display: "block",
    fontFamily: 'Montserrat, sans-serif',
    fontSize: {
      xl: "14px",
      xs: "12px"
    },
    marginBottom: "8px",
  },
  slotContainer: {
    display: "flex",
    flexDirection: "column",
  },
  servicesOffered: {
    margin: "30px 0",
  },
  servicesOfferedTitle: {
    color: "#000000B2",
    opacity: "0.7",
    marginBottom: "10px",
    fontWeight: "500",
    fontSize: {
      xl: "20px",
      xs: "16px"
    },
    fontFamily: "Montserrat, sans-serif",
  },
  servicesList: {
    display: "flex",
    flexDirection: "row",
    gap: "14px",
    flexWrap: "wrap"
  },
  serviceCategory: {
    background: "#ff711d",
    padding: "4px 10px 4px 4px",
    minWidth: "190px",
    borderRadius: "37px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: {
      xl: "18px",
      xs: "14px"
    },
    fontFamily: "Montserrat, sans-serif",
  },
  serviceCategoryIcon: {
    background: "#FFF",
    maxHeight: {
      xl: "38px",
      xs: "30px"
    },
    maxWidth: {
      xl: "38px",
      xs: "30px"
    },
    minHeight: {
      xl: "38px",
      xs: "30px"
    },
    minWidth: {
      xl: "38px",
      xs: "30px"
    },
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    marginRight: {
      xl: "10px",
      xs: "7px"
    },
    justifyContent: "center",
  },
  servicesImages: {
    marginBottom: "30px",
  },
  servicesImagesTitle: {
    color: "#000000B2",
    opacity: "0.7",
    marginBottom: "14px",
    fontWeight: "500",
    fontSize: {
      xl: "20px",
      xs: "16px"
    },
    fontFamily: "Montserrat, sans-serif",
  },
  serviceImage: {
    width: "100%",
    borderRadius: "15px",
    height: "262px",
    objectFit: "contain",
    background: "#F5F5FA"
  },
  businessDescription: {
    border: "1px solid #06038D",
    borderRadius: "20px",
    padding: "15px",
    background: "#3F8CFF12",
    marginBottom: {
      lg: "20px",
      xs: "10px",
    },
  },
  businessDescriptionTitle: {
    color: "#070477",
    fontSize: {
      xl: "24px",
      xs: "18px",
    },
    lineHeight: "30px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    marginBottom: "10px",
  },
  businessDescriptionText: {
    color: "#4F4D89",
    fontSize: {
      xl: "18px",
      xs: "14px",
    },
    opacity: "0.8",
    lineHeight: "30px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
  },
  dialogPaper: {
    width: {
      xs: "90%",
      sm: "70%",
      md: "800px",
    },
    borderRadius: "20px",
    maxWidth: "90%",
  },
  dialogTitle: {
    m: 0,
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "600",
  },
  closeButton: (theme: Theme) => ({
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  }),
  dialogContent: {
    padding: {
      lg: "30px 90px 40px",
      xs: "0 0 20px 0",
    },
  },
  formBox: {
    padding: "0 20px",
  },
  submitButton: {
    background: "#06038D",
    padding: "13px 70px",
    borderRadius: "12px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "white",
    fontWeight: "600",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
    "&.Mui-disabled": {
      backgroundColor: "#B3B3CF",
      color: "white",
    },
  },
  submitButtonContainer: {
    textAlign: "center",
    marginTop: "30px",
  },
  dialogPaperSuccess: {
    width: {
      xs: "90%",
      sm: "70%",
      md: "400px",
    },
    borderRadius: "40px",
    maxWidth: "90%",
  },
  dialogTitleSuccess: {
    m: 0,
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    fontSize: "22px",
    fontWeight: "700",
  },
  dialogContentSuccess: {
    padding: {
      lg: "30px 90px 40px",
      xs: "0 0 20px 0",
    },
    "@media (orientation: landscape) and (max-width: 999px)": {
      overflowY:"unset",
    }
  },
  formBoxSuccess: {
    padding: "0 20px",
  },
  submitButtonSuccess: {
    background: "#06038D",
    padding: "13px 60px",
    borderRadius: "40px",
    fontSize: {
      lg: "18px",
      xs: "16px",
    },
    color: "#fff",
    fontWeight: "500",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    textTransform: "capitalize",
  },
  submitButtonContainerSuccess: {
    textAlign: "center",
  },
  breadCumsIcon: {
    paddingLeft: "14px",
  },
  categoryTitle: {
    width: "40px",
    height: "40px",
  },
  astrikIcon: {
    marginBottom: "5px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px"
  }
};

export default styles;