import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileWrapper from '../ProfileWrapper/ProfileWrapper';
import camera from "../../../assets/icons/cameraIcon.svg";
import { routePaths } from '../../../Router/routePaths';
import UploadImage from '../UploadImage/UploadImage';
import uploadBusiness from '../../../assets/images/uploadBusiness.svg';
import { uploadImage } from '../../../network/common';
import { getUserProfile, updateUserProfile } from '../../../network/user';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../../redux/slices/userSlice';
import { setLoading, showToast } from '../../../redux/slices/commonSlice';
import { ServiceTiming } from '../../../utils/common';

interface ImagePayload {
    url: string;
    key: string;
}

const AddBusinessImages: React.FC = () => {
    const [serviceTiming, setServiceTiming] = useState<ServiceTiming>({})
    const [previews, setPreviews] = useState<string[]>([]);
    const [payload, setPayload] = useState<ImagePayload[]>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        getProfileDetails()
    }, [])

    const getProfileDetails = async () => {
        try {
            dispatch(setLoading(true))
            const res = await getUserProfile()
            dispatch(setUserData(res.data))
            setServiceTiming(res?.data?.serviceTiming)
            setPayload(res?.data?.businessImages)
            setPreviews(res?.data?.businessImages.map((item: ImagePayload) => item?.url))
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFiles = Array.from(e.target.files);
            const acceptedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

            if (previews.length + newFiles.length > 3) {
                dispatch(showToast({ color: 'error', msg: 'Maximum of 3 images can be added' }));
                return;
            }
            for (const file of newFiles) {
                if (!acceptedImageTypes.includes(file.type)) {
                    dispatch(showToast({ color: 'error', msg: "Please upload images in these formats (jpeg, png, jpg)" }));
                    return;
                }
            }
            const formDataList = newFiles.map(file => {
                const formData = new FormData();
                formData.append('file', file);
                return formData;
            });

            try {
                dispatch(setLoading(true))
                const uploadPromises = formDataList.map(formData => uploadImage(formData));
                const responses = await Promise.all(uploadPromises);

                const urls: ImagePayload[] = responses.map(res => ({
                    url: res.data.fileUrl,
                    key: res.data.key
                }));

                setPayload(prev => [...prev, ...urls]);
                setPreviews(prev => [...prev, ...urls.map(item => item.url)]);
            } catch (error) {
                console.error("Error uploading images:", error);
            } finally {
                dispatch(setLoading(false))
            }
        }
    };

    const handleFileRef = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };

    const addProfileHandler = async () => {
        try {
            dispatch(setLoading(true))
            await updateUserProfile({ businessImages: payload })
            dispatch(setUserData({ businessImages: payload }))
            const hasDataInSlots = Object.values(serviceTiming).some(day => day.slots.length > 0);
            hasDataInSlots ? navigate(routePaths.operatingHours) : navigate(routePaths.addOperatingHours)
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setLoading(false))
        }

    };

    const handleClose = (index: number) => {
        setPreviews(prev => prev.filter((_, i) => i !== index));
        setPayload(prev => prev.filter((_, i) => i !== index));
    };
    const skipHandler = () => {
        const hasDataInSlots = Object.values(serviceTiming).some(day => day.slots.length > 0);
        hasDataInSlots ? navigate(routePaths.operatingHours) : navigate(routePaths.addOperatingHours)
    }

    return (
        <ProfileWrapper
            description="Note: Max 3 images can be added"
            title="Add Business Images"
            btnDisabled={previews.length === 0}
            onSubmit={addProfileHandler}
            stepperCount={6}
            businessImages={true}
            onSkip={skipHandler}
        >
            <UploadImage
                handleFileChange={handleFileChange}
                fileRef={fileRef}
                previews={previews}
                camera={camera}
                handleFileRef={handleFileRef}
                uploadImage={uploadBusiness}
                multiple={true}
                handleClose={handleClose}
            />
        </ProfileWrapper>
    );
};

export default AddBusinessImages;
