import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  background: {
    background: "#F5F5FF",
    padding: {
      lg: "60px 0 0",
      xs: "30px 0 0",
    },
    marginTop: {
      lg: "80px",
      xs: "30px",
    },
  },
  container: {
    padding: {
      xl: "0 24px",
      lg: "0 60px",
    },
  },
  paddingBottom: {
    paddingBottom: {
      lg: "50px",
      xs: "30px",
    },
  },
  title: {
    fontSize: {
      lg: "40px",
      xs: "32px",
    },
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "800",
    paddingBottom: "30px",
  },
  searchBox: {
    position: "relative",
    borderRadius: "40px",
    background: "#fff",
    boxShadow: "0px 4px 12px 0px #0000001F",
    padding: "12px",
  },
  inputFormControl: {
    marginRight: "20px",
    backgroundColor: "#f1f1ff",
    fontFamily: "Montserrat, sans-serif",
    borderRadius: "28px",
  },
  searchIconBox: {
    position: "absolute",
    right: "12px",
    transform: "translateY(-50%)",
    top: "50%",
    background: "#06038D",
    borderRadius: "50%",
    justifyContent: "center",
    height: {
      lg: "56px",
      xs: "40px",
    },
    width: {
      lg: "56px",
      xs: "40px",
    },
    display: "flex",
    alignItems: "center",
  },
  accordionContainer: {
    background: "#fff",
    borderRadius: "18px",
    padding: "50px 0 0",
  },
  tabPanelText: {
    paddingBottom: {
      lg: "40px",
    },
  },
  accordionSummary: {
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    padding: 0,
    fontWeight: "500",
  },
  accordionDetails: {
    color: "#8989A3",
    padding: "0 16px 20px",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Montserrat, sans-serif",
  },
  searchResultType: {
    borderBottom: "1px solid #DEDEDE",
  },
  searchResultTitle: {
    fontSize: {
      lg: "20px",
      xs: "15px",
    },
    color: "#06038D",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "800",
    paddingBottom: "25px",
    paddingTop: "25px",
  },
};

export default styles;