import { Box, Container, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import getHelpLeftImage from "../../../../assets/GetHelp/getHelpLeftImage.png";
import getHelpRightImage from "../../../../assets/GetHelp/getHelpRightImage.png";

import styles from "./style";

const GetHelp = () => {
  return (
    <Box sx={styles.container}>
      <Container maxWidth="xl" sx={styles.containerBox}>
        <Box sx={styles.box}>
          <Box sx={styles.paddingBox}>
            <Typography variant="h3" sx={styles.title}>
              Need more information?
            </Typography>
            <Typography variant="h3" sx={styles.subtitle}>
              We are here to help and answer all of your questions
            </Typography>
            <NavLink
              to="/contact"
              style={{
                position: "relative",
                zIndex: "99",
              }}
            >
              <Button sx={styles.button}>Get Help</Button>
            </NavLink>
          </Box>
          <Box
            component="img"
            sx={styles.imageRight}
            src={getHelpLeftImage}
            alt="getHelpLeftImage"
          />
          <Box
            component="img"
            sx={styles.imageLeft}
            src={getHelpRightImage}
            alt="getHelpRightImage"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default GetHelp;
