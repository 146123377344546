import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import successPopupImage from "../../assets/common/successPopupImage.png";

interface successModalProps {
  content: string;
  onClose: () => void;
  onSuccess: () => void;
}
const SuccessModal: React.FC<successModalProps> = ({
  content,
  onClose,
  onSuccess,
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      sx={{
        "& .MuiDialog-paper": {
          width: {
            xs: "90%",
            sm: "70%",
            md: "400px",
          },
          borderRadius: "40px",
          maxWidth: "90%",
        },
      }}
    >
      <Box sx={{ position: "relative", margin: "auto" }}>
        <img src={successPopupImage} alt="Sucess Image" />
      </Box>

      <DialogTitle
        sx={{
          m: 0,
          fontFamily: "Montserrat, sans- serif",
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "700",
        }}
        id="customized-dialog-title"
      >
        {content}
      </DialogTitle>

      <DialogContent
        sx={{
          padding: {
            lg: "30px 90px 40px",
            xs: "0 0 20px 0",
          },
          "@media (orientation: landscape) and (max-width: 999px)": {
              overflowY:"unset",
            }
        }}
      >
        <Box
          sx={{
            padding: "0 20px",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              sx={{
                background: "#06038D",
                padding: "13px 60px",
                borderRadius: "40px",
                fontSize: {
                  lg: "18px",
                  xs: "16px",
                },
                color: "#fff",
                fontWeight: "500",
                textDecoration: "none",
                fontFamily: "Montserrat, sans-serif",
                textTransform: "capitalize",
              }}
              onClick={onSuccess}
            >
              Done
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
