import { Box } from '@mui/material';
import React from 'react';

interface RepeatDaysProps {
    onDayToggle: (day: string) => void;
    selectedDays: string[];
}

const RepeatDays: React.FC<RepeatDaysProps> = ({ onDayToggle, selectedDays }) => {
    // Array of objects containing day initials and full day names
    const days = [
        { initial: 'M', name: 'monday' },
        { initial: 'T', name: 'tuesday' },
        { initial: 'W', name: 'wednesday' },
        { initial: 'T', name: 'thursday' },
        { initial: 'F', name: 'friday' },
        { initial: 'S', name: 'saturday' },
        { initial: 'S', name: 'sunday' },
    ];

    return (
        <Box sx={{
            display: 'flex', gap: '10px', justifyContent: {
                sm: "space-between",
                xs: "flex-start"
            }, paddingBottom: {
                xs: "10px",
                sm: "0"
            },
            flexWrap: {
                sm: "nowrap",
                xs: "wrap"
            }
        }}>
            {days.map((day, index) => (
                <Box
                    key={index}
                    onClick={() => onDayToggle(day.name)}
                    sx={{
                        backgroundColor: selectedDays.includes(day.name) ? '#06038D' : 'white',
                        color: selectedDays.includes(day.name) ? 'white' : 'black',
                        border: selectedDays.includes(day.name) ? '1px solid #06038D' : '1px solid rgba(17, 32, 71, 0.7)',
                        height: {
                            sm: '40px',
                            xs: "30px"
                        },
                        width: {
                            sm: '40px',
                            xs: "30px"
                        },
                        minHeight: {
                            sm: '40px',
                            xs: "30px"
                        },
                        minWidth: {
                            sm: '40px',
                            xs: "30px"
                        },
                        fontSize: {
                            sm: "16px",
                            xs: "14px"
                        },
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                >
                    {day.initial}
                </Box>
            ))}
        </Box>
    );
};

export default RepeatDays;
