import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileWrapper from "../ProfileWrapper/ProfileWrapper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box } from "@mui/material";
import CustomTextField from "../../../components/FormElements/CustomTextField";
import { routePaths } from "../../../Router/routePaths";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/commonSlice";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    fullName: "",
    phone: "",
    email: "",
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Username is required."),
    phone: Yup.string().required("Password is required."),
    email: Yup.string().required("Email is required."),
  });

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= 2 && e.target.value.length < 16) {
      const rawValue = e.target.value.slice(3).replace(/\D/g, "");
      formik.setFieldValue("phone", rawValue);
      e.target.value = rawValue;
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (formik.dirty) {
          const payload = {
            fullName: formik.values.fullName,
            phoneNumber: formik.values.phone.includes("+1")
              ? formik.values.phone
              : `+1${formik.values.phone}`,
          };
          await updateUserProfile(payload);
          dispatch(setUserData(payload));
        }
        navigate(routePaths.uploadProfile);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getUserProfile();
      dispatch(setUserData(res.data));
      formik.setValues({
        fullName: res.data.fullName || "",
        phone: res.data.phoneNumber || "",
        email: res.data.email || "",
      });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <ProfileWrapper
      title="Personal Details"
      btnDisabled={false}
      onSubmit={formik.handleSubmit}
      stepperCount={1}
    >
      <Box sx={{ height: "auto", width: { xs: "100%", md: "60%" } }}>
        <form className="personalDetailsForm">
          <CustomTextField
            label="Full Name"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter your full name"
            type={"text"}
            name="fullName"
            error={Boolean(formik.errors.fullName && formik.touched.fullName)}
            errorMessage={formik.errors.fullName}
            disabled={true}
          />
          <CustomTextField
            label="Phone Number"
            value={formik.values.phone}
            onChange={handlePhoneChange}
            onBlur={formik.handleBlur}
            placeholder="Enter your phone number"
            type={"text"}
            name="phone"
            error={Boolean(formik.errors.phone && formik.touched.phone)}
            errorMessage={formik.errors.phone}
            disabled={true}
          />
          <CustomTextField
            label="Email Address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter your email"
            type={"text"}
            name="email"
            error={Boolean(formik.errors.email && formik.touched.email)}
            errorMessage={formik.errors.email}
            disabled={true}
          />
        </form>
      </Box>
    </ProfileWrapper>
  );
};

export default PersonalDetails;
