import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ProfileWrapper from '../ProfileWrapper/ProfileWrapper';
import { Box, Grid2, Typography } from '@mui/material';
import CustomTextField from '../../../components/FormElements/CustomTextField';
import { routePaths } from '../../../Router/routePaths';
import Search from '../../../assets/icons/Search.svg'
import { getServices } from '../../../network/services';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../../redux/slices/userSlice';
import TextError from '../../../components/TextError/TextError';
import { getUserProfile, updateUserProfile } from '../../../network/user';
import { setLoading } from '../../../redux/slices/commonSlice';
import { RootState } from '../../../redux/rootReducer';
import useDebounce from '../../../hooks/useDebounce';
import styles from '../AddOperatingHours/style';
interface Image {
    _id: string;
    name: string;
    displayImage: {
        url: string;
    };
}
const AddBusiness = () => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const [searchText, setSearchText] = useState("")
    const debouncedSearchText = useDebounce(searchText, 500)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const loader = useSelector((state: RootState) => state.common.loader)

    useEffect(() => {
        getProfileDetails()
    }, [])

    const getProfileDetails = async () => {
        try {
            dispatch(setLoading(true))
            const res = await getUserProfile()
            console.log(res.data)
            dispatch(setUserData(res.data))
            setSelectedItems(res?.data?.serviceIds || [])
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setLoading(false))
        }
    }

    useEffect(() => {
        fetchServicesList()
    }, [debouncedSearchText])

    const fetchServicesList = async () => {
        try {
            dispatch(setLoading(true))
            const res = await getServices(searchText)
            setImages(res?.data)
        } catch (err) {
            console.log(err)
        } finally {
            dispatch(setLoading(false))
        }
    }

    const selectServiceHandler = (image: Image) => {
        if (selectedItems.includes(image._id)) {
            setSelectedItems(selectedItems.filter(item => item !== image._id));
        } else {
            setSelectedItems([...selectedItems, image._id]);
        }
    };
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
    }

    const addBusinessHandler = async () => {
        try {
            const payload = { serviceIds: selectedItems }
            await updateUserProfile(payload)
            dispatch(setUserData(payload))
            navigate(routePaths.selectServiceCategory)
        } catch (err) {
            console.log(err)
        }
    }
    console.log(selectedItems)

    return (
        <Box className="addBusinessForm">
            <ProfileWrapper title="Add Business" btnDisabled={selectedItems?.length === 0} onSubmit={addBusinessHandler} stepperCount={3}>
                <Box sx={styles.addBusinessFormWrapper} className="addBusinessFormWrapper">
                    <form >
                        <Box>
                            <CustomTextField
                                value={searchText}
                                onChange={handleSearch}
                                placeholder="Search services"
                                type={"text"}
                                name="fullName"
                                endAdornment={Search}
                                profile={true}
                                allowOnlyAlphabets={true}
                                maxChar={32}
                            />
                        </Box>
                    </form>

                    <Box sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        margin: {
                            xl: "15px 0 0 0",
                            xs: "13px 14px 10px 14px"
                        },
                        "@media (orientation: landscape) and (max-width: 999px) and (max-width: 1024px)": {
                            padding: "10px",
                            margin: "0"
                        },
                        maxHeight: '70vh',
                        marginBottom: '10px',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                        '-ms-overflow-style': 'none',
                    }}>
                        <Grid2 container spacing={2}>
                            {images.map((image) => (
                                <Grid2 size={{ xs: 12, sm: 4 }} key={image._id}>
                                    <Box sx={{
                                        border: `${selectedItems.includes(image?._id) ? '2px solid #1CB469' : '1px solid #06038D4D'}`,
                                        boxShadow: `${selectedItems.includes(image?._id) ? '0px 4px 36px 0px #00000029' : ''}`,
                                        padding: '8px',
                                        borderRadius: "20px",
                                        height: "90%",
                                        fontWeight: `${selectedItems.includes(image?._id) ? '600' : ''}`,
                                        textAlign: 'center',
                                        cursor: 'pointer',

                                    }}
                                        onClick={() => selectServiceHandler(image)}
                                    >
                                        <Box component="img" sx={{
                                            width: '100%',
                                            height: {
                                                xs: '150px',
                                                sm: '100px'
                                            },
                                            borderRadius: '10px',
                                            objectFit: 'cover',
                                        }}

                                            src={image.displayImage.url}
                                            alt={image?._id}
                                        >

                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    xl: '14px',
                                                    xs: "12px"
                                                },
                                                wordBreak: "break-word",
                                                fontFamily: 'Montserrat, sans-serif',
                                                color: `${selectedItems.includes(image?._id) ? '#06038D' : '#0D0639'}`,
                                                fontWeight: 600,
                                                marginTop: "3px"
                                            }}
                                        >
                                            {image?.name}
                                        </Typography>
                                    </Box>
                                </Grid2>
                            ))}
                            {images?.length === 0 && !loader && searchText && <TextError msg="No items matches your search." />}
                        </Grid2>
                    </Box>
                </Box>
            </ProfileWrapper>
        </Box>
    )
}

export default AddBusiness;