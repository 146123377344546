import { createContext, useState, useMemo } from "react";

interface SearchProviderProps {
  children: React.ReactNode;
}

interface Service {
  _id: string;
  name: string;
}

interface SearchContextType {
  filters: Filters;
  data: Data;
  updateFilter: (field: keyof Filters, value: any) => void;
  updateData: (field: keyof Data, value: any) => void;
}

interface SearchProviderProps {
  children: React.ReactNode;
}

interface Service {
  _id: string;
  name: string;
}

interface Filters {
  selectedCategory: string;
  selectedService: Service | null;
  selectedSubCategories: any[];
  zipCode: string;
  selectedCategoryId: string;
  searchedAddress: string;
  location: string;
}

interface Data {
  services: any[];
  category: any[];
  subCategory: any[];
  serviceProviders: any[];
  totalRecordsCount: number;
  page: number;
  totalPages: number;
  isSearchClicked: boolean;
}

interface SearchContextType {
  filters: Filters;
  data: Data;
  updateFilter: (field: keyof Filters, value: any) => void;
  updateData: (field: keyof Data, value: any) => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<Filters>({
    selectedCategory: "",
    selectedService: null,
    selectedSubCategories: [],
    zipCode: "",
    selectedCategoryId: "",
    searchedAddress: "",
    location: "",
  });
  const [data, setData] = useState<Data>({
    services: [],
    category: [],
    subCategory: [],
    serviceProviders: [],
    totalRecordsCount: 0,
    page: 1,
    totalPages: 0,
    isSearchClicked: false,
  });

  const updateFilter = (field: keyof Filters, value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const updateData = (field: keyof Data, value: any[]) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const value = useMemo(
    () => ({ filters, data, updateFilter, updateData }),
    [filters, data]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
