import {
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

import Footer from "../../../components/LandingPage/Consumer/Footer/footer";
import Header from "../../../components/LandingPage/Consumer/Header/header";

import { useNavigate } from "react-router-dom";
import { useEffect, useReducer, ChangeEvent, useState } from "react";
import { submitGlobalInquiry } from "../../../network/services";
import styles from "./style";
import { formatInput, validateField } from "../../../utils/validationUtils";
import SuccessForm from "../../../components/SucessForm";
import astrikIcon from "../../../assets/common/astrikIcon.svg";
import backIcon from "../../../assets/common/backBlueIcon.svg";
import contactImage from "../../../assets/Contact/contactImage.jpg";

interface State {
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  subject: string;
  message: string;
  disabled: boolean;
  errors: {
    phoneNumber: string;
    email: string;
    fullName: string;
    zipCode: string;
    subject: string;
    message: string;
  };
}
const initialState: State = {
  fullName: "",
  phoneNumber: "",
  email: "",
  zipCode: "",
  subject: "",
  message: "",
  disabled: true,
  errors: {
    phoneNumber: "",
    email: "",
    fullName: "",
    zipCode: "",
    subject: "",
    message: "",
  },
};

type Action =
  | { type: "SET_FIELD"; payload: { field: string; value: string | boolean } }
  | { type: "SET_ERROR"; payload: { field: string; message: string } }
  | { type: "CLEAR_ERRORS" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.payload.field]: action.payload.value };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {
          phoneNumber: "",
          email: "",
          fullName: "",
          zipCode: "",
          subject: "",
          message: "",
        },
      };
    default:
      return state;
  }
};

const getSx = (val: string | null) => {
  return {
    borderColor: !!val ? "rgba(255, 0, 0, 1)" : undefined,
    "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
  };
};

const Contact = () => {
  let navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const hasErrors = Object.values(state.errors).some((error) => error !== "");
    if (
      !hasErrors &&
      state.fullName !== "" &&
      state.phoneNumber !== "" &&
      state.email !== "" &&
      state.zipCode !== "" &&
      state.message !== "" &&
      state.subject !== ""
    ) {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: false },
      });
    } else {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: true },
      });
    }
  }, [state.errors]);

  const handleSubmit = async () => {
    setIsLoading(true);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    const response = await submitGlobalInquiry({
      fullName: state.fullName,
      phoneNumber: state.phoneNumber,
      email: state.email,
      message: state.message,
      subject: state.subject,
      zipCode: state.zipCode,
    });
    if (response) {
      setIsLoading(false);
      setSubmitted(true);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name === "zipCode" || name === "fullName" || name === "phoneNumber") {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "subject") {
      value = formatInput(name, value, 100);
    }
    if (name === "message") {
      value = formatInput(name, value, 1000);
    }
    if (name === "phoneNumber") {
      if (value && !value.startsWith("+1 ")) {
        value = `+1 ${value.replace(/^\+?1?\s?/, "")}`;
      }
    }
    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
    const errorMessage = validateField(name, value);
    dispatch({
      type: "SET_ERROR",
      payload: { field: name, message: errorMessage },
    });
  };

  return (
    <>
      <Box sx={styles.background}>
        <Header />
        <Box component="img" src={contactImage} sx={styles.contactImage}></Box>
        <Box sx={styles.translateContainer}>
          <Container maxWidth="md">
            <Box>
              <Typography variant="h3" sx={styles.headerText}>
                Connect with us today
              </Typography>
              <Box sx={styles.formContainer}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    lg={8}
                    sx={{
                      margin: "auto",
                    }}
                  >
                    {!submitted ? (
                      <>
                        <Box sx={styles.backButton}>
                          <Box onClick={() => navigate(-1)}>
                            <img src={backIcon} alt="backArrowIcon" />
                          </Box>
                        </Box>
                        <Box>
                          <Box className="formControl">
                            <InputLabel className="inputLable">
                              Full Name
                              <img
                                src={astrikIcon}
                                className="astrikIcon"
                                alt="astrikIcon"
                              />
                            </InputLabel>
                            <TextField
                              placeholder="Add First & Last Name"
                              name="fullName"
                              value={state.fullName}
                              onChange={handleInputChange}
                              error={!!state.errors.fullName}
                              helperText={state.errors.fullName}
                              sx={getSx(state.errors.fullName)}
                            />
                          </Box>
                          <Box>
                            <Grid container>
                              <Grid item xs={12} lg={6}>
                                <Box
                                  sx={{ marginRight: { lg: "5px", xs: "0" } }}
                                  className="formControl"
                                >
                                  <InputLabel className="inputLable">
                                    Email Address
                                    <img
                                      src={astrikIcon}
                                      className="astrikIcon"
                                      alt="astrikIcon"
                                    />
                                  </InputLabel>
                                  <TextField
                                    placeholder="Email Address"
                                    value={state.email}
                                    name="email"
                                    onChange={handleInputChange}
                                    error={!!state.errors.email}
                                    helperText={state.errors.email}
                                    sx={getSx(state.errors.email)}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                <Box
                                  sx={{ marginLeft: { lg: "5px", xs: "0" } }}
                                  className="formControl"
                                >
                                  <InputLabel className="inputLable">
                                    Phone Number
                                    <img
                                      src={astrikIcon}
                                      className="astrikIcon"
                                      alt="astrikIcon"
                                    />
                                  </InputLabel>
                                  <TextField
                                    placeholder="Phone Number"
                                    name="phoneNumber"
                                    value={state.phoneNumber}
                                    onChange={handleInputChange}
                                    error={!!state.errors.phoneNumber}
                                    helperText={state.errors.phoneNumber}
                                    sx={getSx(state.errors.phoneNumber)}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box className="formControl">
                            <InputLabel className="inputLable">
                              Zip Code
                              <img
                                src={astrikIcon}
                                className="astrikIcon"
                                alt="astrikIcon"
                              />
                            </InputLabel>
                            <TextField
                              type="text"
                              placeholder="Add Zip Code"
                              name="zipCode"
                              value={state.zipCode}
                              onChange={handleInputChange}
                              error={!!state.errors.zipCode}
                              helperText={state.errors.zipCode}
                              sx={getSx(state.errors.zipCode)}
                            />
                          </Box>
                          <Box className="formControl">
                            <InputLabel className="inputLable">
                              Subject
                              <img
                                src={astrikIcon}
                                className="astrikIcon"
                                alt="astrikIcon"
                              />
                            </InputLabel>
                            <TextField
                              type="text"
                              name="subject"
                              placeholder="Add Subject"
                              value={state.subject}
                              onChange={handleInputChange}
                              error={!!state.errors.subject}
                              helperText={state.errors.subject}
                              sx={getSx(state.errors.subject)}
                            />
                          </Box>
                          <Box className="formControl">
                            <InputLabel className="inputLable">
                              Message
                              <img
                                src={astrikIcon}
                                className="astrikIcon"
                                alt="astrikIcon"
                              />
                            </InputLabel>
                            <TextField
                              placeholder="Add Message"
                              minRows={1}
                              maxRows={4}
                              multiline
                              name="message"
                              value={state.message}
                              onChange={handleInputChange}
                              error={!!state.errors.message}
                              helperText={state.errors.message}
                              sx={getSx(state.errors.message)}
                            />
                          </Box>
                          <Box
                            sx={{
                              margin: "auto",
                              textAlign: "center",
                              marginTop: "30px",
                            }}
                          >
                            {isLoading ? (
                              <Button sx={styles.submitButton}>
                                <CircularProgress size={40} />
                              </Button>
                            ) : (
                              <Button
                                sx={styles.submitButton}
                                onClick={handleSubmit}
                                disabled={state.disabled}
                              >
                                Submit
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <SuccessForm />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
        {/* <GetHelp /> */}
        <Footer />
      </Box>
    </>
  );
};

export default Contact;
