import { Dayjs } from "dayjs";

// Get days from the previous month to display in the current view
export const getPreviousMonthDays = (month: Dayjs) => {
    const firstDayOfMonth = month.startOf('month').day();
    const previousMonth = month.subtract(1, 'month');
    const daysInPreviousMonth = previousMonth.daysInMonth();

    const previousMonthDays: Dayjs[] = [];
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
        previousMonthDays.push(previousMonth.date(daysInPreviousMonth - i));
    }
    return previousMonthDays;
};

// Get days from the next month to display in the current view
export const getNextMonthDays = (month: Dayjs) => {
    const lastDayOfMonth = month.endOf('month').day();
    const nextMonth = month.add(1, 'month');
    const nextMonthDays: Dayjs[] = [];

    for (let i = 1; i <= 6 - lastDayOfMonth; i++) {
        nextMonthDays.push(nextMonth.date(i));
    }
    return nextMonthDays;
};

// Get all days in the current month (along with previous and next month days)
export const getDaysInMonth = (month: Dayjs) => {
    const daysInMonth: Dayjs[] = [];
    const startOfMonth = month.startOf('month');
    const days = month.daysInMonth();

    for (let i = 0; i < days; i++) {
        daysInMonth.push(startOfMonth.add(i, 'day'));
    }

    // return [...getPreviousMonthDays(month), ...daysInMonth, ...getNextMonthDays(month)];
    return daysInMonth
};

// export const scrollDays = (
//     direction: 'left' | 'right',
//     daysContainerRef: React.RefObject<HTMLDivElement>,
//     daysInMonth: Dayjs[]
// ) => {
//     if (daysContainerRef.current) {
//         const container = daysContainerRef.current;
//         const scrollAmount = 300; // Adjust this to match your day width
//         const currentScrollPosition = container.scrollLeft;
//         const maxScrollPosition = container.scrollWidth - container.clientWidth;

//         if (direction === 'left') {
//             if (currentScrollPosition > 0) {
//                 container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
//             }
//         } else if (direction === 'right') {
//             const lastDayRef = container.querySelector(`[data-day="${daysInMonth.length}"]`);
//             if (lastDayRef) {
//                 const lastDayPosition = (lastDayRef as HTMLElement).offsetLeft;

//                 // Ensure no extra space after the last day
//                 if (currentScrollPosition + container.clientWidth < maxScrollPosition) {
//                     container.scrollBy({
//                         left: Math.min(scrollAmount, maxScrollPosition - currentScrollPosition),
//                         behavior: 'smooth',
//                     });
//                 }
//             }
//         }
//     }
// };
export const scrollDays = (
    direction: 'left' | 'right',
    daysContainerRef: React.RefObject<HTMLDivElement>,
    daysInMonth: Dayjs[]
) => {
    if (daysContainerRef.current) {
        const container = daysContainerRef.current;
        const scrollAmount = 300; // Adjust this based on your container width
        const currentScrollPosition = container.scrollLeft;

        if (direction === 'left') {
            // Scroll to the left if possible
            if (currentScrollPosition > 0) {
                container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            }
        } else if (direction === 'right') {
            // Ensure no extra space after the last day
            const lastDayRef = container.querySelector(`[data-day="${daysInMonth.length}"]`);
            if (lastDayRef) {
                const lastDayPosition = (lastDayRef as HTMLElement).offsetLeft;

                if (currentScrollPosition + container.clientWidth < lastDayPosition + lastDayRef.clientWidth) {
                    container.scrollBy({
                        left: Math.min(scrollAmount, lastDayPosition + lastDayRef.clientWidth - (currentScrollPosition + container.clientWidth)),
                        behavior: 'smooth',
                    });
                }
            }
        }
    }
};



//  Helper function to get the weekday name from a date
export const getDayOfWeek = (dateString: string) => {
    const date = new Date(dateString);
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[date.getDay()];
};

export const dayMapping: Record<'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday', number> = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6
};

export const keyToDayMapping = {

    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday'

}


