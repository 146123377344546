import { useState, useEffect, ChangeEvent, useReducer } from "react";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  Link,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import Footer from "../LandingPage/Consumer/Footer/footer";
import Header from "../LandingPage/Consumer/Header/header";
import GetHelp from "../LandingPage/ServiceProvider/GetHelp/GetHelp";
import {
  getServiceProvider,
  submitServiceInquiry,
} from "../../network/services";
import { formatInput, validateField } from "../../utils/validationUtils";
import "./index.css";
import styles from "./style";
import astrikIcon from "../../assets/icons/astrikIcon.svg";
import backIcon from "../../assets/common/backBlueIcon.svg";
import breadCumsIcon from "../../assets/icons/breadCumsIcon.svg";
import closeIcon from "../../assets/common/closeIcon.svg";
import successPopupImage from "../../assets/common/successPopupImage.png";
import { setLoading } from "../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import dummyImage from "../../assets/common/dummyImage.jpg";
import { sortDisplaySlotsByTime } from "../../utils/interfaces";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

interface State {
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  message: string;
  errors: {
    phoneNumber: string;
    email: string;
    fullName: string;
    zipCode: string;
    message: string;
  };
}
const initialState: State = {
  fullName: "",
  phoneNumber: "",
  email: "",
  zipCode: "",
  message: "",
  errors: {
    phoneNumber: "",
    email: "",
    fullName: "",
    zipCode: "",
    message: "",
  },
};

type Action =
  | { type: "SET_FIELD"; payload: { field: string; value: string | boolean } }
  | { type: "SET_ERROR"; payload: { field: string; message: string } }
  | { type: "CLEAR_ERRORS" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.payload.field]: action.payload.value };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {
          phoneNumber: "",
          email: "",
          fullName: "",
          zipCode: "",
          message: "",
        },
      };
    default:
      return state;
  }
};

const getSx = (val: string | null) => {
  return {
    borderColor: !!val ? "rgba(255, 0, 0, 1)" : undefined,
    "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
  };
};

const DetailsServiceProvider = () => {
  const [inquiryFormModal, setInquiryFormModal] = useState(false);
  const [inquiryFormSucessModal, setInquiryFormSucessModal] = useState(false);
  const [inquirySubmitted, setInquirySubmitted] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get("categoryId");
  const zipCode = queryParams.get("zipCode");
  const [serviceProviderDetails, setServiceProviderDetails] = useState<any>({});
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name === "fullName" || name === "phoneNumber") {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "message") {
      value = formatInput(name, value, 1000);
    }
    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
    if (name === "phoneNumber") {
      if (value && !value.startsWith("+1 ")) {
        value = `+1 ${value.replace(/^\+?1?\s?/, "")}`;
      }
    }

    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
    const errorMessage = validateField(name, value);
    dispatch({
      type: "SET_ERROR",
      payload: { field: name, message: errorMessage },
    });
  };

  const handleClickInquiryFormModalOpen = () => {
    setInquiryFormModal(true);
  };
  const handleClickInquiryFormModalClose = () => {
    setInquiryFormModal(false);
  };

  const handleClickInquiryFormSucessModalOpen = async () => {
    setIsLoading(true);
    try {
      const response = await submitServiceInquiry({
        fullName: state.fullName,
        phoneNumber: state.phoneNumber,
        email: state.email,
        message: state.message,
        zipCode: zipCode,
        userId: id,
        categoryIds: [categoryId],
      });
      if (response) {
        setIsLoading(false);
        setInquirySubmitted(true);
        setInquiryFormModal(false);
        setInquiryFormSucessModal(true);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (!navigator.onLine) {
        console.error("No internet connection. Please check your network.");
        alert("You are offline. Please check your internet connection.");
      } else if (error.response) {
        console.error("Server responded with an error:", error?.response?.data);
      } else {
        console.error("An error occurred:", error?.message);
      }
    }
  };
  const handleClickInquiryFormSucessModalClose = () => {
    setInquiryFormSucessModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getParticularServiceProvider = async (id: string) => {
    const response = await getServiceProvider(id);
    const data = await response.data;
    setServiceProviderDetails(data);
    reduxDispatch(setLoading(false));
  };

  useEffect(() => {
    if (id) {
      reduxDispatch(setLoading(true));
      getParticularServiceProvider(id);
    }
  }, [id]);

  const getSlotsWithTimings = (day: string): any => {
    const data =
      serviceProviderDetails?.serviceTiming &&
      serviceProviderDetails?.serviceTiming[day];
    if (!data) return null;
    const today = dayjs().format("YYYY-MM-DD");
    
    const filteredSlots = Array.isArray(data.slots)
      ? data.slots.filter((slot: any, index: number, self: any[]) => {
        const isDuplicate = self.some((s, idx) =>
          idx < index && s.startTime === slot.startTime && s.endTime === slot.endTime
        );

        if (isDuplicate) {
          return false;
        }

        if (slot.startTime && slot.endTime && !slot?.monthDate) {
          return true;
        }

        // Check slots with monthDate greater than or equal to today
        if (slot.startTime && slot.endTime && slot?.monthDate && dayjs(slot.monthDate).isSameOrAfter(today)) {
          return true;
        }

        return false;
      })
      : [];

    const sortedSlots = sortDisplaySlotsByTime(filteredSlots);
    if (filteredSlots?.length === 0) {
      return null;
    }

    return {
      ...data,
      slots: sortedSlots,
    };
  };
  const noSlotsAvailable = weekDays.every((day) => !getSlotsWithTimings(day));
  return (
    <>
      <Header />
      <Box sx={styles.background}>
        <Container
          maxWidth="xl"
          sx={{
            padding: {
              xl: "0 24px 80px",
              lg: "0 60px 80px",
              xs: "0 16px 0",
            },
          }}
        >
          <Box sx={styles.contactWrappercontainer}>
            <Box
              component="img"
              src={backIcon}
              alt="BackArrow"
              sx={{
                width: {
                  xl: "auto",
                  xs: "30px",
                },
              }}
              onClick={() => navigate("/expertServiceProvider")}
            ></Box>
            <List sx={styles.list}>
              <ListItem
                sx={{
                  display: "flex",
                  alignItems: {
                    sm: "center",
                    xs: "flex-start",
                  },
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  padding: {
                    xs: "8px 0 8px 10px",
                    xl: "8px 10px",
                    lg: "8px 10px",
                  },
                }}
              >
                <ListItemText>
                  <NavLink to="/expertServiceProvider">
                    <Link sx={styles.link}>
                      Service Providers{" "}
                      <Box
                        sx={{
                          width: {
                            xl: "auto",
                            xs: "8px",
                          },
                        }}
                        component="img"
                        className="breadCums-icon"
                        src={breadCumsIcon}
                        alt="BreadCums"
                      ></Box>
                    </Link>
                  </NavLink>
                </ListItemText>
                <ListItemText>
                  <Link sx={styles.linkActive}>
                    {serviceProviderDetails?.fullName}
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box sx={styles.card}>
            <Box sx={styles.cardHeader}>
              <Box sx={styles.profileContainer}>
                <Box
                  sx={styles.profileImage}
                  component="img"
                  src={
                    serviceProviderDetails.profileImage?.url
                      ? serviceProviderDetails.profileImage?.url
                      : dummyImage
                  }
                  alt="User Image"
                ></Box>
                <Box sx={styles.profileDetails}>
                  <Typography variant="h4" sx={styles.fullName}>
                    {serviceProviderDetails?.fullName}
                  </Typography>
                  <Box sx={styles.statusDot}></Box>
                  <Typography variant="h4" sx={styles.businessTitle}>
                    {serviceProviderDetails?.businessTitle}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {inquirySubmitted ? (
                  <Button sx={styles.inquirySentbutton}>Inquiry Sent</Button>
                ) : (
                  <Button
                    sx={styles.button}
                    onClick={handleClickInquiryFormModalOpen}
                  >
                    Send Inquiry
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={styles.operatingHours}>
              <Typography variant="body1" sx={styles.operatingHoursTitle}>
                Operating Hours:
              </Typography>
              <Typography variant="body1" sx={styles.operatingHoursText}>
                <div>
                  {noSlotsAvailable ? (
                    <p>No available slots</p> // Show this message if no valid slots are found
                  ) : (
                    weekDays.map((day) => (
                      <Box sx={{ marginBottom: "5px" }} key={day}>
                        {getSlotsWithTimings(day) && (
                          <>
                            <span>{capitalizeFirstLetter(day)}</span>
                            <br />
                            <Box sx={styles.slotContainer}>
                              {getSlotsWithTimings(day)?.slots?.map(
                                (slot: any, index: number) => (
                                  <span key={slot.startTime}>
                                    Slot {index + 1} : {slot.startTime} -{" "}
                                    {slot.endTime}
                                  </span>
                                )
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    ))
                  )}
                </div>
              </Typography>
            </Box>

            <Box sx={styles.servicesOffered}>
              <Typography variant="h4" sx={styles.servicesOfferedTitle}>
                Service Categories Offered:
              </Typography>
              <Box sx={styles.servicesList}>
                {serviceProviderDetails?.categoryDetails?.map(
                  (category: any, index: number) => (
                    <Box key={category.id || index} sx={styles.serviceCategory}>
                      <Box sx={styles.serviceCategoryIcon}>
                        <img
                          src={category.displayImage.url}
                          alt={category.title}
                          className="categoryTitle"
                        />
                      </Box>
                      {category.categoryName}
                    </Box>
                  )
                )}
              </Box>
            </Box>

            <Box sx={styles.servicesImages}>
              <Typography variant="h4" sx={styles.servicesImagesTitle}>
                Services Images:
              </Typography>
              <Grid container spacing={3}>
                {serviceProviderDetails?.businessImages?.map(
                  (businessImage: any) => (
                    <Grid item xs={12} sm={6} md={4} key={businessImage?.url}>
                      <Box
                        sx={styles.serviceImage}
                        src={businessImage?.url}
                        alt="Business Image"
                        component="img"
                      ></Box>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>

            <Box sx={styles.businessDescription}>
              <Typography variant="h4" sx={styles.businessDescriptionTitle}>
                {serviceProviderDetails?.businessTitle}
              </Typography>
              <Typography variant="body1" sx={styles.businessDescriptionText}>
                {serviceProviderDetails?.description}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      <GetHelp />
      <Footer />

      {/* Modal Enquery Form */}
      <Dialog
        onClose={handleClickInquiryFormModalClose}
        aria-labelledby="customized-dialog-title"
        open={inquiryFormModal}
        PaperProps={{ sx: styles.dialogPaper }}
      >
        <DialogTitle sx={styles.dialogTitle} id="customized-dialog-title">
          Inquiry Form
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickInquiryFormModalClose}
          sx={styles.closeButton}
        >
          <img src={closeIcon} alt="closeIcon" />
        </IconButton>
        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.formBox}>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Full Name{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add First & Last Name"
                name="fullName"
                value={state.fullName}
                onChange={handleInputChange}
                error={!!state.errors.fullName}
                helperText={state.errors.fullName}
                sx={getSx(state.errors.fullName)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Phone Number{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Number"
                name="phoneNumber"
                value={state.phoneNumber}
                onChange={handleInputChange}
                error={!!state.errors.phoneNumber}
                helperText={state.errors.phoneNumber}
                sx={getSx(state.errors.phoneNumber)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Email Address{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Email"
                name="email"
                value={state.email}
                onChange={handleInputChange}
                error={!!state.errors.email}
                helperText={state.errors.email}
                sx={getSx(state.errors.email)}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Zip Code{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Zip code"
                name="zipCode"
                value={zipCode}
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
            <Box className="formControl">
              <InputLabel className="inputLable">
                Message{" "}
                <img src={astrikIcon} className="astrikIcon" alt="astrikIcon" />
              </InputLabel>
              <TextField
                placeholder="Add Message"
                name="message"
                value={state.message}
                onChange={handleInputChange}
                minRows={1}
                maxRows={4}
                multiline
                error={!!state.errors.message}
                helperText={state.errors.message}
                sx={getSx(state.errors.message)}
              />
            </Box>
            <Box sx={styles.submitButtonContainer}>
              {isLoading ? (
                <Button sx={styles.submitButton}>
                  <CircularProgress size={40} />
                </Button>
              ) : (
                <Button
                  disabled={
                    !(
                      state.fullName &&
                      state.phoneNumber &&
                      state.email &&
                      state.message &&
                      !state.errors.fullName &&
                      !state.errors.phoneNumber &&
                      !state.errors.email &&
                      !state.errors.message
                    )
                  }
                  sx={styles.submitButton}
                  onClick={handleClickInquiryFormSucessModalOpen}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Modal Inquiry Submitted Successfully */}
      <Dialog
        onClose={handleClickInquiryFormSucessModalClose}
        aria-labelledby="customized-dialog-title"
        open={inquiryFormSucessModal}
        PaperProps={{ sx: styles.dialogPaperSuccess }}
      >
        <DialogTitle
          sx={styles.dialogTitleSuccess}
          id="customized-dialog-title"
        >
          <img src={successPopupImage} alt="Sucess Image" />
          <Box>Inquiry Submitted Successfully</Box>
        </DialogTitle>

        <DialogContent sx={styles.dialogContentSuccess}>
          <Box sx={styles.formBoxSuccess}>
            <Box sx={styles.submitButtonContainerSuccess}>
              <Button
                sx={styles.submitButtonSuccess}
                onClick={handleClickInquiryFormSucessModalClose}
              >
                Done
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailsServiceProvider;
