import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
    externalContainer: {
        marginTop: {
            lg: "0",
            xs: "5px"
        },
        height: 'auto',
        width: {
            md: '60%'
        },
        display: 'flex',
        justifyContent: 'center',
        paddingTop: {
            xl: "0",
            xs: "10px"
        }
    },
    gridContainer: {
        width: '100%',
        height: 'auto',
        overflow: 'scroll',
        display: 'flex',
        justifyContent: 'center',
        scrollbarWidth: 'none',
        paddingTop: {
            xs: "5px",
            sm: "0"
        }
    },
    paddingZero: {
        position: 'relative',
        padding: 0
    },
    closeIconButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        cursor: 'pointer',
    },
    businessImages: {
        aspectRatio: '1 / 1',
        height: {
            xs: "155px",
            xl: "188px"
        },
        // overflow: 'hidden',
        background: 'lightGray',
        borderRadius: '10px',
        "@media (orientation: landscape) and (max-width: 999px)": {
            marginBottom: "10px"
        }
    },
    uploadPlaceholderImage: {
        // width: '150px',
        height: {
            xs: "155px",
            xl: "188px"
        },
        paddingLeft: {
            sm: "0",
            xs: "16px"
        },
        // height:"100%",
        // objectFit: 'fill',
        borderRadius: '10px'
    },
    selectedPreview: {
        background: 'lightGray',
        width: {
            xl: "250px",
            xs: "170px"
        },
        height: {
            xl: "250px",
            xs: "170px"
        },
        borderRadius: "50%",
        "@media (orientation: landscape) and (max-width: 999px)": {
            marginBottom: "10px"
        }
    },
    cameraImg: {
        position: "absolute",
        top: {
            xl: "170px",
            xs: "120px",
        },
        right: "-10px",
        width: {
            xs: "50px",
            md: "60px",
            xl: "auto"
        },
        cursor: "pointer"
    },
    uploadIcon: {
        cursor: 'pointer',
        width: {
            xl: "250px",
            xs: "170px"
        },
        height: {
            xl: "250px",
            xs: "170px"
        },
        objectFit: "fill"
    }
};

export default styles;

