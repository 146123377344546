import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
  dashboardBox: {
    background: "#F5F5FA",
    width: "100%",
    padding: {
      xs: "10px 10px 0 10px",
      sm: "20px",
      xl: "30px",
    },
    "@media (orientation: landscape) and (max-width: 999px)": {
      padding: "20px 20px 0 20px"
    },
    borderRadius: {
      md: "30px 0 0 30px",
      xs: "0"
    },
    height: {
      xs: "auto",
      md: "auto"
    }
  },
  dashboardHeaderBox: {
    boxShadow:
      "0px 1.85px 3.15px 0px #00000002, 0px 8.15px 6.52px 0px #00000003",
    border: "1px solid #3F8CFF4D",
    padding: {
      xl: "20px",
      xs: "14px",
    },
    borderRadius: "16px",
    background: "#fff",
    marginBottom: {
      xs: "15px",
      xl: "30px",
    },
    marginTop: {
      md: "0",
      xs: "60px"
    }
  },
  dashboardTitle: {
    color: "#070477",
    borderLeft: "3px solid #EABD19",
    paddingLeft: "14px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    fontSize: {
      xl: "26px",
      lg: "20px",
      xs: "20px",
    },
  },
  card: {
    background: "#FFFFFF",
    borderRadius: "20px",
    height: "100%",
  },
  cardHeader: {
    padding: {
      sm: "20px",
      xs: "15px",
    },
    borderBottom: "1px solid #3F8CFF",
  },
  cardHeaderTitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: {
      xl: "row",
      xs: "column",
    },
  },
  userName: {
    color: "#0D0639",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "500",
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    wordBreak: "break-word",
    paddingRight: "10px",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden"
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: {
      xl: "0",
      xs: "14px",
    },
  },
  date: {
    color: "#1CB469",
    paddingLeft: "5px",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "500",
    fontSize: "12px",
    width: "max-content",
  },
  contactWrapper: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: {
      xl: "14px",
    },
    padding: "20px 0 0 0",
    flexDirection: "column"
    // flexDirection: {
    //   xl: "row",
    //   xs: "column"
    // }
  },
  callIcon: {
    background: "#E4EFFF",
    height: "30px",
    width: "30px",
    minWidth: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  callText: {
    color: "#000000",
    fontSize: {
      sm: "14px",
      xs: "12px"
    },
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    paddingLeft: "10px",
  },
  contactInnerWrapper: {
    display: "flex",
    alignItems: "center",
    paddingRight: "15px",
  },
  mailIconWrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: {
      xl: "0",
      xs: "14px",
    },
  },
  mailIcon: {
    background: "#EABD1933",
    height: "30px",
    width: "30px",
    minWidth: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  mailText: {
    color: "#000000",
    fontSize: {
      sm: "14px",
      xs: "12px"
    },
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "400",
    paddingLeft: "10px",
    wordBreak: "break-word",
  },
  mailIconImage: {
    padding: "3px 0 0 6px",
    cursor: "pointer",
  },
  sectionLabel: {
    color: "#000000B2",
    marginBottom: "10px",
    fontWeight: "400",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    textTransform: "uppercase",
    fontFamily: "Montserrat, sans-serif",
  },
  viewAllButton: {
    color: "#8989A3",
    marginBottom: "10px",
    fontWeight: "400",
    fontSize: {
      xl: "12px",
      xs: "10px",
    },
    cursor: "pointer",
    fontFamily: "Montserrat, sans-serif",
  },
  readMoreButton: {
    cursor: "pointer",
    color: "#06038D",
    fontWeight: 500,
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
  },
  flexWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  serviceContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    paddingBottom: "14px",
  },
  serviceBox: {
    background: "#FF711D",
    padding: "4px 14px 4px 4px",
    minWidth: "auto",
    borderRadius: "37px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    fontFamily: "Montserrat, sans-serif",
  },
  serviceIconBox: {
    background: "#FFF",
    height: {
      xl: "28px",
      xs: "24px",
    },
    width: {
      xl: "28px",
      xs: "24px",
    },
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    marginRight: {
      xl: "7px",
      xs: "4px",
    },
    justifyContent: "center",
    "& img": {
      borderRadius: "50%",
    },
  },
  serviceBoxYellow: {
    background: "#ECB900",
    padding: "4px 14px 4px 4px",
    minWidth: "auto",
    borderRadius: "37px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    fontFamily: "Montserrat, sans-serif",
  },
  descriptionTitle: {
    color: "#000000B2",
    marginBottom: "6px",
    fontWeight: "400",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    textTransform: "uppercase",
    fontFamily: "Montserrat, sans-serif",
  },
  modalDescriptionText: {
    color: "#000",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    lineHeight: {
      xl: "26px",
      xs: "24px",
    },
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    width: "100%",
    maxHeight: "120px",
    overflow: "auto",
    wordBreak: "break-word",
    paddingRight: "8px",
  },
  descriptionText: {
    color: "#000",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    lineHeight: {
      xl: "26px",
      xs: "24px",
    },
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    width: "100%",
    wordBreak: "break-word",
    // display: '-webkit-box',
    // WebkitBoxOrient: 'vertical',
    // overflow: 'hidden',
    // WebkitLineClamp: 2,
  },
  paginationContainer: {
    position: "relative",
    padding: {
      lg: "30px 0 40px",
      xs: "20px 0",
    },
    "@media (orientation: landscape) and (max-width: 999px)": {
      padding: "20px 0 0"
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiPaginationItem-text": {
      padding: "0",
      margin: "0",
      minWidth: "30px"
    },
    "& ul li:last-child": {
      position: {
        xs: "unset",
        md: "absolute",
      },
      right: "20px",
    },
    "& ul li:first-child": {
      position: {
        xs: "unset",
        md: "absolute",
      },
      left: "20px",
    },
  },
  paginationItem: {
    backgroundColor: "transparent !important",
    borderTop: "2px solid #06038D",
    marginBottom: "3px !important",
    fontWeight: 700,
    fontFamily: "Montserrat, sans-serif",
    color: "#000000",
    boxShadow: "none",
    borderRadius: "0",
  },
  paginationPrevious: {
    display: "flex",
    alignItems: "center",
  },
  paginationNext: {
    display: "flex",
    alignItems: "center",
  },
  paginationText: {
    color: "#00000080",
    fontSize: "18px",
    margin: "0 10px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    display: {
      sm: "block",
      xs: "none"
    }
  },
  dialogPaper: {
    width: {
      xs: "90%",
      sm: "70%",
      md: "680px",
    },
    borderRadius: "20px",
    margin: "auto",
    maxWidth: "90%",
  },
  closeButton: (theme: Theme) => ({
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
    "& img": {
      width: {
        sm: "auto",
        xs: "30px"
      }
    }
  }),
  dialogContent: {},
  dialogContentBox: {
    padding: {
      sm: "20px",
      xs: "15px 0",
    },
    borderBottom: "1px solid #0000001A",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  title: {
    color: "#0D0639",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    fontSize: {
      lg: "20px",
      xs: "18px",
    },
    wordBreak: "break-word"
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    paddingTop: {
      sm: "0",
      xs: "14px",
    },
  },
  calendarText: {
    color: "#1CB469",
    paddingLeft: "5px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    fontSize: "12px",
  },
  contactBox: {
    display: "flex",
    padding: "20px 0 0 0",
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  contactItem: {
    display: "flex",
    alignItems: "center",
    paddingRight: "15px",
  },
  contactIcon: {
    background: "#E4EFFF",
    height: "30px",
    width: "30px",
    minWidth: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  contactText: {
    color: "#000000",
    fontSize: "14px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "400",
    paddingLeft: "10px",
    wordBreak: "break-word"
  },
  copyIcon: {
    padding: "3px 0 0 6px",
    cursor: "pointer",
  },
  emailIcon: {
    background: "#EABD1933",
    height: "30px",
    width: "30px",
    minWidth: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  serviceCategoryBox: {
    padding: {
      sm: "20px",
      xs: "15px 0",
    },
  },
  serviceCategoryHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  serviceCategoryTitle: {
    color: "#000000B2",
    marginBottom: "10px",
    fontWeight: "400",
    fontSize: {
      xl: "14px",
      xs: "12px",
    },
    textTransform: "uppercase",
    fontFamily: "Montserrat, sans-serif",
  },
  viewAllText: {
    color: "#8989A3",
    marginBottom: "10px",
    fontWeight: "400",
    fontSize: "12px",
    cursor: "pointer",
    fontFamily: "Montserrat, sans-serif",
  },
  serviceCategoryList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    paddingBottom: "14px",
  },
  serviceCategoryItem: {
    background: "#ff711d",
    padding: "4px 10px 4px 4px",
    minWidth: {
      sm: "170px",
      xs: "90%",
    },
    borderRadius: "37px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Montserrat, sans-serif",
  },
  serviceCategoryIcon: {
    background: "#FFF",
    height: "28px",
    width: "28px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    marginRight: "7px",
    justifyContent: "center",
    "& img": {
      borderRadius: "50%",
    },
  },
  container: {
    display: "flex",
  },
  settingsPage: {
    background: "#F5F5FA",
    height: {
      md: "90vh",
      xs: "auto"
    },
    width: "100%",
    padding: {
      xs: "10px 10px 0 10px",
      lg: "30px 30px 0 30px",
    },
  },
  headerBox: {
    boxShadow:
      "0px 1.85px 3.15px 0px #00000002, 0px 8.15px 6.52px 0px #00000003",
    border: "1px solid #3F8CFF4D",
    padding: {
      xl: "20px",
      xs: "14px",
    },
    borderRadius: "16px",
    background: "#fff",
    marginBottom: {
      xs: "15px",
      xl: "30px",
    },
    marginTop: {
      md: "0",
      xs: "60px"
    },
    // position: "sticky",
    // top: "30px",
  },
  availabilityTitle: {
    color: "#070477",
    borderLeft: "3px solid #EABD19",
    paddingLeft: "14px",
    fontFamily: "Montserrat, sans- serif",
    fontWeight: "600",
    fontSize: {
      xl: "26px",
      lg: "20px",
      xs: "20px",
    },
  },
  noResultDataWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "60px",
    background: "#fff",
    borderRadius: "20px",
    padding: {
      md: "60px 40px 40px",
      xs: "50px 10px 0px"
    },
    boxShadow: {
      md: "0px 69px 42px 0px #C8C8C840",
      xs: "none"
    },
    flexDirection: "column",
    width: {
      xl: "50%",
      md: "60%",
      xs: "auto",
    },
    height: {
      md: "45vh",
      xs: "auto"
    },
    margin: {
      md: "20px auto",
      xs: "0 0 10px 0"
    },
  },
  noResultImage: {
    width: {
      xs: "200px",
    },
    marginBottom: "30px",
  },
  heading: {
    fontFamily: "Montserrat, sans-serif",
    color: "#0D0639",
    fontSize: {
      xl: "20px",
      xs: "18px",
    },
    fontWeight: "600",
    marginBottom: "20px",
  },
};

export default styles;
