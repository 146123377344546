import { Button } from '@mui/material'
import React from 'react'
interface BlueButtonProps {
    btnName: string;
    disabled: boolean;
    type?: "button" | "submit" | "reset";
    width?: string;
    onClick?: () => void;
    padding?: string;
}

const BlueButton: React.FC<BlueButtonProps> = ({ btnName, disabled, type = "button", width, onClick, padding }) => {
    return (
        <Button
            sx={{
                background: '#06038D',
                fontWeight: 600,
                fontSize: {
                    xl: "18px",
                    xs: "14px"
                },
                fontFamily: 'Montserrat, sans- serif',
                color: 'white',
                textTransform: 'none',
                width: width ? width : '100%',
                padding: padding ? padding : {
                    xl: "13px 24px",
                    xs: "12px 24px"
                },
                borderRadius: '12px',
                boxShadow: "4px 8px 24px 0px #3F8CFF40",
                '&:disabled': {
                    background: "#B3B3CF",
                    boxShadow: "none",
                    color: '#fff'
                }
            }}
            disabled={disabled}
            type={type}
            onClick={type !== 'submit' ? onClick : undefined}
        >
            {btnName}
        </Button>
    )
}

export default BlueButton
