import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import backIcon from "../../../assets/common/backBlueIcon.svg";
import BlueButton from "../../../components/Button/BlueButton";
import CustomCalendar from "../../../components/CustomCalendar/CustomCalendar";
import addIcon from "../../../assets/icons/addIcon.svg";
import editIcon from "../../../assets/icons/editIcon.svg";
import { getUserProfile, updateUserProfile } from "../../../network/user";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/slices/userSlice";
import { ServiceTiming } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../Router/routePaths";
import { setLoading, showToast } from "../../../redux/slices/commonSlice";
import AddAvailabilityModal from "./AddAvailabilityModal";
import dayjs, { Dayjs } from "dayjs";
import { dayMapping, getDayOfWeek } from "../../../utils/calendar";
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from "../../../assets/icons/deleteIcon copy.svg";
import {
  convertTo24Hour,
  sortDisplaySlotsByTime,
} from "../../../utils/interfaces";
import styles from "./style";
import { containerStyle, bodyStyle } from "./style";
interface calendarProps {
  from?: string;
}

const OperatingHours: React.FC<calendarProps> = ({ from }) => {
  const [serviceTiming, setServiceTiming] = useState<ServiceTiming>({});
  const [open, setOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getProfileDetails();
  }, []);

  const addOperatingHoursHandler = () => {
    navigate(routePaths.uploadDocuments);
  };

  const openModalHandler = (day: string) => {
    if (selectedDate && getDayOfWeek(selectedDate) === day) {
      setSelectedDay(`${day} (${selectedDate})`);
    } else {
      setSelectedDay(day);
    }
    setOpen(true);
  };

  const closeModalHandler = () => {
    setOpen(false);
    setSelectedDay("");
  };

  const getProfileDetails = async () => {
    try {
      dispatch(setLoading(true));
      const res = await getUserProfile();
      dispatch(setUserData(res.data));
      setServiceTiming(res?.data?.serviceTiming);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const navigateBackHandler = () => {
    navigate(-1);
  };

  // Callback to handle date selection from the calendar
  const handleDateSelect = (date: Dayjs | null) => {
    if (date) {
      setSelectedDay(date.format("YYYY-MM-DD"));
      setSelectedDate(date.format("YYYY-MM-DD"));
    } else {
      setSelectedDay("");
      setSelectedDate("");
    }
  };

  // const getDisplaySlots = (day: string) => {
  //     const daySlots = serviceTiming[day]?.slots || [];

  //     if (selectedDate) {
  //         // Filter slots for the selectedDate
  //         const selectedDateSlots = daySlots.filter(slot => slot?.monthDate === selectedDate);

  //         // If there are any slots for the selectedDate, return those
  //         if (selectedDateSlots.length > 0) {
  //             return selectedDateSlots;
  //         }
  //     }

  //     // If no slots for the selectedDate, return slots without monthDate (general slots for the day)
  //     return daySlots.filter(slot => !slot?.monthDate);
  // };
  const getDisplaySlots = (day: string) => {
    const daySlots = serviceTiming[day]?.slots || [];

    let filteredSlots: any;

    if (selectedDate) {
      // Filter slots for the selectedDate
      const selectedDateSlots = daySlots.filter(
        (slot) => slot?.monthDate === selectedDate
      );

      // If there are any slots for the selectedDate, assign those to filteredSlots
      if (selectedDateSlots.length > 0) {
        if (
          selectedDateSlots?.length === 1 &&
          selectedDateSlots[0]?.startTime === null
        )
          filteredSlots = [];
        else filteredSlots = selectedDateSlots;
      } else {
        // If no slots for the selectedDate, return slots without monthDate (general slots for the day)
        filteredSlots = daySlots.filter((slot) => !slot?.monthDate);
      }
    } else {
      // If no selectedDate, use general slots for the day
      filteredSlots = daySlots.filter((slot) => !slot?.monthDate);
    }
    const sortedSlots = sortDisplaySlotsByTime(filteredSlots);
    // Sort the filtered slots by startTime
    return filteredSlots?.length > 0 ? filteredSlots : [];
  };

  const handleDelete = async (day: string) => {
    let slotsWithStartAndEndTime: any = [];

    Object.keys(serviceTiming).forEach((key) => {
      const dates = new Set();
      serviceTiming[key].slots.forEach((slot) => {
        const today = dayjs().startOf('day');
        if (slot?.monthDate && dayjs(slot.monthDate).startOf('day').isBefore(today)) {
          return;
        }
        const uniqueId = slot.monthDate || "noDate";

        if (dates.has(uniqueId)) {
          return;
        }
        if (slot.startTime && slot.endTime) {
          slotsWithStartAndEndTime.push(slot);
          dates.add(uniqueId);
        }
      });
    });
    console.log(slotsWithStartAndEndTime)
    if (slotsWithStartAndEndTime.length <= 1 && from === 'edit') {
      dispatch(showToast({ color: 'error', msg: "You cannot delete this slot as atleast one slot is mandatory." }));
      return;
    }
    let updatedServiceTiming;
    if (selectedDate && getDayOfWeek(selectedDate) === day) {
      // Delete only the slot for the selected date
      updatedServiceTiming = {
        ...serviceTiming,
        [day]: {
          ...serviceTiming[day],
          slots: [
            ...serviceTiming[day].slots.filter(
              (slot) => slot.monthDate !== selectedDate
            ),
            { startTime: null, endTime: null, monthDate: selectedDate },
          ],
        },
      };
    } else {
      // If selected date is not matching, remove only the slots without monthDate for the day
      updatedServiceTiming = {
        ...serviceTiming,
        [day]: {
          ...serviceTiming[day],
          slots: serviceTiming[day].slots.filter((slot) => !!slot.monthDate), // Keep only slots that have a monthDate
        },
      };
    }
    try {
      await updateUserProfile({ serviceTiming: updatedServiceTiming });
      dispatch(setUserData({ serviceTiming: updatedServiceTiming }));
      getProfileDetails(); // Fetch updated profile details
    } catch (err) {
      console.log(err);
    }
  };


  const getIsDisabled = (): boolean => {
    return Object.values(serviceTiming).every((day) =>
      day.slots.every((slot: { startTime?: string }) => !slot.startTime)
    );
  };

  return (
    <Box sx={containerStyle(from)}>
      {from !== "edit" && (
        <Box sx={styles.opHrsTitle}>
          <Box sx={styles.opHrsBackIconExternalBox}>
            <Box
              src={backIcon}
              component="img"
              alt="backIcon"
              sx={styles.opHrsBackIcon}
              onClick={navigateBackHandler}
            ></Box>
          </Box>
          <Typography sx={styles.opHrsHeading}>Add Operating Hours</Typography>
        </Box>
      )}
      <Box sx={styles.opHrsBodyContainer}>
        <Box sx={bodyStyle(from)}>
          {/* Custom Calendar */}
          <CustomCalendar
            onDateSelect={handleDateSelect}
            serviceTiming={serviceTiming}
          />

          {/* Scrollable Box for days */}
          <Box sx={styles.scrollableBoxForDays}>
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((day) => (
              <Box key={day} sx={styles.day}>
                <Box sx={styles.singleDayBox}>
                  <Typography sx={styles.dayHeading}>
                    {day}
                    {selectedDate && getDayOfWeek(selectedDate) === day
                      ? ` (${selectedDate})`
                      : ""}
                  </Typography>
                  <Box
                    src={editIcon}
                    alt="edit"
                    sx={styles.editIcon}
                    onClick={() => openModalHandler(day)}
                    component="img"
                  ></Box>
                </Box>
                <Box sx={styles.justifyContentBetween}>
                  <Box sx={styles.displaySlotBox}>
                    {getDisplaySlots(day).map((item: any, index: number) => (
                      <Typography key={index} sx={styles.slotsFont}>
                        {`Slot ${index + 1} - (${item?.startTime} - ${item?.endTime})`}
                      </Typography>
                    ))}
                  </Box>
                  {getDisplaySlots(day).length > 0 && (
                    <Box
                      component="img"
                      src={DeleteIcon}
                      onClick={() => handleDelete(day)}
                      sx={styles.deleteIcon}
                    />
                  )}
                </Box>

                <Box sx={styles.opHrsAddAvailabilityBox}>
                  <Typography sx={styles.addAvailabilityText}>
                    Add Availability
                  </Typography>
                  <Box
                    src={addIcon}
                    alt="add"
                    sx={styles.addIcon}
                    onClick={() => openModalHandler(day)}
                    component="img"
                  ></Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {from !== "edit" && (
        <Box sx={styles.continueBtn}>
          <BlueButton
            btnName={"Continue"}
            disabled={getIsDisabled()}
            type="button"
            onClick={addOperatingHoursHandler}
            width="300px"
          />
        </Box>
      )}
      {open && (
        <AddAvailabilityModal
          handleClose={closeModalHandler}
          selectedDay={selectedDay}
          serviceTimingProp={serviceTiming}
          getProfileDetails={getProfileDetails}
          // selectedDate={selectedDate}
          selectedDate={
            dayjs(selectedDate).day() ===
              dayMapping[
              selectedDay.split(" ")[0].toLowerCase() as keyof typeof dayMapping
              ]
              ? selectedDate
              : ""
          }
        />
      )}
    </Box>
  );
};

export default OperatingHours;
