import React, { ChangeEvent, useEffect, useState, useReducer } from "react";
import "./Home.css";
import Constant from "../../../../comman/Constant";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Container, InputLabel, TextField, Typography } from "@mui/material";
import { submitInquiry } from "../../../../network/services";
import styles from "./style";
import { initialState } from "./helper";
import { setLoading, showToast } from "../../../../redux/slices/commonSlice";
import FileIcon1 from "../../../../assets/ServiceProvider/file icon1.png";
import FileIcon2 from "../../../../assets/ServiceProvider/file icon2.png";
import astrikIcon from "../../../../assets/common/astrikIcon.svg";
import backIcon from "../../../../assets/common/backBlueIcon.svg";
import closeIcon from "../../../../assets/common/closeIcon.svg";
import plusIcon from "../../../../assets/ServiceProvider/plusIcon.svg";
import uploadDoc from "../../../../assets/UploadDocuments/uploadDoc.svg";
import formvectorImage from "../../../../assets/ServiceProvider/formvectorImage.png";
import SuccessForm from "../../../SucessForm";
import { formatInput, validateField } from "../../../../utils/validationUtils";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

interface SnackBarState extends SnackbarOrigin {
  open: boolean;
}

interface CustomLocationState {
  formState?: any;
}

interface State {
  fullName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  isAddMoreZipCode: boolean;
  moreZipCodes: string;
  description: string;
  address: string;
  city: string;
  province: string;
  selectedFiles: File[];
  disabled: boolean;
  disabled2: boolean;
  formCount: number;
  error: string | null;
  errors: {
    phoneNumber: string;
    email: string;
    fullName: string;
    zipCode: string;
    description: string;
    address: string;
    city: string;
    province: string;
    moreZipCodes: string;
  };
}

type Action =
  | {
      type: "SET_FIELD";
      payload: { field: string; value: any };
    }
  | { type: "SET_ERROR"; payload: { field: string; message: string } }
  | { type: "CLEAR_ERRORS" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.payload.field]: action.payload.value };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.message,
        },
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {
          phoneNumber: "",
          email: "",
          fullName: "",
          zipCode: "",
          description: "",
          address: "",
          city: "",
          province: "",
          moreZipCodes: "",
        },
      };
    default:
      return state;
  }
};

const Home = React.forwardRef((props, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [snackMsg, setSnackMsg] = useState("");
  const [fileuploadStatus, setFileuploadStatus] = useState<any[]>([]);
  const [fileuploads, setFileuploads] = useState<any[]>([]);
  const commonDispatch = useDispatch();
  const location = useLocation();
  const pathState = location.state as CustomLocationState;
  const formState = pathState?.formState;
  const [snackBarState, setSnackBarState] = React.useState<SnackBarState>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = snackBarState;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setSnackBarState({ ...newState, open: true });
  };

  const handleClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const validateMoreZipCodes = (value: string) => {
    const moreZipCodesArray = value.split(",").map((zip: string) => zip.trim());

    if (new Set(moreZipCodesArray).size !== moreZipCodesArray.length) {
      return "zip codes must be unique.";
    }
    const zipPattern = /^\d{5,9}$/;
    for (const zip of moreZipCodesArray) {
      if (!zipPattern.test(zip)) {
        return "Zip codes must be between 5 and 9 digits long.";
      }
    }
    return null;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;

    if (
      name === "zipCode" ||
      name === "fullName" ||
      name === "city" ||
      name === "province" ||
      name === "phoneNumber"
    ) {
      value = formatInput(name, value);
    }
    if (name === "email") {
      value = formatInput(name, value, 35);
    }
    if (name === "address") {
      value = formatInput(name, value, 100);
    }
    if (name === "description") {
      value = formatInput(name, value, 1000);
    }

    if (name === "phoneNumber") {
      if (value && !value.startsWith("+1 ")) {
        value = `+1 ${value.replace(/^\+?1?\s?/, "")}`;
      }
      const errorMessage = validateField(name, value);
      dispatch({
        type: "SET_ERROR",
        payload: { field: name, message: errorMessage },
      });
    } else if (name === "moreZipCodes") {
      const errormsg = validateMoreZipCodes(value);
      if (errormsg) {
        dispatch({
          type: "SET_ERROR",
          payload: { field: name, message: errormsg },
        });
      } else {
        dispatch({ type: "SET_ERROR", payload: { field: name, message: "" } });
      }
    } else {
      const errorMessage = validateField(name, value);
      dispatch({
        type: "SET_ERROR",
        payload: { field: name, message: errorMessage },
      });
    }

    dispatch({ type: "SET_FIELD", payload: { field: name, value } });
  };

  const handleFormCount = (value: number) => {
    dispatch({ type: "SET_FIELD", payload: { field: "formCount", value } });
  };

  const handleFormContinue = async (e: any) => {
    e.preventDefault();
    window.scrollTo({
      top: window.innerHeight * 0.85,
      behavior: "smooth",
    });
    const userData = {
      fullName: state.fullName,
      phoneNumber: state.phoneNumber,
      email: state.email,
      description: state.description,
      address: state.address,
      city: state.city,
      province: state.province,
      zipCode: state.zipCode,
      zipCodes: [
        ...(state.moreZipCodes
          ? state.moreZipCodes
              .split(",")
              .map((zip) => zip.trim())
              .filter((zip) => zip)
          : []),
      ],
      businessDocuments: fileuploads,
    };
    const response = await submitInquiry(userData);
    const data = await response.data;

    if (data) {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "formCount", value: 4 },
      });
    }
  };

  const handleFileTrigger = () => {
    const fileElem = document.querySelector(
      ".mainFileSelector"
    ) as HTMLInputElement | null;
    if (fileElem) {
      fileElem.click();
    } else {
      console.error("File input element not found");
    }
  };

  const handleAddMoreZipCode = () => {
    dispatch({
      type: "SET_FIELD",
      payload: { field: "isAddMoreZipCode", value: true },
    });
  };
  useEffect(() => {
    const hasErrors = Object.values(state.errors).some((error) => error !== "");
    if (
      !hasErrors &&
      state.fullName != "" &&
      state.phoneNumber !== "" &&
      state.email !== "" &&
      state.description != ""
    )
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: false },
      });
    else
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled", value: true },
      });
  }, [state.errors]);

  useEffect(() => {
    const hasErrors = Object.values(state.errors).some((error) => error !== "");
    if (
      !hasErrors &&
      state.address !== "" &&
      state.city !== "" &&
      state.province !== "" &&
      state.zipCode !== "" &&
      state.moreZipCodes !== ""
    ) {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled2", value: false },
      });
    } else {
      dispatch({
        type: "SET_FIELD",
        payload: { field: "disabled2", value: true },
      });
    }
  }, [
    state.address,
    state.city,
    state.province,
    state.zipCode,
    state.moreZipCodes,
    state.errors,
  ]);

  const handleRemoveFile = (id: number) => {
    const newFiles = state.selectedFiles.filter((_, idx) => idx !== id);
    const newFilesuploads = fileuploads.filter((_, idx) => idx !== id);

    dispatch({
      type: "SET_FIELD",
      payload: { field: "selectedFiles", value: newFiles },
    });
    setFileuploads(newFilesuploads);
    setSnackMsg("File removed successfully");
    const snackBtn = document.getElementById("snackbar");
    snackBtn?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileList = Array.from(files);
      const allowedTypes = [
        "application/pdf",
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const validFiles = fileList.filter((file) =>
        allowedTypes.includes(file.type)
      );
      const invalidFiles = fileList.filter(
        (file) => !allowedTypes.includes(file.type)
      );

      if (invalidFiles.length > 0) {
        dispatch({
          type: "SET_FIELD",
          payload: {
            field: "error",
            value: "Only PDF, PNG, JPG, JPEG and doc files are allowed.",
          },
        });
      } else if (state.selectedFiles.length + validFiles.length > 3) {
        dispatch({
          type: "SET_FIELD",
          payload: {
            field: "error",
            value: "More than 3 files are not allowed.",
          },
        });
      } else {
        const newValidFiles = validFiles.filter(
          (validFile) =>
            !state.selectedFiles.some(
              (selectedFile) => selectedFile.name === validFile.name
            )
        );
        if (newValidFiles.length < validFiles.length) {
          commonDispatch(
            showToast({
              color: "error",
              msg: "Duplicate files are not allowed",
            })
          );
        } else {
          for (let validFile of newValidFiles) {
            fileUploadPercantageTrack(validFile);
          }
          dispatch({
            type: "SET_FIELD",
            payload: {
              field: "selectedFiles",
              value: [...state.selectedFiles, ...newValidFiles],
            },
          });
          setSnackMsg("File added successfully");
          const snackBtn = document.getElementById("snackbar");
          snackBtn?.click();
        }
      }
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (formState == "form") {
      setTimeout(() => {
        const elem = document.getElementById("register");
        elem?.click();
      }, 200);
    }
  }, []);

  const buttons = (
    <>
      <Button
        style={{ display: "none" }}
        id="snackbar"
        onClick={handleClick({ vertical: "top", horizontal: "right" })}
      >
        Top-Right
      </Button>
    </>
  );

  const fileUploadPercantageTrack = (validFile: any) => {
    const filename = validFile.name;
    let data = new FormData();
    data.append("file", validFile);

    let request = new XMLHttpRequest();
    request.open("POST", `${Constant.BASE_URL}/api/file/upload`);
    commonDispatch(setLoading(true));
    let existingItemIndex: number = fileuploadStatus.findIndex(
      (fileupload) => fileupload.name == filename
    );

    // upload progress event
    request.upload.addEventListener("progress", function (e) {
      let percent_completed = (e.loaded / e.total) * 100;
      let newItem = { name: filename, percent_completed: percent_completed };
      if (existingItemIndex !== -1) {
        setFileuploadStatus((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[existingItemIndex] = newItem;
          existingItemIndex = updatedItems.findIndex(
            (fileupload) => fileupload.name === filename
          );
          return updatedItems;
        });
      } else {
        if (existingItemIndex == -1) {
          existingItemIndex = 0;
        } else {
          existingItemIndex = existingItemIndex + 1;
        }
        setFileuploadStatus((prevItems) => [...prevItems, newItem]);
      }
    });

    request.addEventListener("load", function (e) {
      let files = JSON.parse(request.response).data;
      files = { ...files, name: filename, size: validFile.size / 1024 };
      setFileuploads((prevItems) => [...prevItems, files]);
      commonDispatch(setLoading(false));
    });
    request.send(data);
  };

  const getSx = (val: string | null) => {
    return {
      borderColor: !!val ? "red" : undefined,
      "& .MuiFormHelperText-root": { margin: "5px 5px -6px 5px" },
    };
  };
  return (
    <Box>
      <Box sx={styles.background}>
        <Container maxWidth="md">
          <Box ref={ref} sx={styles.formBox}>
            <Box>
              <Grid container>
                <Grid item xs={12} lg={8} sx={styles.gridItem}>
                  {state.formCount === 1 && (
                    <form onSubmit={() => handleFormCount(2)}>
                      <Typography variant="h3" sx={styles.formTitle}>
                        Inquiry Form
                      </Typography>
                      <Box sx={styles.requiredFields}>
                        <img
                          src={astrikIcon}
                          className="astrikIcon"
                          alt="astrikIcon"
                        />
                        <Typography variant="body2" sx={styles.requiredText}>
                          All the fields are required
                        </Typography>
                      </Box>
                      <Box className="formControl">
                        <InputLabel className="inputLable">
                          Full Name
                        </InputLabel>
                        <TextField
                          name="fullName"
                          value={state.fullName}
                          onChange={handleInputChange}
                          placeholder="Add First & Last Name"
                          error={!!state.errors.fullName}
                          helperText={state.errors.fullName}
                          sx={getSx(state.errors.fullName)}
                        />
                      </Box>
                      <Box className="formControl">
                        <InputLabel className="inputLable">
                          Phone Number
                        </InputLabel>
                        <TextField
                          name="phoneNumber"
                          value={state.phoneNumber}
                          onChange={handleInputChange}
                          placeholder="Add Number"
                          error={!!state.errors.phoneNumber}
                          helperText={state.errors.phoneNumber}
                          sx={getSx(state.errors.phoneNumber)}
                        />
                      </Box>
                      <Box className="formControl">
                        <InputLabel className="inputLable">
                          Email Address
                        </InputLabel>
                        <TextField
                          name="email"
                          value={state.email}
                          onChange={handleInputChange}
                          type="email"
                          placeholder="Add Email"
                          error={!!state.errors.email}
                          helperText={state.errors.email}
                          sx={getSx(state.errors.email)}
                        />
                      </Box>
                      <Box className="formControl">
                        <InputLabel className="inputLable">
                          Description
                        </InputLabel>
                        <TextField
                          name="description"
                          value={state.description}
                          onChange={handleInputChange}
                          placeholder="Add Description"
                          multiline
                          minRows={1}
                          maxRows={4}
                          error={!!state.errors.description}
                          helperText={state.errors.description}
                          sx={getSx(state.errors.description)}
                        />
                      </Box>
                      <Box sx={styles.buttonContainer} className="button">
                        <button
                          className={`${state.disabled ? "disableBtn" : "activeBtn"}`}
                          disabled={state.disabled}
                        >
                          Next
                        </button>
                      </Box>
                    </form>
                  )}
                  {state.formCount === 2 && (
                    <Box>
                      <Box sx={styles.backButton}>
                        <Box onClick={() => handleFormCount(1)}>
                          <Box
                            component="img"
                            src={backIcon}
                            alt="backArrowIcon"
                            sx={{
                              width: {
                                xs: "30px",
                                lg: "auto",
                              },
                            }}
                          ></Box>
                        </Box>
                      </Box>
                      <Typography variant="h3" sx={styles.formTitle}>
                        Inquiry Form
                      </Typography>
                      <form
                        className="inqueryFormBox"
                        onSubmit={() => handleFormCount(3)}
                      >
                        <Box className="formControl">
                          <InputLabel className="inputLable">
                            Address
                          </InputLabel>
                          <TextField
                            name="address"
                            value={state.address}
                            onChange={handleInputChange}
                            placeholder="Add Address"
                            error={!!state.errors.address}
                            helperText={state.errors.address}
                            sx={getSx(state.errors.address)}
                          />
                        </Box>
                        <Box className="formControl">
                          <InputLabel className="inputLable">City</InputLabel>
                          <TextField
                            name="city"
                            value={state.city}
                            onChange={handleInputChange}
                            placeholder="Enter City"
                            error={!!state.errors.city}
                            helperText={state.errors.city}
                            sx={getSx(state.errors.city)}
                          />
                        </Box>
                        <Box>
                          <Grid container>
                            <Grid item xs={12} lg={6}>
                              <Box className="formControl" sx={styles.marginL}>
                                <InputLabel className="inputLable">
                                  State
                                </InputLabel>
                                <TextField
                                  name="province"
                                  value={state.province}
                                  onChange={handleInputChange}
                                  placeholder="Enter Province"
                                  error={!!state.errors.province}
                                  helperText={state.errors.province}
                                  sx={getSx(state.errors.province)}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Box className="formControl" sx={styles.marginL}>
                                <InputLabel className="inputLable">
                                  Zip Code
                                </InputLabel>
                                <TextField
                                  name="zipCode"
                                  value={state.zipCode}
                                  type="text"
                                  onChange={handleInputChange}
                                  placeholder="Add Zip Code"
                                  error={!!state.errors.zipCode}
                                  helperText={state.errors.zipCode}
                                  sx={getSx(state.errors.zipCode)}
                                />
                              </Box>
                            </Grid>
                            {state.isAddMoreZipCode && (
                              <Grid item xs={12}>
                                <Box
                                  className="formControl"
                                  sx={styles.marginL}
                                >
                                  <InputLabel className="inputLable">
                                    Service Location Zip Codes
                                  </InputLabel>
                                  <TextField
                                    name="moreZipCodes"
                                    value={state.moreZipCodes}
                                    onChange={handleInputChange}
                                    placeholder="Add Zip Codes"
                                    error={!!state.errors.moreZipCodes}
                                    helperText={state.errors.moreZipCodes}
                                    sx={getSx(state.errors.moreZipCodes)}
                                  />
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                        {!state.isAddMoreZipCode && (
                          <Box
                            sx={styles.addMoreZipCode}
                            onClick={handleAddMoreZipCode}
                          >
                            <img src={plusIcon} />
                            <Typography
                              variant="h3"
                              sx={styles.addMoreZipCodeText}
                            >
                              Add more Zip Code for your Service Location
                            </Typography>
                          </Box>
                        )}
                        <Box sx={styles.buttonContainer} className="button">
                          <button
                            className={`${state.disabled2 ? "disableBtn" : "activeBtn"}`}
                            disabled={state.disabled2}
                          >
                            Submit Inquiry
                          </button>
                        </Box>
                      </form>
                    </Box>
                  )}
                  {state.formCount === 3 && (
                    <Box style={{ width: "100%" }}>
                      <Box>
                        <Box sx={styles.backButton}>
                          <Box onClick={() => handleFormCount(2)}>
                            <Box
                              component="img"
                              src={backIcon}
                              alt="backArrowIcon"
                              sx={{
                                width: {
                                  xs: "30px",
                                  sm: "auto",
                                },
                              }}
                            ></Box>
                          </Box>
                        </Box>
                        <Typography variant="h3" sx={styles.UploadformTitle}>
                          Upload License/InsuranceDocument
                        </Typography>
                      </Box>
                      <form
                        className="about_form setuploadfile"
                        onSubmit={handleFormContinue}
                      >
                        <div className="form_file_wrapper">
                          <Box
                            sx={styles.uploadBox}
                            onClick={handleFileTrigger}
                          >
                            <Box sx={{ paddingBottom: "10px" }}>
                              <img
                                src={uploadDoc}
                                width="66px"
                                height="66px"
                                alt="Upload Image"
                              />
                            </Box>
                            <Typography variant="h3" sx={styles.uploadText}>
                              Upload License Document
                            </Typography>
                            <Typography variant="h3" sx={styles.uploadSubText}>
                              Lorem ipsum dolor sit amet <br /> consectetur.
                            </Typography>
                          </Box>
                          <Typography variant="h3" sx={styles.noteText}>
                            Note: Max 3 documents can be added
                          </Typography>
                          <input
                            type="file"
                            multiple
                            className="mainFileSelector"
                            onChange={handleFileChange}
                          />
                          {state.error && (
                            <p className="errorText">{state.error}</p>
                          )}
                          <Box>
                            {state.selectedFiles.map((file, index) => (
                              <Box sx={styles.fileItem} key={file.name}>
                                <img
                                  className="fileItem_img"
                                  src={index % 2 === 0 ? FileIcon1 : FileIcon2}
                                  alt="Image Icon"
                                />
                                <Box sx={styles.fileItemText}>
                                  <Box sx={styles.fileName}>
                                    {file.name}
                                    <Box sx={styles.fileSize}>
                                      {Math.ceil(file.size / 1024)} kB
                                    </Box>
                                  </Box>
                                  <Box sx={styles.fileProgress}></Box>
                                </Box>
                                <Box>
                                  <Box
                                    src={closeIcon}
                                    alt="Delete Image"
                                    className="deleteIcon"
                                    component="img"
                                    sx={{
                                      cursor: "pointer",
                                      width: {
                                        xl: "28px",
                                        xs: "20px",
                                      },
                                    }}
                                    onClick={() => handleRemoveFile(index)}
                                  ></Box>
                                  <Box sx={styles.fileProgressText}>
                                    {/* 100% */}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </div>
                        <Box sx={styles.buttonContainer} className="button">
                          <button
                            className={`${state.selectedFiles.length === 0 ? "disableBtn" : "activeBtn"}`}
                            disabled={state.selectedFiles.length === 0}
                          >
                            Continue
                          </button>
                        </Box>
                      </form>
                    </Box>
                  )}
                  {state.formCount === 4 && <SuccessForm />}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Box sx={styles.vectorImage}>
          <img src={formvectorImage} alt="Vector Image" />
        </Box>
      </Box>

      <Box sx={styles.w500}>
        {buttons}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message={snackMsg}
          key={vertical + horizontal}
        />
      </Box>
    </Box>
  );
});

export default Home;
