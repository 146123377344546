import axiosInstance from "./axios";

export const uploadImage = async (payload: any) => {
  const res = await axiosInstance.post(`/file/upload`, payload);
  return res.data;
};

export const getQuestions = async () => {
  const res = await axiosInstance.get(`/faq/list-for-user`);
  return res.data;
};