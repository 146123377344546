import { useEffect, useRef } from "react";
import GetHelp from "./GetHelp/GetHelp";
import { Header } from "./Header/Header";
import Home from "./Home/Home";
import Footer from "./Footer/Footer";

const ServiceProvider: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('register') === 'true') {
            scrollToForm();
        }
    }, []);

    const contentRef = useRef<HTMLDivElement | null>(null);

    const scrollToForm = () => {
        if (contentRef.current) {
            contentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Header scrollToForm={scrollToForm} />
            <Home ref={contentRef} />
            <GetHelp />
            <Footer />
        </>
    )
}

export default ServiceProvider;