import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
    confirmationModalPaper: {
        '& .MuiDialog-paper': {
            width: {
                xs: '90%',
                sm: '70%',
                md: '400px',
            },
            borderRadius: "40px",
            maxWidth: '90%',
        },
    },
    confirmationModalTitle: {
        m: 0,
        fontFamily: "Montserrat, sans- serif",
        textAlign: "center",
        fontSize: "22px",
        fontWeight: "700"
    },
    confirmationDialogContent:
    {
        padding: {
            lg: "30px 90px 20px",
            xs: "0 0 20px 0"
        },
        "@media (orientation: landscape) and (max-width: 999px)": {
              overflowY:"unset",
            }
    },
    confirmationModalButtonDiv: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: {
            sm: "row",
            xs: "column"
        }
    },
    confirmationModalYesButton: {
        background: '#B3B3CF',
        padding: '13px 80px',
        borderRadius: "40px",
        fontSize: {
            lg: "18px",
            xs: "16px"
        },
        color: '#fff',
        fontWeight: '500',
        textDecoration: 'none',
        fontFamily: 'Montserrat, sans-serif',
        textTransform: "capitalize",
        margin: {
            sm: "0 20px 0 0",
            xs: "0 0 20px 0"
        },
        width: {
            sm: "max-content",
            xs: "100%"
        }
    },
    confirmationModalNoButton: {
        background: '#06038D',
        padding: '13px 80px',
        borderRadius: "40px",
        fontSize: {
            lg: "18px",
            xs: "16px"
        },
        color: '#fff',
        fontWeight: '500',
        textDecoration: 'none',
        fontFamily: 'Montserrat, sans-serif',
        textTransform: "capitalize",
        width: {
            sm: "max-content",
            xs: "100%"
        }
    },
    paddingTwenty: {
        padding: "0 20px"
    }



};

export default styles;

