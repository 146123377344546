export const handleEncrypt = (value: string) => {
    const encoded = btoa(value);
    return encoded;
};

export const handleDecrypt = (value: string) => {
    const decoded = atob(value);
    return decoded;
};

export const formatPhoneNumber = (value: string) => {
    const digits = value.replace(/\D/g, '');

    let formattedNumber = digits
        .slice(0, 6)
        .replace(/(\d{3})(?=\d)/g, '$1-')
        .trim();

    if (digits.length > 6) {
        formattedNumber += '-' + digits.slice(6, 10);
    }

    return `+1 ${formattedNumber.trim()}`;
};

export interface Slot {
    startTime: string;
    endTime: string;
    monthDate?: any;
}

export interface DayTiming {
    isAvailable: boolean;
    slots: Slot[];
}

export interface ServiceTiming {
    [day: string]: DayTiming;
}

export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const sortDays = (serviceTiming: ServiceTiming): ServiceTiming => {
    const weekOrder: Array<keyof ServiceTiming> = daysOfWeek

    const sortedServiceTiming: ServiceTiming = {};
    weekOrder.forEach((day) => {
        if (serviceTiming[day]) {
            sortedServiceTiming[day] = serviceTiming[day]!;
        }
    });

    return sortedServiceTiming;
};
