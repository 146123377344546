export const initialState = {
    fullName: "",
    phoneNumber: "",
    email: "",
    zipCode: "",
    message: "",
    address: "",
    city: "",
    state: "",
    disabled: true,
    errors: { phoneNumber: '', email: '', fullName: '', zipCode: '', message: '', address: '', city: '', state: '' },
};
