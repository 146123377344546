import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import bannerBgImage from "../../../../assets/images/bannerBgImage.png";
import bannerImage from "../../../../assets/images/bannerImage.png";
import borderImage from "../../../../assets/images/borderImage.png";
import { useState } from "react";

const Banner = () => {
  const [loadingBannerImage, setLoadingBannerImage] = useState(true);
  const [loadingBorderImage, setLoadingBorderImage] = useState(true);

  return (
    <>
      <Box
        sx={{
          background: "#06038D",
          boxShadow: "4px 8px 24px 0px #3F8CFF40",
          padding: {
            xl: "90px 0 0 66px",
            md: "80px 0 0 30px",
            xs: "60px 16px 0 16px",
          },
          backgroundImage: `url(${bannerBgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
          position: "relative",
          marginTop: "-30px",
          backgroundSize: "contain",
        }}
      >
        <Grid
          sx={{
            "@media (min-width: 1536px)": {
              maxWidth: "1536px",
              margin: "auto",
            },
            padding: {
              xl: "0 24px",
              lg: "0 40px",
            },
          }}
          container
        >
          <Grid item xs={12} sm={12} lg={4}>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    lg: "46px",
                    xs: "28px",
                  },
                  color: "#1CB469",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "700",
                }}
              >
                Sell Your Home
              </Typography>
              <Box sx={{ padding: "14px 0" }}>
                <img
                  src={borderImage}
                  alt="Border Image"
                  className="vectorImage"
                  style={{ display: loadingBorderImage ? "none" : "block" }}
                  onLoad={() => setLoadingBorderImage(false)}
                />
                {loadingBorderImage && <CircularProgress size={40} />}
              </Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    lg: "30px",
                    xs: "22px",
                  },
                  color: "#1CB469",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "500",
                  paddingBottom: "14px",
                }}
              >
                For as low as
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    lg: "120px",
                    xs: "80px",
                  },
                  color: "#fff",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                }}
              >
                1.95 %
              </Typography>
              <Box sx={{ padding: "0 0 14px" }}>
                <img
                  src={borderImage}
                  alt="Border Image"
                  className="vectorImage"
                  style={{ display: loadingBorderImage ? "none" : "block" }}
                  onLoad={() => setLoadingBorderImage(false)}
                />
                {loadingBorderImage && <CircularProgress size={40} />}
              </Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    xl: "24px",
                    lg: "20px",
                    xs: "18px",
                  },
                  lineHeight: {
                    xs: "30px",
                    lg: "40px",
                  },
                  color: "#fff",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "400",
                  paddingBottom: "14px",
                }}
              >
                Your Ultimate Home Selling Hub: Connect With Experts, Get Top
                Offers, And Simplify The Sale
              </Typography>
              <Box>
                <NavLink to="/tiered-plans">
                  <Button
                    sx={{
                      background: "#1CB469",
                      color: "#fff",
                      padding: {
                        xs: "8px 20px",
                        sm: "13px 30px",
                      },
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                      },
                      textTransform: "capitalize",
                      border: "1.6px solid #1CB469",
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "600",
                      borderRadius: "12px",
                      margin: {
                        xs: "10px 0 30px 0",
                        sm: "10px 0 0 0",
                        lg: "40px 0 70px 0",
                      },
                    }}
                  >
                    Contact Us
                  </Button>
                </NavLink>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <img
                src={bannerImage}
                alt="bannerImage"
                width="100%"
                style={{ display: loadingBannerImage ? "none" : "block" }}
                onLoad={() => setLoadingBannerImage(false)}
              />

              {loadingBannerImage && (
                <CircularProgress
                  size={40}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Banner;
