export const formatUserData = (data: any) => ({
  profilePic: data.profileImage?.url,
  fullName: data.fullName,
  phoneNumber: data.phoneNumber,
  email: data.email,
  serviceNames: data.serviceDetails
    ?.map((service: any) => service.name)
    .join(", "),
  categoryDetails: data.categoryDetails,
  subCategoryDetails: data.subCategoryDetails,
  serviceImages: data.businessImages,
  businessTitle: data.businessTitle,
  description: data.description,
  businessDocuments: data.businessDocuments,
  address: data.address,
  city: data.city,
  province: data.province,
  zipCode: data.zipCode,
  zipCodes: data.zipCodes?.join(","), // Convert to a comma-separated string
  serviceIds: data.serviceIds,
  categoryIds: data.categoryIds,
  subCategoryIds: data.subCategoryIds,
  isProfileCompleted: data.isProfileCompletion,
  errors: {
    phoneNumber: "",
    email: "",
    fullName: "",
    zipCode: "",
    message: "",
    address: "",
    city: "",
    state: "",
    businessTitle: "",
  },
});
