import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
    selectSubServiceCategoryForm: {
        "& .innerWrapper": {
            padding: {
                sm: "16px",
                xs: "60px 16px 16px"
            }
        }
    },
    uploadDocWrapper: {
        height: 'auto',
        width: {
            xs: '100%',
            md: '80%',
            lg: "60%"
        },
        display: 'flex',
        flexDirection: 'column'
    },
    uploadContainer: {
        borderRadius: '8px',
        border: '1px dashed #06038D',
        background: 'rgba(6, 3, 141, 0.05)',
        padding: {
            xl: '14px',
            xs: "10px"
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    uploadLisence: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        fontWeight: 600,
        color: '#0D0639',
        mt: 1
    },
    uploadDesc: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: {
            xl: '14px',
            xs: "12px"
        },
        fontWeight: 400,
        color: '#8989A3',
        textAlign: 'center',
        marginTop: "5px"
    },
    uploadNote: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: '14px',
        fontWeight: 400,
        color: '#8989A3',
        textAlign: 'center',
        mt: 1,
        marginBottom: "10px"
    }

};

export default styles;

