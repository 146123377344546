import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import quickBuyBannerImage from "../../assets/quickBuy/quickBuyBannerImage.png";
import quickBuyVectorImage from "../../assets/quickBuy/quickBuyVectorImage.png";
import { useState } from "react";

const QuickBuyBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <Box
        sx={{
          background: "#F6F6FD",
          boxShadow: "4px 8px 24px 0px #3F8CFF40",
          padding: {
            xl: "0 30px 0 30px",
            xs: "0 16px",
          },
          position: "relative",
        }}
      >
        <Grid
          sx={{
            "@media (min-width: 1536px)": {
              maxWidth: "1536px",
              margin: "auto",
            },
            flexDirection: {
              md: "row",
              xs: "column-reverse",
            },
          }}
          container
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={4}
          >
            <Box
              sx={{
                padding: {
                  lg: "0 20px 0 0",
                  md: "0 20px 0 0",
                  xs: "20px 20px 30px 0",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    lg: "42px",
                    xs: "32px",
                  },
                  color: "#0F172A",
                  fontFamily: "Montserrat, sans- serif",
                  fontWeight: "700",
                  lineHeight: {
                    lg: "60px",
                    xs: "44px",
                  },
                }}
              >
                Together, Let’s Find the Best Home Sale Solution for You
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    lg: "18px",
                    xs: "16px",
                  },
                  paddingTop: "14px",
                  color: "#64748B",
                  fontFamily: "Montserrat, sans- serif",
                  fontWeight: "400",
                  lineHeight: {
                    lg: "36px",
                    xs: "28px",
                  },
                }}
              >
                We make selling your home convenient, flexible and quick
              </Typography>
              <NavLink to="/quick-buy-form">
                <Button
                  sx={{
                    background: "#1CB469",
                    color: "#fff",
                    padding: {
                      xs: "8px 20px",
                      sm: "13px 30px",
                    },
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                    },
                    textTransform: "capitalize",
                    border: "1.6px solid #1CB469",
                    fontFamily: "Montserrat, sans- serif",
                    fontWeight: "600",
                    borderRadius: "12px",
                    margin: {
                      xs: "20px 0 0 0",
                      sm: "20px 0 0 0",
                      lg: "40px 0 0 0",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </NavLink>
            </Box>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "relative",
            }}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={8}
          >
            <Box
              sx={{
                display: "flex",
                zIndex: "9",
                marginTop: "-30px",
              }}
            >
              <img
                onLoad={() => setIsLoading(false)}
                src={quickBuyBannerImage}
                width="100%"
                alt="Quick Banner Image"
              />
              {isLoading && (
                <CircularProgress
                  size={40}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </Box>
            <Box
              component="img"
              src={quickBuyVectorImage}
              sx={{
                position: "absolute",
                top: "-30px",
                left: "0",
              }}
            ></Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default QuickBuyBanner;
