import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
    availabilityModalDialog: {
        '& .MuiDialog-paper': {
            borderRadius: '15px'
        },
        zIndex: 9,
    },
    closeIconBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '15px'
    },
    closeIcon: {
        height: '25px',
        width: '25px',
        cursor: 'pointer'
    },
    availabilityDialogContent: {
        padding: {
            xs: "0 15px",
            md: "0 50px"
        },
        '&::-webkit-scrollbar': {
            width: '6px', 
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888', 
            borderRadius: '10px', 
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555', 
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
        }
    }
    ,

    availabilityDialogTitle: {
        padding: "0 0 10px 0",
        fontFamily: "Montserrat, sans-serif",
        fontSize: { xs: '20px', xl: '30px' },
        fontWeight: 700,
        color: '#112047',
    },
    availabilitySelectedDay: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: { xs: '14px', md: '16px', xl: "18px" },
        fontWeight: 500,
        color: 'black',
        paddingBottom: "10px",
        textTransform: 'capitalize'
    },
    repeatsOnText: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: "Montserrat, sans-serif",
        paddingY: '15px',
    },
    availabilityDialogActions: {
        justifyContent: 'center',
        margin: {
            sm: '5px 0 40px 0',
            xs: '5px 0 16px 0'
        },
        "@media (orientation: landscape) and (max-width: 999px)": {
            margin: "5px 0 0 0"
        }
    },
    justifyContentCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    addOpExternalBox: {
        height: 'auto',
        width: { xs: '100%', lg: '60%', md: "80%" },
        scrollbarWidth: 'none'
    },
    addOpDaysBox: {
        border: '1px solid rgba(137, 137, 163, 0.4)',
        borderRadius: '16px',
        height: { md: '80%' },
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        padding: '10px 18px 30px 18px',
    },
    addOpDayFont: {
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: "Montserrat, sans- serif",
        color: '#0D0639',
        padding: '10px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
    },
    addAvailabilityTextFont: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#8989A3',
        paddingY: '12px',
        fontFamily: "Montserrat, sans- serif",

    },
    addAvailabilityBox: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        paddingX: '10px'
    },
    opHrsBackIconExternalBox: {
        position: "absolute",
        cursor: "pointer",
        top: {
            lg: "30px",
            sm: "25px",
            xs: "13px"
        },
        left: {
            sm: "40px",
            xs: "20px"
        }
    },
    opHrsBackIcon: {
        position: "absolute",
        cursor: "pointer",
        width: {
            lg: "auto",
            xs: "30px"
        }
    },
    opHrsHeading: {
        fontWeight: 700,
        fontSize: { xs: "18px", md: "20px", xl: "28px" },
        color: "#06038D",
        fontFamily: "Montserrat, sans-serif",
        textAlign: "center",
    },
    opHrsBodyContainer: {
        width: { xs: "80%", sm: "80vw", md: "70vw" },
        height: {
            xs: "90%", sm: "70vh", md: "62vh", "@media (orientation: landscape) and (max-width: 999px)": {
                height: "auto"
            }
        },
        backgroundColor: "#FFFFFF",
        padding: {
            sm: "20px",
            xs: "16px"
        },
        borderRadius: "15px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        scrollbarWidth: "none",
        boxShadow: "none",
    },
    opHrsTitle: {
        display: "flex",
        justifyContent: "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        width: "100%",
        position: "relative",
        padding: {
            sm: "30px 0 15px",
            xs: "15px 0"
        },
        marginBottom: "20px"
    },
    scrollableBoxForDays: {
        flexGrow: 1,
        overflowY: "auto",
        padding: {
            sm: "10px 18px 30px 18px",
            xs: "5px 0 0 0"
        },
        scrollbarWidth: "none",
        "@media (orientation: landscape) and (max-width: 999px)": {
            padding: "10px 18px 0 18px"
        }
    },
    continueBtn: {
        display: "flex",
        justifyContent: "center",
        mb: {
            md: 1,
            xl: 2,
        },
        marginTop: {
            xs: "20px",
            xl: "30px"
        },
        "@media (orientation: landscape) and (max-width: 999px)": {
            marginBottom: "20px"
        }
    },
    addIcon: {
        cursor: "pointer",
        width: {
            xl: "auto",
            sm: "23px",
            xs: "18px"
        }
    },
    addAvailabilityText: {
        fontSize: {
            xl: "14px",
            xs: "12px"
        },
        fontWeight: 400,
        color: "#8989A3",
        paddingY: "12px",
        fontFamily: "Montserrat, sans-serif",
    },
    opHrsAddAvailabilityBox: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        borderTop: "1px solid rgba(0, 0, 0, 0.05)",
        paddingX: "8px",
    },
    deleteIcon: {
        cursor: "pointer",
        marginRight: "8px",
        width: {
            xl: "auto",
            sm: "23px",
            xs: "18px"
        }
    },
    slotsFont: {
        fontSize: {
            xl: "14px",
            xs: "12px"
        },
        fontWeight: "500",
        fontFamily: "Montserrat, sans-serif",
        color: "#0D0639",
    },
    displaySlotBox: {
        padding: "10px 10px 0px 10px",
        display: "flex",
        columnGap: "20px",
        flexWrap: "wrap"
    },
    justifyContentBetween: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "5px"
    },
    editIcon: {
        width: {
            sm: "20px",
            xs: "14px"
        },
        cursor: "pointer"
    },
    dayHeading: {
        fontSize: {
            xl: "16px",
            sm: "16px",
            xs: "14px"
        },
        fontWeight: "600",
        fontFamily: "Montserrat, sans-serif",
        color: "#0D0639",
        paddingY: "10px",
        paddingBottom: {
            sm: "0",
            xs: "0"
        },
        paddingTop: {
            sm: "0",
            xs: "0"
        },
        textTransform: "capitalize",
    },
    singleDayBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: "10px",
    },
    day: {
        paddingTop: {
            sm: "5px",
            xs: "5px"
        }
    },
    addBusinessFormWrapper: {
        height: 'auto',
        width: "60%",
        display: 'flex',
        flexDirection: 'column'
    }
};

export default styles;

export const containerStyle = (from: string | undefined) => ({
    height: from !== "edit" ? {
        sm: "100vh",
        xs: "auto"
    } : "auto",
    background: "#f5f5fa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    position: "relative",
    "@media (orientation: landscape) and (max-width: 999px)": {
        height: "auto"
    }
});

export const bodyStyle = (from: string | undefined) => ({
    width: {
        sm: "95%",
        xs: "100%"
    },
    margin: {
        xs: "0",
        sm: "auto"
    },
    height: from === "edit" ? "calc(100vh - 150px)" : "calc(100vh - 220px)",
    backgroundColor: "white",
    borderRadius: { md: "15px" },
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "@media (orientation: landscape) and (max-width: 999px)": {
        height: from === "edit" ? "auto" : "auto"
    }
});
