import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import closeIcon from "../../../assets/common/closeIcon.svg";

import TimeRangeSelector from "../../../components/CustomTimePicker/TimeRangeSelector";
import RepeatDays from "../../../components/CustomTimePicker/RepeatDays";
import BlueButton from "../../../components/Button/BlueButton";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { routePaths } from "../../../Router/routePaths";
import { updateUserProfile } from "../../../network/user";
import { setUserData } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { showToast } from "../../../redux/slices/commonSlice";
import { daysOfWeek } from "../../../utils/common";
import { keyToDayMapping } from "../../../utils/calendar";
import {
  addGeneralSlotsWithoutDate,
  AvailabilityFormProps,
  createNewSlot,
  deepCopyServiceTiming,
  ensureDayExists,
  formatTimeSlots,
  removeFalsyIdsFromSlots,
  sortSlotsByStartTime,
  TimeSlot,
  validateLastSlot,
} from "../../../utils/interfaces";
import styles from "./style";

const AddAvailabilityModal: React.FC<AvailabilityFormProps> = ({
  handleClose,
  selectedDay,
  serviceTimingProp,
  getProfileDetails,
  selectedDate,
  from,
}) => {
  const [timeSlots, setTimeSlots] = useState<
    {
      startTime: Dayjs | null;
      endTime: Dayjs | null;
      monthDate?: Dayjs | null;
      _id?: string | null;
    }[]
  >([{ startTime: null, endTime: null, monthDate: null, _id: null }]);
  const [removedSlots, setRemovedSlots] = useState<
    {
      startTime: Dayjs | null;
      endTime: Dayjs | null;
      monthDate?: Dayjs | null;
    }[]
  >([]);
  const [repeatDays, setRepeatDays] = useState<string[]>([]); // To track selected repeat days
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width:600px)"); // Adjust width for small screens
  const buttonWidth = isSmallScreen ? "80%" : "50%";
  useEffect(() => {
    setRepeatDays([selectedDay.toLowerCase()]);
  }, [selectedDay]);

  const toggleRepeatDay = (day: string) => {
    const dayInLower = day.toLowerCase();

    // Allow deselecting the day if it is already selected
    if (repeatDays.includes(dayInLower)) {
      if (day !== selectedDay)
        setRepeatDays((prevDays) => prevDays.filter((d) => d !== dayInLower));
      return;
    }

    // Check if the selected day already has slots in serviceTimingProp
    if (serviceTimingProp && serviceTimingProp[dayInLower]) {
      const existingSlots = serviceTimingProp[dayInLower].slots;

      // Convert existing slots to Dayjs format for comparison
      const existingDayjsSlots = convertTimeSlotsToDayjs(existingSlots);

      // Check if any existing slot conflicts with the current time slots
      const hasConflict = timeSlots.some((newSlot) => {
        return existingDayjsSlots.some((existingSlot) => {
          return (
            // Add a condition to prevent conflict if start and end times are exactly the same
            !(
              newSlot.startTime &&
              newSlot.startTime.isSame(existingSlot.startTime) &&
              newSlot.endTime &&
              newSlot.endTime.isSame(existingSlot.endTime)
            ) &&
            // Check for time overlap
            newSlot.startTime &&
            newSlot.endTime &&
            existingSlot.startTime.isBefore(newSlot.endTime) &&
            existingSlot.endTime.isAfter(newSlot.startTime) &&
            !existingSlot?.monthDate
          );
        });
      });

      if (hasConflict) {
        dispatch(
          showToast({
            color: "error",
            msg: `Conflicting time slots found for ${day}. Please choose different slots.`,
          })
        );
        return; // Prevent adding this day to repeatDays if there's a conflict
      }
    }

    // Otherwise, allow selecting the day
    setRepeatDays((prevDays) => [...prevDays, dayInLower]);
  };

  const convertTimeSlotsToDayjs = (slots: TimeSlot[]) => {
    return slots.map((slot) => ({
      startTime: dayjs(slot.startTime, "h:mm A"),
      endTime: dayjs(slot.endTime, "h:mm A"),
      monthDate: slot?.monthDate,
    }));
  };

  useEffect(() => {
    if (serviceTimingProp && selectedDay) {
      const dayPart = selectedDay.split(" ")[0].toLowerCase();
      const dateMatch = selectedDay.match(/\((.*?)\)/);
      const selectedDate = dateMatch ? dateMatch[1] : null;
      const serviceDayKey = dayPart;

      if (serviceTimingProp[serviceDayKey]) {
        let daySlots = serviceTimingProp[serviceDayKey].slots;
        const hasSelectedDate: any = daySlots.some(slot => slot.monthDate === selectedDate);
        if (selectedDate && !hasSelectedDate) {
          daySlots = daySlots.map((slot) =>
            slot.monthDate ? slot : { ...slot, monthDate: selectedDate }
          );
        }

        // Check for slots that match the selectedDate
        const selectedDateSlots = daySlots.filter(
          (slot) => selectedDate && slot?.monthDate === selectedDate
        );

        const fallbackSlots = daySlots.filter((slot) => !slot?.monthDate);
        const hasNullStartTime = selectedDateSlots.some(
          (slot) => slot.startTime === null
        );
        // Use selectedDateSlots if available, otherwise use fallbackSlots
        const filteredSlots =
          selectedDateSlots.length > 0 && hasNullStartTime
            ? []
            : selectedDateSlots.length > 0
              ? selectedDateSlots
              : fallbackSlots;
        // Convert filtered slots to the required format
        const convertedSlots =
          filteredSlots.length === 0
            ? [{ _id: null, startTime: null, endTime: null }] // Default empty slot if no slots available
            : convertTimeSlotsToDayjs(filteredSlots).map((slot, index) => ({
              _id: filteredSlots[index]._id, // Preserve the _id
              ...slot,
            }));
        const sortedTimeSlots: any = sortSlotsByStartTime(convertedSlots);
        setTimeSlots(sortedTimeSlots); // Update the state with the converted slots
      }
    }
  }, [serviceTimingProp, selectedDay]);

  const getServiceTimingFromSlotsAndRepeatedDays = () => {
    if (validateLastSlot(dispatch, timeSlots)) {
      const updatedServiceTiming = deepCopyServiceTiming(serviceTimingProp);
      const formattedSlots = formatTimeSlots(timeSlots);

      // Process slots for days with specific dates (monthDate)
      processSlotsWithMonthDate(formattedSlots, updatedServiceTiming);

      // Process repeatDays (without specific dates)
      processRepeatDaysSlots(formattedSlots, repeatDays, updatedServiceTiming);

      // Handle removed slots
      handleRemovedSlots(removedSlots, repeatDays, updatedServiceTiming);

      removeFalsyIdsFromSlots(updatedServiceTiming);
      return updatedServiceTiming;
    }
  };
  // Process slots with specific dates (monthDate)
  const processSlotsWithMonthDate = (
    formattedSlots: any,
    updatedServiceTiming: any
  ) => {
    formattedSlots.forEach((slot: any) => {
      if (slot.monthDate) {
        const dayOfWeek = dayjs(slot.monthDate).day(); // Get the day of the week (0-6)
        const day = keyToDayMapping[dayOfWeek]; // Get corresponding day name

        if (day) {
          ensureDayExists(day, updatedServiceTiming);

          // Handle the current slot (with monthDate)
          processSlotForSpecificDay(slot, day, updatedServiceTiming);

          // Add general slots (without monthDate)
          // addGeneralSlotsWithoutDate(formattedSlots, day, updatedServiceTiming);
        }
      }
    });
  };

  // Process individual slot with a specific monthDate
  const processSlotForSpecificDay = (
    slot: any,
    day: any,
    updatedServiceTiming: any
  ) => {
    const existingSlots = updatedServiceTiming[day].slots || [];
    const existingSlotIndex = existingSlots.findIndex(
      (existingSlot: any) =>
        ((existingSlot._id === slot._id &&
          existingSlot?._id !== undefined) &&
          existingSlot.monthDate === slot.monthDate) ||
        existingSlot.startTime === null
    );
    if (existingSlotIndex !== -1) {
      updatedServiceTiming[day].slots[existingSlotIndex] = { ...slot };
    } else {
      updatedServiceTiming[day].slots.push({ ...slot });
    }
  };

  // Add general (repeat) slots without monthDate to a day
  const processRepeatDaysSlots = (
    formattedSlots: any[],
    repeatDays: string[], // Specify more accurate type if possible
    updatedServiceTiming: any
  ) => {
    repeatDays.forEach((day: string) => {
      ensureDayExists(day, updatedServiceTiming); // Ensure the day exists
      addGeneralSlotsWithoutDate(formattedSlots, day, updatedServiceTiming);
    });
  };

  const handleRemovedSlots = (
    removedSlots: any[],
    repeatDays: string[],
    updatedServiceTiming: any
  ) => {
    if (removedSlots && removedSlots.length > 0) {
      // Format the removed slots to compare properly
      const formattedRemovedSlots = formatRemovedSlots(removedSlots);
      const specificDate = removedSlots[0]?.monthDate;

      if (specificDate) {
        const day = dayjs(specificDate).format("dddd").toLowerCase(); // Get the day name from the date
        updateSlotsForDay(day, formattedRemovedSlots, updatedServiceTiming);
      } else {
        repeatDays.forEach((day: string) => {
          updateSlotsForDay(day, formattedRemovedSlots, updatedServiceTiming);
        });
      }
    }
  };

  const updateSlotsForDay = (
    day: string,
    formattedRemovedSlots: any[],
    updatedServiceTiming: any
  ) => {
    const existingSlots = updatedServiceTiming[day]?.slots || [];
    // Filter out the removed slots for the given day
    updatedServiceTiming[day].slots = existingSlots.filter(
      (existingSlot: any) =>
        !formattedRemovedSlots.some(
          (removedSlot: any) =>
            removedSlot.startTime === existingSlot.startTime &&
            removedSlot.endTime === existingSlot.endTime &&
            (removedSlot.monthDate === null ||
              removedSlot.monthDate === existingSlot.monthDate)
        )
    );
  };

  // Helper function to format removedSlots for comparison
  const formatRemovedSlots = (removedSlots: any) => {
    return removedSlots.map((slot: any) => ({
      startTime: slot.startTime ? slot.startTime.format("h:mm A") : "",
      endTime: slot.endTime ? slot.endTime.format("h:mm A") : "",
      monthDate: slot.monthDate || null, // Ensure monthDate is included for comparison
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const payload = getServiceTimingFromSlotsAndRepeatedDays();
      // const payload = {
      //     monday: { isAvailable: true, slots: [] },
      //     tuesday: { isAvailable: true, slots: [] },
      //     wednesday: { isAvailable: true, slots: [] },
      //     thursday: { isAvailable: true, slots: [] },
      //     friday: { isAvailable: true, slots: [] },
      //     saturday: { isAvailable: true, slots: [] },
      //     sunday: { isAvailable: true, slots: [] },
      // }
      await updateUserProfile({ serviceTiming: payload });
      dispatch(setUserData({ serviceTiming: payload }));
      handleClose();
      getProfileDetails && getProfileDetails();
      from && navigate(routePaths.operatingHours);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const disabled = timeSlots.some((slot) => slot.startTime === null);
    setIsDisabled(disabled);
  }, [timeSlots]);
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        sx={styles.availabilityModalDialog}
      >
        <Box sx={styles.closeIconBox}>
          <img
            src={closeIcon}
            alt="close"
            style={{ height: "25px", width: "25px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        <DialogContent sx={styles.availabilityDialogContent}>
          <DialogTitle sx={styles.availabilityDialogTitle}>
            Add your Availability
          </DialogTitle>
          <Typography sx={styles.availabilitySelectedDay}>
            {selectedDay}
          </Typography>
          <Box sx={styles.justifyContentCenter}>
            <TimeRangeSelector
              timeSlots={timeSlots}
              setTimeSlots={setTimeSlots}
              removedSlots={removedSlots}
              setRemovedSlots={setRemovedSlots}
              selectedDate={selectedDate}
            />
          </Box>
          {daysOfWeek?.includes(selectedDay.toLowerCase()) ? (
            <>
              <Typography sx={styles.repeatsOnText}>Repeats On</Typography>
              <RepeatDays
                onDayToggle={toggleRepeatDay}
                selectedDays={repeatDays}
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions sx={styles.availabilityDialogActions}>
          <BlueButton
            btnName=" Add Availability"
            onClick={handleSubmit}
            disabled={isDisabled}
            width={buttonWidth}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAvailabilityModal;
