import { useEffect } from "react";
import Footer from "../LandingPage/Consumer/Footer/footer";
import Header from "../LandingPage/Consumer/Header/header";
import AboutUsBanner from "./AboutUsBanner/AboutUsBanner";
import AboutUsContent from "./AboutUsContent/AboutUsContent";

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <AboutUsBanner />
            <AboutUsContent />
            <Footer />
        </>
    )
}

export default AboutUs;