import { SxProps, Theme } from "@mui/system";

const styles: { [key: string]: SxProps<Theme> } = {
    selectSubServiceCategoryForm : {
      "& .innerWrapper" : {
        padding: {
            sm:"16px",
            xs:"60px 16px 16px"
        }
      }  
    },
    innerContainer: {
        height: 'auto',
        width: { lg: '50%', sm: "80%", xs:"100%" },
        display: 'flex',
        flexDirection: 'column'
    },
    categoriesContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        maxHeight: '70vh',
        marginBottom: '10px',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
    },
    categoryName: {
        my: {
            xs: "5px",
            xl: "14px"
        },
        color: '#06038D',
        fontSize: {
            xs: "14px",
            xl: '16px'
        },
        fontWeight: 500,
        fontFamily: "Montserrat, sans- serif",
    },
    categoriesGrid: {
        padding: '5px 15px 5px 5px',
        cursor: 'pointer',
        alignItems: "center",
        display: 'flex',
        columnGap: '5px',
        borderRadius: '37px',
    },
    subcategoriesGrid: {
        padding: '5px 15px 5px 10px',
        cursor: 'pointer',
        alignItems: "center",
        display: 'flex',
        columnGap: '5px',
        borderRadius: '37px',
    },
    subCatName: {
        fontWeight: 500,
        fontSize: {
            xs: '12px',
            xl: '14px'
        },
        fontFamily: 'Montserrat, sans-serif',
    },
    imageStyles: {
        width: {
            xl: '23px',
            xs: "20px"
        },
        height: {
            xl: '23px',
            xs: "20px"
        },
        borderRadius: '50%',
        backgroundColor: 'white',
        padding: '2px'
    }

};

export default styles;

