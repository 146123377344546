import { Box, Typography } from "@mui/material";

import DashboardHeader from "./DashboardHeader";
import DashboardFooter from "./DashboardFooter";
import OperatingHours from "../AddOperatingHours/OperatingHours";

import styles from "./style";
import DashboardMobileHeader from "./DashboardMobileHeader";
import { Padding } from "@mui/icons-material";

const Availability = () => {
  return (
    <>
      <Box sx={styles.container}>
        <Box sx={{
          display: {
            md: "none",
            xs: "block"
          },
          position: "absolute",
          width: "100%"
        }}>
          <DashboardMobileHeader />
        </Box>
        <Box
          sx={{
            display: {
              md: "block",
              xs: "none"
            }
          }}
        >
          <DashboardHeader />
        </Box>
        <Box className="settingsPage" sx={styles.settingsPage}>
          <Box sx={styles.headerBox}>
            <Typography variant="h3" sx={styles.availabilityTitle}>
              Availability
            </Typography>
          </Box>
          <OperatingHours from={"edit"} />
        </Box>
      </Box>
      <Box sx={{
        paddingLeft: {
          md: "0",
          xs: "28px"
        },
        background:"#F5F5FA"
      }}>
        <DashboardFooter />
      </Box>
    </>
  );
};

export default Availability;
